import React, { SetStateAction, useEffect, useRef } from 'react';
import { PopoverOuterContainer, PopoverWrapper, PopoverContainer, PopoverContent } from './PopoverContainers';

export type PopoverProps = {
  isOpened: boolean
  setOpened: React.Dispatch<SetStateAction<boolean>>;
  onClose?: () => void
};

type Props = PopoverProps & {
  children: React.ReactNode
};

export const PopoverComponent = ({ isOpened, setOpened, children, onClose }: Props) => {

  const closePopover = () => setOpened(false)
  const popoverRef = useRef<HTMLDivElement>(null);

  const useClose = () => {
    onClose ? onClose() : closePopover();
  }; 

  useEffect(() => {
    const handleClickOutside = (event: TouchEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as HTMLElement)){
        useClose();
      }
    };

    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return (
    <PopoverOuterContainer id="popover" ref={popoverRef}>
      {isOpened &&
        <>
          <PopoverWrapper>
            <PopoverContainer>
              <PopoverContent>
              {children}
              </PopoverContent>
            </PopoverContainer>
          </PopoverWrapper>
        </>
      }
    </PopoverOuterContainer>
  );
};