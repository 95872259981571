import React from 'react'
import { Icon, IconType } from '../icons'
import { PaymentLogos } from '../payment-logos'
import { FooterContainer } from './FooterContainers'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { GB_FEATS } from '../../growthbook'

type Props = {
  showPaymentLogos?: boolean;
}

export const FooterComponent = ({showPaymentLogos}: Props) => {
  const showYumminnLogo = useFeatureIsOn(GB_FEATS.SHOW_YUMMINN_LOGO);

  return (
    <FooterContainer>
      {showPaymentLogos &&
        <div>
          <PaymentLogos/>
        </div>
      }
      {showYumminnLogo &&
        <div>
          Powered by
          <Icon type={IconType.Logo} size={16}/>
        </div>
      }
    </FooterContainer>
  )
}