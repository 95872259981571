import StackTrace from "stacktrace-js";
import { getDeviceInfo } from "./getDeviceInfo";

function safeStringify(obj: any) {
  try {
      return JSON.stringify(obj);
  } catch (error) {
      console.error("Error stringifying object:", error);
      return "<Unable to stringify>";
  }
}

export const postError = async (error: any, localStorageSetItemFailedValue: any=null, localStorageSetItemFailedKey: any=null) => {
  if (!window.location.href.includes('localhost') && !window.location.href.includes('127.0.0.1')) {
    const restaurantId = Number(sessionStorage.getItem('restaurantId'));
    const device = getDeviceInfo();

    const body: Record<string, any> = {
      restaurant: restaurantId,
      browser_name: device.browser_name,
      browser_version: device.browser_version,
      os_name: device.os_name,
      os_version: device.os_version,
      url: window.location.href,
      agent: window.navigator.userAgent,
      session_storage: { ...sessionStorage }, // Convert to a plain object to avoid serializing complex objects
      local_storage: { ...localStorage }, // Convert to a plain object to avoid serializing complex objects
      token: "Yumminn2022.",
      column_number: '',
      line_number: '',
      file_name: '',
      function_name: '',
      stack: null,
      message: '',
      local_storage_set_item_failed_value: localStorageSetItemFailedValue, 
      local_storage_set_item_failed_key: localStorageSetItemFailedKey
    };

    try {
      const stackTrace = await StackTrace.fromError(error)
      body.column_number = stackTrace[0].columnNumber;
      body.line_number = stackTrace[0].lineNumber;
      body.file_name = stackTrace[0].fileName;
      body.function_name = stackTrace[0].functionName;
      body.stack = stackTrace[0];
      let responseData;
      if (typeof error?.response?.data === 'string') {
          responseData = error?.response?.data;
      } else {
          responseData = safeStringify(error?.response?.data);
      }
      body.message = error?.response ? `error.response.data: ${safeStringify(responseData)}, error.response.status: ${error?.response?.status}, error.response.headers: ${safeStringify(error?.response?.headers)}}`
                    : error?.message ? `error.message: ${safeStringify(error?.message)}`
                    : `error.request: ${safeStringify(error?.request)}`;
      if (typeof body.message === 'string') {
        body.message = body.message.slice(0, 255);
      }                                                        
    } catch {
      body.message = safeStringify(error);
    }

    let bodyStr = "";
    try {
      bodyStr = safeStringify(body);
    } catch(e: any) {
      console.log("postError JSON.stringify(body) failed: " + e.toString());
    }

    try {
      //const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN as string; # use to test in local
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/log', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //"yumminn-token": yumminnToken
        },
        body: bodyStr,
      });
      return response;
    } catch {
      console.log(`postError /api/log exception.`);
    }
  }

  return null;
};