import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import {Request as RegisterRequest}  from "../../api/register/Register.api";
import { LoginButton } from './login-buttons.component';
import { postError } from '../../shared/utils/postError';
import axios from 'axios';

type Props = {
  Render: (props: LoginButton) => JSX.Element
  from: string
  disabled?: boolean
  lopd: boolean
  news: boolean
  onSuccess: (data: any, type: string, from: string, isFirstLogin: boolean) => void;
  onFailure: (error: any) => void;
};

export const GoogleButton = ({Render, from, disabled, lopd, news, onSuccess, onFailure}: Props) => {
  const restaurantId = sessionStorage.getItem('restaurantId');
  const paymentId = sessionStorage.getItem("paymentId") || "";
  const restaurantGroupUuid = sessionStorage.getItem('restaurantGroupUuid') || '';

  const onGoogleSuccess = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const userInfo: any = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then(res => res.data)
        .catch(error => {
          // Handle the error from the axios call here
          onFailure(error) ; postError(error)
          console.error('Error in axios call to Google API:', error);
        });
    
      RegisterRequest.register({
        username: userInfo.email,
        name: userInfo.name,
        token: tokenResponse.access_token,
        type: "google",
        lopd,
        news,
        paymentId,
        restaurantId,
        restaurantGroupUuid,
        paymentSuccessfulPopup: from === 'pop-up_save-card',
      })
        .then((data: any) => onSuccess(data, "google", from, lopd))
        .catch((error: any) => {onFailure(error) ; postError(error)});
    },
  });

  const handleRenderClick = () => {
    onGoogleSuccess();
  };

  return (
    <Render type='google' renderProps={{ onClick: handleRenderClick }}/>
  );
};