import React from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { NotFoundContainer, NotFoundIcon } from './NotFoundContainers';
import { IconType } from '../../elements';
import { OpeningHours } from '../../context/app';
import { groupBy } from 'lodash';

type Props = {
  cta?: React.ReactNode
  hasHome: boolean
  errorStatus?: null | number
  isRestaurantClosed?: boolean
  openingHours?: OpeningHours[] | null;
};

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const getFormatter = () => {
  const lang = sessionStorage.getItem('language') || 'es';
  
  return new Intl.ListFormat(lang, {
    style: 'long',
    type: 'conjunction',
  });
};

const sortByDate = (a: OpeningHours, b: OpeningHours) => {
  const numberHour = (hour: string) => Number(hour.replace(':', ''));

  if (a.hour_from !== b.hour_from) return numberHour(a.hour_from) - numberHour(b.hour_from);
  if (a.hour_to !== b.hour_to) return numberHour(a.hour_to) - numberHour(b.hour_to);
  return a.day - b.day;
};

const formatOpenigHours = (openingHours: Props['openingHours']) => {
  if (!openingHours) return null;

  const hoursByDay = groupBy(openingHours, 'day');

  const formatter = getFormatter();

  const hourStringByDay = Object.values(hoursByDay).map(ranges => {
    const stringRanges = ranges.sort(sortByDate).map(range => `${t('from')} ${range.hour_from} ${t('to')} ${range.hour_to}`);
    return { day: ranges[0].day - 1, hours: formatter.format(stringRanges) }
  });

  const sameRanges = hourStringByDay.reduce((hours, day) => {
    hours[day.hours] = [...(hours[day.hours] || []), day.day];
    return hours;
  }, {} as Record<string, number[]>);

  return Object.entries(sameRanges);
};

const formatDays = (days: number[]) => {
  if (days.length === 7) return t('every_day');
  if (days.length === 2 && days.includes(5) && days.includes(6)) return t('weekends');

  days.sort((a, b) => a - b);
  const areConsecutive = days.length > 2 && days.map((day, index) => (
    days[index + 1] - day === 1 || day === 6 && days[0] === 0
  ));

  const isRange = areConsecutive && areConsecutive.filter(isConsecutive => !isConsecutive).length === 1;

  if (isRange) {
    const lastDayIndex = areConsecutive.findIndex(isConsecutive => !isConsecutive);
    const firstDay = WEEKDAYS[days[lastDayIndex === days.length - 1 ? 0 : lastDayIndex + 1]];
    const lastDay = WEEKDAYS[days[lastDayIndex]];

    return `${t(firstDay)} ${t('to')} ${t(lastDay)}`;
  }

  const formatter = getFormatter();

  const weekdays = days.map(day => t(WEEKDAYS[day]));
  return formatter.format(weekdays);
};

export const NoProductsFound = ({cta, errorStatus, hasHome, isRestaurantClosed, openingHours}: Props) => {
  const logo = sessionStorage.getItem('restaurantLogo') || '';

  const formattedHours = formatOpenigHours(openingHours);
  
  return (
    <NotFoundContainer>
      {(hasHome && logo) &&
        <img src={logo} alt='logo'/>
      }
      <NotFoundIcon type={IconType.Menu} emoji={isRestaurantClosed ? '🕑' : '❌'}/>
      <p>
        {(errorStatus === 200 && isRestaurantClosed) ?
          <Trans i18nKey={"closed_restaurant_message"}>
            The restaurant is not taking orders at the moment.
          </Trans>
        : errorStatus === 200 ?
          <Trans i18nKey={"catalog_error_empty"}>
            <b>There are currently</b> no products to display.
          </Trans>
        : errorStatus === 401 ?
          <Trans i18nKey={"catalog_error_unauthorized"}>
            <strong>Oops!</strong>
            <b>You are not authorized to view this catalog.</b>
          </Trans>
        :
          <Trans i18nKey={"catalog_error_message"}>
            <strong>Oops!</strong>
            <b>There was a problem loading the menu.</b>
          </Trans>
        }
      </p>
      <p>
        <b>{t(isRestaurantClosed ? 'opening_hours_message' : 'contact_staff')}</b>
      </p>
      {formattedHours &&
        <ul>
          {formattedHours.map(([hours, days]) =>
            <li key={hours}>
              {formatDays(days)} {hours}
            </li>
          )}
        </ul>
      }
      {cta}
    </NotFoundContainer>
  );
};