import useSWR from 'swr';
import moment from 'moment';
import ManagerAuth from '../../api/manager/ManagerAuth.api';
import { Receipt } from '../../context/app';
import { MINUTE, SECOND } from '../constants/time';
import endpoints from '../../api/endpoints/Endpoints.api';
import { v4 as uuidv4 } from 'uuid';
import { postError } from './postError';

const PROGRESS_STATES = ['created', 'waiting_send_info_to_pos', 'received'];

const dateSorter = (a: Receipt, b: Receipt) => Date.parse(b.date) - Date.parse(a.date);

const receiptFetcher = async (endpoint: string) => {
  const token = localStorage.getItem('token_yumminn');
  const type = localStorage.getItem('type');
  
  return ManagerAuth.client(token, type).get(endpoint).then((res: {data:{payments: Receipt[]}}) => res.data);
};

const refreshInterval = (latestData: {payments: Receipt[]} | undefined) => {
  if (!latestData || !latestData.payments) return 10 * SECOND;

  const lastPaymentUuid = sessionStorage.getItem('lastPaymentUuid');

  const isLastPaymentMissing = lastPaymentUuid && !latestData?.payments?.some(payment => payment.uuid === lastPaymentUuid);

  if (isLastPaymentMissing) return 10 * SECOND

  const hasOrderInProgress = latestData?.payments?.some(payment =>
    payment?.order_status
    && PROGRESS_STATES.includes(payment.order_status)
    && moment(payment.date).isSame(Date.now(), 'day')
  );
  
  if (hasOrderInProgress) return MINUTE;

  return 0;
};

interface UseReceiptsOptions {
  restaurantId?: string | number;
  creditName?: string;
}

export const useReceipts = ({ restaurantId, creditName }: UseReceiptsOptions) => {
  let deviceToken = localStorage.getItem('deviceToken') || '';

  if (!deviceToken) {
    deviceToken = uuidv4();
    try {
      localStorage.setItem('deviceToken', deviceToken);
    } catch (err:any) {
      postError(err);
    }
  }

  let queryParams = (deviceToken || restaurantId || creditName) ? '?' : '';

  if (deviceToken) queryParams +=`device_token=${deviceToken}`;
  if (restaurantId) queryParams += `${deviceToken ? '&' : ''}restaurant_id=${restaurantId}`;
  if (creditName) queryParams += `${deviceToken ? '&' : ''}credit_name=${creditName}`;

  const { data } = useSWR(
    `${endpoints.payments.get_client_payments}${queryParams}`,
    receiptFetcher,
    {
      revalidateOnFocus: false,
      refreshInterval(latestData) {return refreshInterval(latestData)},
    },
  );

  const receipts: Receipt[] = data?.payments?.sort(dateSorter) || [];

  return receipts;
};