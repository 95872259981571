export const fonts = {
  typography: `'Poppins', sans-serif`,
  font_xxl: '2rem', //32px
  font_xl: '1.5rem', //24px
  font_l_xl: '1.25rem', //20px
  font_lg: '1.125rem', //18px
  font_md: '1rem', //16px
  font_sm: '0.875rem', //14px
  font_xs: '0.75rem', //12px
  font_xxs: '0.625rem', //10px
};