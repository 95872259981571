import styled from "styled-components";

export const LoginButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  flex-direction: ${({bigButtons}) => bigButtons ? 'column' : 'row'};
  justify-content: ${({bigButtons}) => bigButtons ? 'stretch' : 'center'};
  width: 100%;
  box-sizing: border-box;
  color: ${({theme}) => theme.text};
  
  & > div:first-child {
    display: flex;
    flex: auto;
    flex-direction: ${({bigButtons}) => bigButtons ? 'column' : 'row'};
    justify-content: ${({bigButtons}) => bigButtons ? 'stretch' : 'center'};
    gap: 16px;
    padding: ${({bigButtons, showForm}) => !bigButtons ? '0 16px 0 0' : showForm ? '0' : '0 0 16px 0'};
    overflow: hidden;
    transition: 0.15s ease-in-out;
  }
  
  & > div:last-child {
    padding: ${({showForm}) => showForm ? '16px 0 0' : '0'};
    overflow: hidden;
    transition: 0.15s ease-in-out;
  }
`;

export const SmallLoginButton = styled.button.attrs({type: 'button'})<LoginButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: ${({theme}) => theme.text};
  background-color: ${({selected, theme}) => selected ? theme.primary_a20 : theme.background};
  border: ${({selected, theme}) => `${theme.border_xs} ${selected ? theme.primary : theme.button_border_neutral}`};
  border-radius: ${({theme}) => theme.radius_sm};
  padding: 8px 22px;
  box-shadow: ${({theme}) => theme.shadow_xs};
  opacity: ${({$disabled}) => $disabled ? 0.5 : 1};
`;

export const BigLoginButton = styled.button.attrs({type: 'button'})<LoginButtonProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${({theme}) => theme.typography};
  font-size: ${({theme}) => theme.font_md};
  color: ${({theme}) => theme.text};
  background-color: ${({selected, theme}) => selected ? theme.primary_a20 : theme.background};
  border: ${({selected, theme}) => `${theme.border_xs} ${selected ? theme.primary : theme.button_border_neutral}`};
  border-radius: ${({theme}) => theme.radius_round};
  padding: 8px 16px;
  box-shadow: ${({theme}) => theme.shadow_xs};
  opacity: ${({$disabled}) => $disabled ? 0.5 : 1};

  svg:last-child {
    transform: rotate(${({selected}) => selected ? '90deg' : '0deg'});
    transition: transform 0.2s ease-in-out;
  }
`;

export const ConsentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${({theme}) => theme.text};
  
  label {
    display: flex;
    gap: 8px;
  }

  p {
    font-size: ${({theme}) => theme.font_xs};
    margin: 0;
  }

  a {
    font-weight: 600;
    color: currentColor;
  }

  span {
    color: ${({theme}) => theme.danger};
  }

  b {
    font-weight: 600;
    color: ${({theme}) => theme.primary};
  }
`;

type ButtonsContainerProps = {
  bigButtons: boolean
  showForm: boolean
};

type LoginButtonProps = {
  selected: boolean
  $disabled: boolean
};