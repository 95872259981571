import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

type props = {
    history: any;
    children?: any;
}

function ScrollToTop({ history, children }: props) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return <>{children}</>;
}

export default withRouter(ScrollToTop);
