import styled from "styled-components";


const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;

  & > p, b {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  & > p {
    font-size: ${({theme}) => theme.font_md};
    margin: 0;
    margin: 0 4px;

    span {
      font-size: ${({theme}) => theme.font_md};
    }
  }

  & > b {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 700;
    margin: 8px 8px 0 0;
  }

  i {
    font-size: ${({theme}) => theme.font_md};
    text-align: center;
  }

  .discount {
    color:  ${({theme}) => theme.greenish};
  }
`;

const TotalCredits = styled.div`
  display: flex; 
  font-size: ${({theme}) => theme.font_md};
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 0px;
  margin: 0 20px;
  background-color: #F6F6F6;
  border-radius: 8px;
  color: #404040;
`;

const TotalAmountPurchase = styled.span`
  background-color: #EFF8F4;
  font-size: ${({theme}) => theme.font_md};
  padding: 4px 12px 4px 13px;
  border-radius: 24px;
  color: #404040;
  font-weight: 600;
`;

const ExtraCredits = styled.div`
  display: flex; 
  font-size: ${({theme}) => theme.font_xxs};
  align-items: center;
  gap: 5px;
  margin: -7px 0 10px 0;
  position: relative; 

  i {
    display: flex; 
    align-items: center;
  }
`;

const Notice = styled.div`
  display: flex;
  font-size: ${({theme}) => theme.font_xs};
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 10px;

  span{
    margin: 0 4px;
  }
`;

const DisplayLinks = styled.div`
  div{
    p { 
      font-size: ${({theme}) => theme.font_xs};
      margin-right: 4px;
    }
    span {
      font-weight: bold;
      text-decoration: underline;
    }
  }
`;

const CodeCouponInputContainer = styled.div`
  h3 {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: ${({theme}) => theme.font_md};
    font-weight: 400;
    margin: 0.5em 0 1em;
  }

  i {
    font-size: ${({theme}) => theme.font_xs};
    margin-bottom: -16px;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 16px;

    & > button {
      width: fit-content;
      min-width: 120px;
    }

    & > p {
      font-size: ${({theme}) => theme.font_sm};
      font-weight: 600;
      text-align: center;
      white-space: nowrap;
      min-width: 120px;
    }
  }

  .fake_input {
    display: flex;
    width: 100%;
    height: 48px;
    color: ${({theme}) => theme.text};
    background-color: ${({theme}) => theme.input_bg};
    border: ${({theme}) => `${theme.border_sm} ${theme.input_border}`};
    border-radius: ${({theme}) => theme.radius_sm};
    padding: 8px 12px;
    box-sizing: border-box;
    box-shadow: ${({theme}) => theme.shadow_inset_xs};

    & > span {
      color: ${({theme}) => theme.yumminn_blue};
      background-color: #EAFCF5;
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: ${({theme}) => theme.font_sm};
      font-weight: 400;
      padding: 4px 8px;
      border-radius: ${({theme}) => theme.radius_xs};

      & > button {
        color: currentColor;
        background-color: transparent;
        border: none;
        display: inline-flex;
        padding: 8px;
        margin: -8px;
      }
    }
  }
`;

export {
  DetailsContainer,
  DisplayLinks,
  Notice,
  TotalCredits,
  ExtraCredits,
  TotalAmountPurchase,
  CodeCouponInputContainer,
};