import React, { lazy } from "react";

const GrowthBookWrapper = lazy(() => import("../growthbook"));
import { useHistory } from "react-router";

export const GBConditionalWrapper = ({ children }: { children: React.ReactNode }) => {
  const history = useHistory();

  if (history.location.pathname.includes('complete-payment')) return <>{children}</>;
  
    return (
      <GrowthBookWrapper>
        {children}
      </GrowthBookWrapper>
    )
};