import styled from "styled-components";

const PaymentErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px;
  height: 100dvh;
  justify-content: center;
  overflow: hidden;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Sorry = styled.div`
  font-size:  ${({theme}) => theme.font_lg};
  font-weight: 600;
  text-align: center;
  padding: 20px 0;
  line-height: 134%;
`;

const TryAgain = styled.div`
  text-align: center;
  font-size:  ${({theme}) => theme.font_md};
  font-weight: 400;
  line-height: 150%;
`;

const BackArrow = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px;
`;

const ButtonTryAgain = styled.div`
  position: absolute;
  bottom: 20px;
  width: 80%;
`;



export {
  PaymentErrorContainer,
  Message,
  Sorry,
  TryAgain,
  BackArrow,
  ButtonTryAgain
};