import { t } from 'i18next';
import React from 'react';
import { PhoneInputContainer } from './InputContainers';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { Icon, IconType } from '../icons';

type Props = {
  value: string
  title?: string | React.ReactNode
  disabled?: boolean
  required?: boolean
  error?: string | false
  onChange: PhoneInputProps['onChange']
  onFocus?: PhoneInputProps['onBlur']
  onBlur?: PhoneInputProps['onBlur']
  component?: string
};

export const PhoneInput = ({
  value,
  title,
  disabled = false,
  required,
  error,
  onChange,
  onFocus,
  onBlur,
  component,
}: Props) => {

  const validatePhoneNumber = (value: string) => {
    if(value.startsWith("+")){
      return true
    }
    return false
  }

  return (
    <PhoneInputContainer component={component}>
      {title &&
        <h5 className={required ? 'required' : ''}>
          {typeof title === 'string' ?  t(title) : title}
        </h5>
      }
      <label>
        <ReactPhoneInput
          country={'es'}
          value={validatePhoneNumber(value) ? value : ""}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          countryCodeEditable={false}
          enableLongNumbers={true}
        />
        {error &&
          <div className='error'>
            <Icon type={IconType.Error}/>
          </div>
        }
      </label>
      {error &&
        <i>{t(error)}</i>
      }
    </PhoneInputContainer>
  );
};