import React from 'react';
import { InputWrapper, Label } from './ProfileInputContainers';
import { RoundCheckbox } from '../round-checkbox';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import en from "date-fns/locale/en-GB";
import es from "date-fns/locale/es";
import de from "date-fns/locale/de";
import fr from "date-fns/locale/fr";
import i18n from '../../../i18n';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { parseISO } from 'date-fns';
import { t } from 'i18next';
import { Icon, IconType } from '../icons';

const setDate = (birthday: any) => {
  if (birthday) {
    //eslint-disable-next-line
    const date: any = moment(parseISO(birthday));
    return date._d;
  }

  return null;
};

export const ProfileDatepickerComponent = ({label, value, placeholder, isCompleted, error, onChange}: props) => {
  value = value ? setDate(moment(value).format("YYYY-MM-DD")) : null;
  const setLanguage = () => {
    const language = i18n.language;
    registerLocale("es", es);
    registerLocale("en", en);
    registerLocale("de", de);
    registerLocale("fr", fr);
    setDefaultLocale(language);
  
    return language;
  };

  const language = setLanguage();

  return (
    <Label htmlFor={'profile-input-' + label}>
      <span>{t(label)}</span>
      <InputWrapper $error={error} value={value}>
        <RoundCheckbox isChecked={isCompleted}/>
        <ReactDatePicker
          id={'profile-input-' + label}
          locale={language}
          selected={value}
          placeholderText={placeholder}
          dateFormat="d MMMM, yyyy"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          disabledKeyboardNavigation
          onChange={onChange}
          onFocus={(e) => e.target.readOnly = true}
          popperPlacement="top"
        />
        <Icon type={IconType.Edit}/>
      </InputWrapper>
    </Label>
  );
};

type props = {
  label: string,
  value: Date | null,
  placeholder: string,
  isCompleted: boolean,
  error: boolean,
  onChange: any,
};