import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Icon, IconType } from '../icons';
import { BackButton, CloseButton, PopupContainer, PopupContent } from './PopupContainers';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { PopupType } from '../../context/popups.enum';
import { AuthContext } from '../../context/auth/AuthContext';
import { ToastContainer } from 'react-toastify';
import { PopupContext } from '../../context/PopupContext';

export type PopupProps = {
  isOpened: boolean
  header?: string | React.ReactNode
  noReturn?: boolean
  onClose?: () => Promise<void> | void
  afterClose?: () => void
  afterGoBack?: () => void
};

type Props = PopupProps & {
  children: React.ReactNode
  className?: string
};

const noReturnPopup = [
  PopupType.Cart,
  PopupType.Welcome,
  PopupType.CashbackRegister,
] as PopupType[];

export const Popup = ({
  isOpened,
  children,
  header,
  noReturn,
  className = '',
  onClose,
  afterClose,
  afterGoBack,
}: Props) => {
  const { popup } = useContext(PopupContext);
  const { isLogged } = useContext(AuthContext);

  const { goBackPopup, closePopup } = usePopupNavigation();

  const [shouldRenderChildren, setShouldRenderChildren] = useState(false);

  const close = () => {
    popupRef.current?.open && popupRef.current?.children?.[0]?.classList.add('close');
  };

  const open = () => {
    setShouldRenderChildren(true);
    !popupRef.current?.open && popupRef?.current?.showModal && popupRef?.current?.showModal?.();
    popupRef.current?.children?.[0]?.classList.add('open');
  };

  const goBack = async () => {
    await goBackPopup();
    afterGoBack?.();
  };

  const onAnimationEnd = async () => {
    if (popupRef.current?.children?.[0]?.classList.contains('close')) {
      popupRef.current?.close && popupRef.current?.close();
      popupRef.current?.children?.[0]?.classList.remove('close');

      setShouldRenderChildren(false);
      await (onClose || closePopup)();
      afterClose?.();
    } else {
      popupRef.current?.children?.[0]?.classList.remove('open');
    }
  };

  const popupRef = useRef<HTMLDialogElement | null>(null);

  const historyLength = popup.history.length;
  const lastPopup = popup.history[historyLength - 1]?.popup;
  const hideReturn = noReturn || noReturnPopup.includes(popup.current) || isLogged && lastPopup === PopupType.Register && historyLength === 1;

  useLayoutEffect(() => {
    isOpened ? open() : close();

    const handleTouch = (event: TouchEvent) => {
      const childNodes = popupRef.current?.childNodes as NodeList;
      const isPopup = Array.from(childNodes).some(node => node.contains(event.target as Node))

      !isPopup && close();
    };

    isOpened && window.addEventListener('touchstart', handleTouch);

    return () => window.removeEventListener('touchstart', handleTouch);
  }, [isOpened]);

  return (
    <PopupContainer className="dialog_popup" ref={popupRef}>
        <PopupContent className={className} onAnimationEnd={onAnimationEnd}>
          {shouldRenderChildren &&
            <>
              <header>
                {!(hideReturn || historyLength <= 0) &&
                  <BackButton onClick={goBack}>
                    <Icon type={IconType.Arrowback}/>
                  </BackButton>
                }
                {header && (
                  typeof header === 'string' ?
                    <h4>{t(header)}</h4>
                  :
                    header
                  )}
                <CloseButton onClick={close}>
                  <Icon type={IconType.Close}/>
                </CloseButton>
              </header>
              {children}
            </>
          }
        </PopupContent>
      {isOpened &&
        <ToastContainer
          position="bottom-center"
          theme='colored'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          icon={false}
        />
      }
    </PopupContainer>
  );
};