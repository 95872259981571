import React from 'react';
import { TextInput } from '../inputs';
import { Button } from '../buttons';
import { t } from 'i18next';
import { CodeCouponInputContainer } from './PaymentDetailContainers';
import { Icon, IconType } from '../icons';
import { GB_FEATS } from '../../growthbook';
import { useFeatureValue } from '@growthbook/growthbook-react';

export const CodeCouponInput = ({
  codeCouponCents,
  codeCouponDeleteClicked,
  codeCouponText,
  handleCouponChange,
  codeCouponError,
  redeemingCodeCoupon,
  disabledCouponField,
  codeCouponApplyClicked,
}: any) => {
  const label = useFeatureValue(GB_FEATS.COUPON_LABEL, 'do_you_have_a_code_coupon');

  return (
    <CodeCouponInputContainer>
      <h3>
        <Icon type={IconType.Tag} size={22}/>
        {t(label)}
      </h3>
      <div>
        {codeCouponCents <= 0 &&
          <>
            <TextInput
              value={codeCouponText}
              onChange={handleCouponChange}
              error={codeCouponError}
              disabled={redeemingCodeCoupon || disabledCouponField}
            />
            <Button
              title={t('Apply')}
              disabled={disabledCouponField}
              handleClick={codeCouponApplyClicked}
              loading={redeemingCodeCoupon}
            />
          </>
        }
        {codeCouponCents > 0 &&
          <>
            <div className='fake_input'>
              <span>
                {codeCouponText}
                <button onClick={codeCouponDeleteClicked}>
                  <Icon type={IconType.Close} size={12}/>
                </button>
              </span>
            </div>
            <p>{t('Applied!')} 🎉</p>
          </>
        }
      </div>
    </CodeCouponInputContainer>
  );
};
