import React from 'react';
import styled from "styled-components";
import { Icon, IconType } from '../icons';

type Props = {
  name: string
  value: boolean
  onChange?: React.ChangeEventHandler
  disabled?: boolean
};

export const SquareCheckbox = ({name, value, onChange, disabled = false}: Props) => {
  return (
    <Label className={`${value ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}>
      <input
        type='checkbox'
        id={name}
        name={name}
        checked={value}
        disabled={disabled}
        readOnly={!onChange}
        onChange={onChange}
      />
      <Icon type={IconType.Check} size={12}/>
    </Label>
  );
};

const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  color: transparent;
  background-color: ${({theme}) => theme.input_bg};
  border: ${({theme}) => `${theme.border_sm} ${theme.input_border}`};
  border-radius: 4px;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  
  &::after {
    content: '';
    width: 0;
    height: 0;
    background-color: ${({theme}) => theme.primary};
    border-radius: 2px;
    transition: 0.15s ease;
  }
  
  &.checked {
    color: ${({theme}) => theme.background};
    &::after {
      width: 100%;
      height: 100%;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  input {
    opacity: 0;
    width: 0;
    position: absolute;
  }

  svg {
    position: absolute;
  }
`;