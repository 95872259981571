import { Form } from 'formik';
import styled from 'styled-components';

type props = {
    addmarginbottom?: boolean
}

export const FormContainer = styled(Form)<props>`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding-bottom: ${(props) => props.addmarginbottom ? "40px" : 0};
`;

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 37px;
    right: 16px;
`;

const IconWrapperYumClub = styled.div`
    position: absolute;
    color: ${({theme}) => theme.text};
    top: 42px;
    right: 12px;
`;

const PrivacyWrapper = styled.label`
    display: flex;
    align-items: center;
`;

const CarouselWrapper = styled.div`
    margin-top: 40px;
    height: 100vh;
`;


export {IconWrapper, CarouselWrapper, PrivacyWrapper, IconWrapperYumClub}