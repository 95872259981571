import React from 'react'
import { Isotype, LogoContainer, Logotype } from './ResponsiveLogoContainers';

type Props = {
  size?: number
  className?: string
  onClick?: () => void
};

export const ResponsiveLogoComponent = ({size = 24, className='', onClick}: Props) => {
  return (
    <LogoContainer onClick={onClick} size={size} className={className}>
      <Isotype size={size}>
        <path d="M19.1948 24.7288H6.17852C3.30934 24.7288 1 22.3773 1 19.527V6.27307C1 3.3515 3.30934 1 6.17852 1H19.1948C22.064 1 24.3733 3.3515 24.3733 6.27307V19.527C24.3034 22.3773 21.994 24.7288 19.1948 24.7288Z" fill="#094553" stroke="white" strokeWidth="1.06102"/>
        <path d="M12.7575 7.27029C13.5305 7.27029 14.1571 6.66413 14.1571 5.9164C14.1571 5.16866 13.5305 4.5625 12.7575 4.5625C11.9845 4.5625 11.3579 5.16866 11.3579 5.9164C11.3579 6.66413 11.9845 7.27029 12.7575 7.27029Z" fill="white"/>
        <path d="M11.5681 21.4507C10.5884 21.7358 9.5387 21.2369 9.1888 20.3106L6.73949 13.1136C6.38959 12.1872 6.94943 11.1896 7.99913 10.8333C8.97886 10.5483 10.0286 11.0471 10.3785 11.9734L12.8278 19.1705C13.1077 20.0968 12.5478 21.0944 11.5681 21.4507Z" fill="white" strokeWidth="0.523589" strokeMiterlimit="10"/>
        <path d="M14.1553 21.4507C15.135 21.7358 16.1847 21.2369 16.4646 20.3106L18.9139 13.1136C19.1938 12.1872 18.704 11.1896 17.7243 10.8333C16.7445 10.5483 15.6948 11.0471 15.4149 11.9734L12.9656 19.1705C12.6157 20.0968 13.1756 21.0944 14.1553 21.4507Z" fill="white" strokeWidth="0.523589" strokeMiterlimit="10"/>
        <path d="M12.8972 21.5224C11.4276 21.5224 10.238 20.311 10.238 18.8146V11.1188C10.238 9.62239 11.4276 8.41101 12.8972 8.41101C14.3668 8.41101 15.5564 9.62239 15.5564 11.1188V18.8146C15.5564 20.311 14.2968 21.5224 12.8972 21.5224Z" fill="white" strokeWidth="0.523589" strokeMiterlimit="10"/>
        <path d="M18.2147 9.26518C18.7558 9.26518 19.1944 8.85044 19.1944 8.33883C19.1944 7.82722 18.7558 7.41248 18.2147 7.41248C17.6736 7.41248 17.235 7.82722 17.235 8.33883C17.235 8.85044 17.6736 9.26518 18.2147 9.26518Z" fill="white"/>
        <path d="M7.50826 9.26518C8.04935 9.26518 8.48798 8.85044 8.48798 8.33883C8.48798 7.82722 8.04935 7.41248 7.50826 7.41248C6.96718 7.41248 6.52854 7.82722 6.52854 8.33883C6.52854 8.85044 6.96718 9.26518 7.50826 9.26518Z" fill="white"/>
      </Isotype>
      <Logotype size={size}>
        <path d="M5.56498 15.3995L5.54844 15.3894L5.53116 15.3806C5.35903 15.2929 5.1419 15.0882 4.9653 14.7885C4.81264 14.5294 4.7546 14.2674 4.7546 13.9022V9.27043C4.7546 8.29028 3.9794 7.48779 2.99496 7.48779H2.78502C1.80059 7.48779 1.02538 8.29028 1.02538 9.27043V13.9022C1.02538 14.6859 1.1805 15.3952 1.48701 16.0974C1.80098 16.8168 2.27023 17.3747 2.87496 17.8421C3.05876 18.0214 3.31555 18.197 3.54647 18.3326C3.77829 18.4688 4.04808 18.6024 4.25676 18.6584C4.25862 18.6592 4.26185 18.6609 4.2668 18.6645C4.27525 18.6708 4.29001 18.6838 4.3093 18.709C4.34999 18.762 4.39741 18.8511 4.44265 18.9875C4.5338 19.2623 4.58743 19.639 4.61276 20.0604C4.64954 20.6721 4.6249 21.2625 4.60745 21.6803C4.6003 21.8516 4.59436 21.9939 4.59436 22.0968V23.8783C4.59436 24.8396 5.35145 25.6609 6.28402 25.6609H6.49396C7.4784 25.6609 8.2536 24.8584 8.2536 23.8783V22.0968C8.2536 22.0103 8.24838 21.8888 8.24201 21.7405C8.22504 21.3459 8.19994 20.762 8.24361 20.1459C8.27296 19.7317 8.33224 19.3524 8.43245 19.0647C8.53801 18.7616 8.65095 18.6656 8.71199 18.6407L8.73149 18.6328L8.75024 18.6232L8.77211 18.6121C9.17705 18.406 9.63728 18.1717 10.0288 17.8527L10.0354 17.8474L10.0418 17.8418C10.5713 17.3797 11.044 16.8236 11.361 16.0974C11.6675 15.3952 11.8226 14.6859 11.8226 13.9022V9.27043C11.8226 8.29028 11.0474 7.48779 10.0629 7.48779H9.85301C8.86857 7.48779 8.09337 8.29028 8.09337 9.27043V13.9022C8.09337 14.2674 8.03533 14.5294 7.88267 14.7885L7.87302 14.8049L7.86463 14.8219C7.77711 15.0002 7.57514 15.221 7.28299 15.3995C7.03168 15.553 6.77869 15.6112 6.42399 15.6112C6.06928 15.6112 5.81629 15.553 5.56498 15.3995Z" fill="#094553" stroke="white"/>
        <path d="M17.1819 15.3998L17.1654 15.3897L17.1481 15.3809C16.976 15.2933 16.7588 15.0885 16.5822 14.7888C16.4296 14.5297 16.3715 14.2677 16.3715 13.9025V9.27077C16.3715 8.30805 15.6131 7.41687 14.5419 7.41687C13.5746 7.41687 12.7123 8.20222 12.7123 9.27077V13.8313C12.7123 14.7346 12.8592 15.6099 13.3317 16.4202C13.732 17.2262 14.366 17.8619 15.2089 18.3301C16.061 18.8033 16.986 19.0343 18.1109 19.0343C19.2264 19.0343 20.1555 18.8072 20.8764 18.3994L20.8837 18.3953L20.8909 18.3909C21.6613 17.9202 22.2793 17.2905 22.7405 16.5079L22.7501 16.4915L22.7585 16.4744C23.1382 15.7011 23.3695 14.8424 23.3695 13.9025V9.34202C23.3695 8.37931 22.6111 7.48813 21.5399 7.48813C20.5726 7.48813 19.7103 8.27347 19.7103 9.34202V13.9025C19.7103 14.2677 19.6523 14.5297 19.4996 14.7888L19.4899 14.8052L19.4816 14.8223C19.394 15.0005 19.1921 15.2213 18.8999 15.3998C18.6486 15.5533 18.3956 15.6115 18.0409 15.6115C17.6862 15.6115 17.4332 15.5533 17.1819 15.3998Z" fill="#094553" stroke="white"/>
        <path d="M31.1532 7.29661L31.1154 7.2838L31.0761 7.27713L31.0637 7.27503C30.6498 7.20477 30.1949 7.12756 29.6628 7.12756C28.6348 7.12756 27.7013 7.277 26.8308 7.76052C25.9848 8.23041 25.3538 8.8663 24.9551 9.59708C24.5658 10.3106 24.3342 11.1766 24.3342 12.0455V16.8198C24.3342 17.7825 25.0926 18.6737 26.1638 18.6737C27.1477 18.6737 27.9213 17.8923 28.2442 16.988L28.2734 16.9064V16.8198V12.0455C28.2734 11.7463 28.3334 11.5107 28.4559 11.3445L28.4711 11.3238L28.4841 11.3018C28.6527 11.0155 28.8045 10.8614 29.0837 10.6908L29.1059 10.6772L29.1265 10.6615C29.2764 10.547 29.5624 10.4791 29.8728 10.4791C30.1487 10.4791 30.4045 10.5336 30.6618 10.6908C30.9173 10.8469 31.0756 11.0538 31.2897 11.3445C31.4121 11.5107 31.4722 11.7463 31.4722 12.0455V16.8198C31.4722 17.7825 32.2306 18.6737 33.3018 18.6737C34.2691 18.6737 35.1314 17.8884 35.1314 16.8198V12.0455C35.1314 11.7463 35.1914 11.5107 35.3139 11.3445L35.3291 11.3238L35.3421 11.3018C35.5108 11.0155 35.6625 10.8614 35.9418 10.6908L35.9639 10.6772L35.9846 10.6615C36.1345 10.547 36.4205 10.4791 36.7308 10.4791C37.0068 10.4791 37.2625 10.5336 37.5198 10.6908C37.7753 10.8469 37.9336 11.0538 38.1477 11.3445C38.2702 11.5107 38.3302 11.7463 38.3302 12.0455V16.8198C38.3302 17.7825 39.0886 18.6737 40.1598 18.6737C41.1271 18.6737 41.9895 17.8884 41.9895 16.8198V12.0455C41.9895 11.1766 41.7578 10.3106 41.3685 9.59708L41.3646 9.58983L41.3604 9.58272C40.8886 8.78213 40.2612 8.22941 39.5108 7.77095L39.4942 7.76085L39.4769 7.75205C38.7005 7.35672 37.7819 7.12756 36.6608 7.12756C36.648 7.12756 36.6351 7.12756 36.6221 7.12756C36.1603 7.12742 35.5995 7.12726 35.1005 7.29661L35.0751 7.30524C34.6731 7.44164 34.2086 7.59925 33.8109 7.84221C33.5725 7.98787 33.3365 8.16159 33.119 8.34615C32.9085 8.1538 32.6706 7.98141 32.4428 7.84221L32.4262 7.8321L32.409 7.82331C31.9652 7.59738 31.5929 7.44586 31.1532 7.29661Z" fill="#094553" stroke="white"/>
        <path d="M49.5589 7.29661L49.5212 7.2838L49.4819 7.27713L49.4695 7.27503C49.0556 7.20477 48.6007 7.12756 48.0686 7.12756C47.0405 7.12756 46.1071 7.277 45.2366 7.76052C44.3906 8.23041 43.7596 8.8663 43.3609 9.59708C42.9716 10.3106 42.74 11.1766 42.74 12.0455V16.8198C42.74 17.7825 43.4984 18.6737 44.5696 18.6737C45.5535 18.6737 46.3271 17.8923 46.65 16.988L46.6792 16.9064V16.8198V12.0455C46.6792 11.7463 46.7392 11.5107 46.8617 11.3445L46.8769 11.3238L46.8899 11.3018C47.0585 11.0155 47.2103 10.8614 47.4895 10.6908L47.5117 10.6772L47.5323 10.6615C47.6822 10.547 47.9682 10.4791 48.2786 10.4791C48.5545 10.4791 48.8103 10.5336 49.0676 10.6908C49.3231 10.8469 49.4813 11.0538 49.6955 11.3445C49.8179 11.5107 49.878 11.7463 49.878 12.0455V16.8198C49.878 17.7825 50.6364 18.6737 51.7076 18.6737C52.6749 18.6737 53.5372 17.8884 53.5372 16.8198V12.0455C53.5372 11.7463 53.5972 11.5107 53.7197 11.3445L53.7349 11.3238L53.7479 11.3018C53.9166 11.0155 54.0683 10.8614 54.3476 10.6908L54.3697 10.6772L54.3904 10.6615C54.5403 10.547 54.8263 10.4791 55.1366 10.4791C55.4126 10.4791 55.6683 10.5336 55.9256 10.6908C56.1811 10.8469 56.3394 11.0538 56.5535 11.3445C56.676 11.5107 56.736 11.7463 56.736 12.0455V16.8198C56.736 17.7825 57.4944 18.6737 58.5656 18.6737C59.5329 18.6737 60.3952 17.8884 60.3952 16.8198V12.0455C60.3952 11.1766 60.1636 10.3106 59.7743 9.59708L59.7704 9.58983L59.7662 9.58272C59.2944 8.78213 58.667 8.22941 57.9166 7.77095L57.9 7.76085L57.8827 7.75205C57.1063 7.35672 56.1877 7.12756 55.0666 7.12756C55.0538 7.12756 55.0409 7.12756 55.0279 7.12756C54.5661 7.12742 54.0053 7.12726 53.5063 7.29661L53.4809 7.30523C53.0789 7.44163 52.6144 7.59925 52.2167 7.84221C51.9783 7.98787 51.7423 8.16159 51.5248 8.34615C51.3143 8.1538 51.0764 7.98141 50.8486 7.84221L50.832 7.8321L50.8148 7.82331C50.371 7.59738 49.9987 7.44586 49.5589 7.29661Z" fill="#094553" stroke="white"/>
        <path d="M61.4255 8.69509V16.8185C61.4255 17.9033 62.3047 18.6724 63.3251 18.6724C64.2924 18.6724 65.1547 17.887 65.1547 16.8185V8.69509C65.1547 7.99997 64.5895 7.41125 63.885 7.41125H62.6953C61.9908 7.41125 61.4255 7.99996 61.4255 8.69509Z" fill="#094553" stroke="white"/>
        <path d="M66.8829 9.65321L66.8732 9.6696L66.8648 9.68666C66.4852 10.4599 66.2538 11.3187 66.2538 12.2586V16.819C66.2538 17.7818 67.0122 18.6729 68.0834 18.6729C69.113 18.6729 69.9581 17.9044 70.051 16.8635L70.053 16.8413V16.819V12.2586C70.053 11.8934 70.1111 11.6313 70.2637 11.3723L70.2734 11.3559L70.2818 11.3388C70.3693 11.1606 70.5712 10.9398 70.8634 10.7613C71.1147 10.6077 71.3677 10.5496 71.7224 10.5496C72.0771 10.5496 72.3301 10.6077 72.5814 10.7613L72.598 10.7714L72.6152 10.7802C72.7874 10.8678 73.0045 11.0725 73.1811 11.3723C73.3337 11.6313 73.3918 11.8934 73.3918 12.2586V16.819C73.3918 17.7818 74.1502 18.6729 75.2214 18.6729C76.1887 18.6729 77.051 17.8876 77.051 16.819V12.2586C77.051 11.3553 76.9041 10.4799 76.4316 9.66963C76.0328 8.86651 75.4018 8.23241 74.5634 7.76475C73.7651 7.27989 72.761 7.05554 71.6524 7.05554C70.5275 7.05554 69.6026 7.28647 68.7504 7.75976L68.7414 7.76479L68.7325 7.77019C67.9621 8.2409 67.344 8.87057 66.8829 9.65321Z" fill="#094553" stroke="white"/>
        <path d="M78.569 9.79604L78.5594 9.81242L78.551 9.82949C78.1713 10.6027 77.94 11.4615 77.94 12.4014V16.9619C77.94 17.9246 78.6984 18.8158 79.7696 18.8158C80.7674 18.8158 81.5562 17.9421 81.7298 17.0582L81.7392 17.0105V16.9619V12.4014C81.7392 12.0362 81.7972 11.7742 81.9499 11.5151L81.9595 11.4987L81.9679 11.4816C82.0554 11.3034 82.2574 11.0826 82.5496 10.9041C82.8009 10.7505 83.0539 10.6924 83.4086 10.6924C83.7633 10.6924 84.0163 10.7505 84.2676 10.9041L84.2841 10.9142L84.3014 10.923C84.4735 11.0106 84.6906 11.2154 84.8672 11.5151C85.0199 11.7742 85.0779 12.0362 85.0779 12.4014V16.9619C85.0779 17.9246 85.8363 18.8158 86.9076 18.8158C87.8749 18.8158 88.7372 18.0304 88.7372 16.9619V12.4014C88.7372 11.4981 88.5903 10.6227 88.1177 9.81245C87.7189 9.00933 87.088 8.37523 86.2496 7.90757C85.4512 7.42271 84.4472 7.19836 83.3386 7.19836C82.2137 7.19836 81.2887 7.42929 80.4366 7.90258L80.4275 7.90761L80.4187 7.91301C79.6482 8.38372 79.0302 9.01339 78.569 9.79604Z" fill="#094553" stroke="white"/>
        <path d="M61.7768 4.41387C61.9878 4.62879 62.2167 4.79693 62.4836 4.9081C62.7503 5.0192 63.0284 5.06234 63.3232 5.06234C63.8639 5.06234 64.4167 4.87501 64.8696 4.41387C65.3144 3.96095 65.5027 3.47753 65.5027 2.85215C65.5027 2.3093 65.3217 1.75087 64.8696 1.29043C64.6585 1.07552 64.4296 0.907372 64.1627 0.796206C63.896 0.685108 63.6179 0.641968 63.3232 0.641968C62.7824 0.641968 62.2296 0.8293 61.7768 1.29043C61.332 1.74336 61.1436 2.22678 61.1436 2.85215C61.1436 3.39501 61.3246 3.95344 61.7768 4.41387Z" fill="#094553" stroke="white"/>
      </Logotype>
    </LogoContainer>
  );
};