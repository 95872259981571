export enum FontSize {
    ExtraLarge = '24px',
    Larger= '20px',
    Large = '18px',
    Medium = '16px',
    Normal = '14px',
    Smaller = '13px',
    Small = '12px',
    Base = '10px',
    HiperLarge = "HiperLarge"
}

