import { Modifier, ReadOnlyModifierCategory, SelectedModifiers } from "../../../context/app";

export const getAmountFromModifierList = (modifierList: any) => {
  let totalAmount = 0;
  modifierList.forEach((modifier: any) => {
      totalAmount += modifier.amount;
  });
  return totalAmount;
};

export const checkMissingRequiredMods = (
  selectedModifiersByCategoryDict: SelectedModifiers,
  modifierCategories?: ReadOnlyModifierCategory[]
) => {
  if (!modifierCategories) return false;

  return modifierCategories.some((modifierCategory) => {
    const modifierCategoryId = modifierCategory.modifier_category.id;
    const minProducts = modifierCategory.min_selectable_products;

    if (!minProducts) return false;

    if (!(modifierCategoryId in selectedModifiersByCategoryDict)) return true;
    
    const selectedCategory = selectedModifiersByCategoryDict[modifierCategoryId];
    let totalAmount = 0;

    for (const modifierKey in selectedCategory) {
      const selectedModifierList = selectedCategory[modifierKey];
      totalAmount += getAmountFromModifierList(selectedModifierList);
    }

    return totalAmount < minProducts;
  });
}

export const deleteModifierBox = (
  modifierCategoryId: number,
  modifierId: number,
  indexToDelete: number,
  selectedModifiersByCategoryDict: SelectedModifiers,
  setSelectedModifiersByCategoryDict: React.Dispatch<React.SetStateAction<SelectedModifiers>>,
) => {
  if (modifierCategoryId in selectedModifiersByCategoryDict) {
    const selectedModifierCategory = selectedModifiersByCategoryDict[modifierCategoryId];

  if (modifierId in selectedModifierCategory) {
      const selectedModifierList = selectedModifierCategory[modifierId];

      if (indexToDelete < selectedModifierList.length) {
        const selectedModifiersByCategoryDictCopy = {...selectedModifiersByCategoryDict};
        selectedModifiersByCategoryDictCopy[modifierCategoryId][modifierId].splice(indexToDelete, 1);
        setSelectedModifiersByCategoryDict(selectedModifiersByCategoryDictCopy);
      }
    }
  }
};

export const getMaxExtras = (categoryId: any, modifierCategories?: ReadOnlyModifierCategory[]) => {
  let maxExtras = null;

  if (modifierCategories?.length) {
    modifierCategories.some((modifierCategoryInfo: any) => {
      if ('modifier_category' in modifierCategoryInfo && modifierCategoryInfo.modifier_category.id == categoryId) {
        maxExtras = modifierCategoryInfo.max_selectable_products;

        return true;
      }

      return false;
    });
  }

  return maxExtras;
}

export const getModifierOfModifier = (
  newSelectedModifiersByCategoryDict: SelectedModifiers,
  selectedModifiersByCategoryDict: SelectedModifiers,
  modifierPopupModifierCategoryId: any,
  modifierPopupModifierId: any,
  modifierCategories?: ReadOnlyModifierCategory[],
) => {

  const selectedModifiersByCategoryDictCopy = {...selectedModifiersByCategoryDict};
  const newModifier = {
    amount: 1,
    modifiers: newSelectedModifiersByCategoryDict
  };

  let modifierCategory: any;

  if (modifierPopupModifierCategoryId in selectedModifiersByCategoryDictCopy) {
    modifierCategory = selectedModifiersByCategoryDictCopy[modifierPopupModifierCategoryId];
    if (modifierPopupModifierId in modifierCategory) {
      const modifierList = modifierCategory[modifierPopupModifierId];
      modifierList.push(newModifier);
    } else {
      const maxExtras = getMaxExtras(modifierPopupModifierCategoryId, modifierCategories);
      if (maxExtras === 1) {
        modifierCategory = {}
        selectedModifiersByCategoryDictCopy[modifierPopupModifierCategoryId] = modifierCategory;
      }
      modifierCategory[modifierPopupModifierId] = [newModifier];
    }
  } else {
    modifierCategory = {};
    modifierCategory[modifierPopupModifierId] = [newModifier];
    selectedModifiersByCategoryDictCopy[modifierPopupModifierCategoryId] = modifierCategory;
  }
  
  return selectedModifiersByCategoryDictCopy;
};

export const listByFather = (modifiers: Modifier[]) => modifiers.reduce((arr, mod) => {
  const index = arr.findIndex((item) => item[0] === mod.father);

  index === -1 ? arr.push([mod.father, [mod]]) : arr[index][1].push(mod);
  return arr;
}, [] as [string, Modifier[]][])


export const getCartProductModifiersFromProduct = (
  selectedModifiersByCategoryDict: SelectedModifiers,
  product: any,
) => {
  const modifiers: Modifier[] = [];
  for (const modifierCategoryId in selectedModifiersByCategoryDict) {
    const modifierCategory = selectedModifiersByCategoryDict[modifierCategoryId];
    const productModifierCategories = product.modifier_categories
    const fullCategory = productModifierCategories.find((productModifierCategory:any) => {
      return productModifierCategory.modifier_category_id.toString() === modifierCategoryId;
    });

    if (!fullCategory) continue;

    for (const modifierId in modifierCategory) {
      const modifierList = modifierCategory[modifierId];

      const fullModifier = fullCategory.modifiers.find((modifier:any) => {
        return modifier.id.toString() === modifierId;
      });

      if (!fullModifier) continue;

      modifierList.forEach((modifier) => {
        const modifierCopy = { ...modifier, modifiers: [] as Modifier[] };

        if ('modifiers' in modifierCopy) {
          const modifiersOfModifiersByCategory = modifier.modifiers;
          const newModifiersOfModifiers: Modifier[] = [];

          for (const modifierOfModifierCategoryId in modifiersOfModifiersByCategory) {
            const modifierOfModifierCategory = modifiersOfModifiersByCategory[modifierOfModifierCategoryId];
            for (const modifierOfModifierId in modifierOfModifierCategory) {
              // It will always be position 0 because modifiers of modifiers will always have only one element
              const modifierOfModifier = modifierOfModifierCategory[modifierOfModifierId][0];
              const fullModifierOfModifier: any = modifierOfModifier.modifier_info;
              const finalModifiersDict = { ...fullModifierOfModifier, ...modifierOfModifier };
              newModifiersOfModifiers.push(finalModifiersDict);
            }
          }
          
          modifierCopy['modifiers'] = newModifiersOfModifiers;
        }
        const finalDict = { ...fullModifier, ...modifierCopy, father: fullCategory.modifier_category.visible_name };
        modifiers.push(finalDict);
      });
    }
  }
  return modifiers;
};