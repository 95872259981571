import { DeliveryTakeawayLanding } from "../../context/app";
import { baseTheme } from "./baseTheme";
import { generatePalette } from "../utils/colors";
import * as themeOptions from "./theme-options";

export const deliTakeLandingTheme = (data?: DeliveryTakeawayLanding | null) => {
  let newTheme = baseTheme;
  let deliveryTheme = {};
  let takeawayTheme = {};

  if (data) {
    const { theme, typography, color_takeaway, color_delivery } = data;

    newTheme = {
      ...baseTheme,
      ...themeOptions[theme],
      ...generatePalette(color_takeaway, 'primary'),
      ...generatePalette(color_delivery, 'tertiary'),
      typography,
    };
    
    deliveryTheme = deliTakeTheme(color_delivery, color_takeaway,'delivery_theme');
    takeawayTheme = deliTakeTheme(color_takeaway, color_delivery,'takeaway_theme');
  } else {
    deliveryTheme = deliTakeTheme('#5FB894', '#094553','delivery_theme');
    takeawayTheme = deliTakeTheme('#094553', '#5FB894','takeaway_theme');
  }

  const root = document.documentElement;

  for (const property in newTheme) {
    root.style.setProperty(`--${property}`, newTheme[property as keyof typeof newTheme]);
  };

  return { base: newTheme, deliveryTheme, takeawayTheme };
};

const deliTakeTheme = (primary: string, tertiary: string, className: string) => {
  const primaryPalette = generatePalette(primary, 'primary');
  const tertiaryPalette = generatePalette(tertiary, 'tertiary');

  const newTheme = {
    ...primaryPalette,
    ...tertiaryPalette,
  };

  const overrideTheme = Object.entries(newTheme).reduce((css: string, [property, value]) => css + `--${property}: ${value};`, '');

  const head = document.head;
  const style = document.createElement('style');

  style.textContent = `.${className} {${overrideTheme}}`;

  head.appendChild(style);

  return newTheme;
};