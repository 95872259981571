import React from "react";
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

export const initSentry = () => {
  
  const history = createBrowserHistory();

  Sentry.init({
    environment: "production",
    dsn: "https://02e00bee592504c62964f7b9c7fdf031@o4506223869100032.ingest.sentry.io/4506223875653632",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      })
    ],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: window.location.href.includes('localhost') ? 1.0 : 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0
  });
}
