import styled, { keyframes } from "styled-components";

const float = keyframes`
  0% {
    translate: 0 var(--half-width);
  }
  50% {
    translate: 0 var(--negative-half-width);
  }
  100% {
    translate: 0 var(--half-width);
  }
`;

const shadow = keyframes`
  0% {
    width: 0px;
    box-shadow: var(--negative-width) 0px 0 var(--color), inset 0 var(--negative-width) 0 var(--color);
    translate: var(--half-width) 0;
  }
  50% {
    width: 100%;
    box-shadow: 0px 0px 0 var(--color);
  }
  100% {
    width: 0px;
    box-shadow: var(--width) 0px 0 var(--color), inset 0 var(--negative-width) 0 var(--color);
    translate: var(--negative-half-width) 0;
  }
`;

const spin = keyframes`
  0% {
    scale: 0 1;
    translate: var(--half-width) 0;
    filter: brightness(0.9) saturate(1.1);
  }
  50% {
    scale: 1 1;
    filter: brightness(1.1) saturate(1);
  }
  100% {
    scale: 0 1;
    translate: var(--negative-half-width) 0;
    filter: brightness(0.9) saturate(1.1);
  }
`;

const SpinningContainer = styled.div<Spinning>`
  --width: ${({size}) => size * 0.16}px;
  --negative-width: calc(var(--width) * -1);
  --half-width: calc(var(--width) / 2);
  --negative-half-width: calc(var(--half-width) * -1);
  --color: ${({type, theme}) => type === 'cashback' ? theme.cash : theme.yumminn};
  
  display: flex;
  border-radius: ${({theme}) => theme.radius_round};
  position: relative;
  animation: ${float} 3s linear infinite;
  
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    background-color: var(--color);
    width: var(--width);
    height: 100%;
    border-radius: 1px;
  }
  
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: ${shadow} 2s cubic-bezier(.74,.45,.29,.58) infinite;
    background-color: var(--color);
  }
  
  svg {
    z-index: 2;
    animation: ${spin} 2s cubic-bezier(.74,.45,.29,.58) infinite;
  }
`;

type Spinning = {
  type: 'cashback' | 'point'
  size: number
}

export {
  SpinningContainer,
}