import React from 'react';
import styled from "styled-components";

const TabPillContainer = styled.div<{disabled?: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
  opacity: ${({disabled}) => disabled ? 0.5 : 1};
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TabSwitchContainer = styled.div<{disabled?: boolean}>`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  align-items: center;
  gap: 4px;
  width: 100%;
  background-color: ${({theme}) => theme.background};
  border: 1px solid ${({theme}) => theme.gray_50};
  border-radius: ${({theme}) => theme.radius_round};
  padding: 4px;
  box-shadow: ${({theme}) => theme.shadow_xs};
  box-sizing: border-box;
  opacity: ${({disabled}) => disabled ? 0.5 : 1};
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TabLineContainer = styled.div<{disabled?: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 2px solid ${({theme}) => theme.text_a20};
  opacity: ${({disabled}) => disabled ? 0.5 : 1};
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TabContainer = ({variant, ...props}: TabProps) => {
  if (variant === 'line') return <TabLineContainer {...props}/>;
  if (variant === 'switch') return <TabSwitchContainer {...props}/>;
  return <TabPillContainer {...props}/>;
};

export const Label = styled.label<{variant: 'pill' | 'switch' | 'line'}>`
  --color_pill: ${({theme}) => theme.primary};
  --color_switch: ${({theme}) => theme.gray_50};
  --color_line: ${({theme}) => theme.text_a50};

  --padding_pill: 4px 16px;
  --padding_switch: 8px 16px;
  --padding_line: 4px 16px;

  --border_pill: 1px solid ${({theme}) => theme.primary};
  --border_switch: none;
  --border_line: none;

  font-weight: 500;
  line-height: 22px;
  text-align: center;
  white-space: nowrap;
  color: ${({variant}) => `var(--color_${variant})`};
  padding: ${({variant}) => `var(--padding_${variant})`};
  border: ${({variant}) => `var(--border_${variant})`};
  border-radius: ${({theme}) => theme.radius_round};
  transition: 0.5s cubic-bezier(.35,.07,.22,1.04);
  z-index: 2;

  &:first-letter {
    text-transform: capitalize;
  }

  &:has(:checked) {
    color: ${({theme, variant}) => variant === 'line' ? theme.text : theme.background};
    font-weight: ${({variant}) => variant === 'pill' ? '600' : '500'};
  }

  input {
    opacity: 0;
    width: 0;
    position: absolute;
  }
`;

const PillSlider = styled.div<{color?: string}>`
  position: absolute;
  top: 0;
  height: 100%;
  background-color: ${({theme, color}) => color || theme.primary};
  border-radius: ${({theme}) => theme.radius_round};
  z-index: 1;
  padding: 0 1px;
  transition: 0.5s cubic-bezier(.35,.07,.22,1.04);
`;

const SwitchSlider = styled.div<{color?: string}>`
  position: absolute;
  top: 0;
  height: calc(100% - 8px);
  background-color: ${({theme, color}) => color || theme.primary};
  border-radius: ${({theme}) => theme.radius_round};
  margin: 4px 0;
  z-index: 1;
  transition: 0.5s cubic-bezier(.35,.07,.22,1.04);
`;

const LineSlider = styled.div<{color?: string}>`
  position: absolute;
  bottom: -2px;
  height: 2px;
  background-color: ${({theme, color}) => color || theme.primary};
  z-index: 1;
  transition: 0.5s cubic-bezier(.35,.07,.22,1.04);
`;

export const Slider = ({variant, ...props}: SliderProps) => {
  if (variant === 'line') return <LineSlider {...props}/>;
  if (variant === 'switch') return <SwitchSlider {...props}/>;
  return <PillSlider {...props}/>;
};

type TabProps = {
  disabled?: boolean
  variant: 'pill' | 'switch' | 'line'
  className?: string
  children: React.ReactNode
};

type SliderProps = {
  variant: 'pill' | 'switch' | 'line'
  color?: string
  style: React.CSSProperties
};