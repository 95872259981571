import React from 'react';
import { Icon, IconType } from '../../icons';
import { t } from 'i18next';
import { ToastOptions, toast } from 'react-toastify';
import { ToastContainer } from './ReceiptsContainers';
import { Trans } from 'react-i18next';

const TOAST_ID = 'toast_notify_receipt';

export const toastOptions: ToastOptions = {
  position: toast.POSITION.TOP_LEFT,
  hideProgressBar: true,
  autoClose: false,
  closeOnClick: false,
  closeButton: false,
  toastId: TOAST_ID,
  delay: 1000,
};

export const closeReceiptToast = () => toast.dismiss(TOAST_ID);

type Props = {
  openLastTicket: () => void
};

export const LastReceiptToast = ({ openLastTicket }: Props) => {
  const openReceipt = () => {
    closeReceiptToast();
    openLastTicket();
  };

  return (
    <ToastContainer>
      <button onClick={closeReceiptToast}>
        <Icon type={IconType.Close}/>
      </button>
      <span>
        <Trans i18nKey={'new_receipt_notification'}>
          you have <b>a recent order</b>
        </Trans>
      </span>
      <button onClick={openReceipt}>
        {t('see')}
      </button>
    </ToastContainer>
  );
};