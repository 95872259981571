import styled from "styled-components";

const VideoContainer = styled.dialog`
  position: fixed;
  inset: 50% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  border: none;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  padding: 0;
  z-index: 99;
  height: var(--dvh);; /* fallback */
  height: 100dvh;
  width: 100vw;
  max-height: 100vh; /* fallback */
  max-height: 100dvh;
  max-width: 100vw;

  &[open] {
    opacity: 1;
    pointer-events: all;
  }

  video {
    width: 100%;
    height: auto;
    object-fit: contain;
    pointer-events: none;
    padding-bottom: 10vh;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: center;
  gap: clamp(16px, 3vh, 24px);
  padding: clamp(8px, 1.5vh, 16px);
  color: ${({theme}) => theme.text};

  span {
    font-size: ${({theme}) => theme.font_xs};
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export {VideoContainer, ButtonContainer};