import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {Request as RegisterRequest}  from "../../api/register/Register.api";
import { LoginButton } from './login-buttons.component';
import { postError } from '../../shared/utils/postError';

type Props = {
  Render: (props: LoginButton) => JSX.Element
  from: string
  disabled?: boolean
  lopd: boolean
  news: boolean
  onSuccess: (data: any, type: string, from: string, isFirstLogin: boolean) => void;
  onFailure: (error: any) => void;
};

export const FacebookButton = ({Render, from, disabled, lopd, news, onSuccess, onFailure}: Props) => {
  const restaurantId = sessionStorage.getItem('restaurantId');
  const paymentId = sessionStorage.getItem("paymentId") || "";
  const restaurantGroupUuid = sessionStorage.getItem('restaurantGroupUuid') || '';

  const onFacebookRespone = (response: any) => {
    RegisterRequest.register({
      username: response.email,
      name: response.name,
      token: response.accessToken,
      type: "facebook",
      lopd,
      news,
      paymentId,
      restaurantId,
      restaurantGroupUuid,
      paymentSuccessfulPopup: from === 'pop-up_save-card',
    })
      .then((data: any) => onSuccess(data, "facebook", from, lopd))
      .catch((error: any) => {onFailure(error);postError(error)});
  };

  return (
    <FacebookLogin
      appId="205174719046179"
      isMobile={false}
      responseType='token'
      fields="name,email,picture"
      callback={onFacebookRespone}
      disabled={disabled}
      render={(renderProps: any) => <Render type='facebook' renderProps={renderProps}/>}
    />
  );
};