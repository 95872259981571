import styled from 'styled-components';
import { MO_DE_MOVIMIENTO } from '../../shared/constants/restaurantIds';

const TabsContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
`;

const ProfilePicture = styled.img`
    border-radius: 9999px;
    height: 80px;
    width: 80px;
    margin-bottom: 12px;
`;

const BorderOfSelectedItem = styled.div<props>`
    border-top-width: 2px;
    border-top-style: solid;
    width: 100%;
    border-color: ${props => props.restaurantId === MO_DE_MOVIMIENTO ? '#000000' : props.theme.primary};
`;

const BorderOfNotSelectedItem = styled.div`
    border-top-width: 2px;
    width: 100%;
    margin-top: -2px;
    border-top-style: solid;
    border-color: lightgray;
`;

type props = {
    restaurantId?: number
}


export {TabsContainer, BorderOfSelectedItem, ProfilePicture, BorderOfNotSelectedItem}