import React, {useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CheckboxContainer, Checkmark } from "./checkboxContainers";
import { OrderItem } from "../../context/app";
import { Icon, IconType } from "../icons";

export const CheckBoxLineItemComponent = ({item, addItem, removeItem, remaining, selectedLineItems, selectedLineModifiers, disabled = false} : props) => {
  const [isChecked, setIsChecked] = useState(false);
  const {t} = useTranslation();
  const handleClick = () => {
    if (disabled) {
      return
    }
    
    let selectedTotal = 0;
    selectedLineItems.map((sel_item) => {
        selectedTotal += Number(sel_item.price);
    })
    selectedLineModifiers.map((sel_modifier: any) => {
        selectedTotal += Number(sel_modifier.total_price);
    });
    selectedTotal += Number(item.price);

    if (!isChecked){
        if (remaining - selectedTotal >= 0) {
          addItem(item)
          setIsChecked(!isChecked)
        } else {
          toast.error((t('Total selected higher than remaining')), {
              theme: 'colored'
        });
        }
    } else {
        removeItem(item)
        setIsChecked(!isChecked)
    }
};

return (
  <CheckboxContainer isChecked={isChecked} onClick={handleClick} isDisabled={disabled}>
    {isChecked &&
      <Icon type={IconType.Check}/>
    }
  </CheckboxContainer>
);
};

type props={
  item: OrderItem;
  addItem: any;
  removeItem: any;
  remaining: any;
  selectedLineItems: OrderItem[];
  selectedLineModifiers: any[];
  disabled: boolean;
}
