import React, { useContext, useState } from "react";
import { t } from 'i18next';
import QRCode from 'react-qr-code';
import { useTheme } from 'styled-components';
import { Popup } from '../../../elements';
import { PickUpContainer, QRContainer, SmsContainer } from './PickUpQrContainers';
import { Trans } from 'react-i18next';
import { AppContext } from '../../../context/AppContext';

type Props = {
  orderCode?: string | null
  orderUuid?: string | null
  style?: React.CSSProperties
};

export const PickUpCard = ({ orderCode, orderUuid, style }: Props) => {
  const {
    state: {
      restaurant: {
        smsEnabled,
        isMockup
      }
    }
  } = useContext(AppContext);
  
  const theme: any = useTheme();

  const functionality = sessionStorage.getItem('functionality');
  const isOC = functionality === 'order_and_collect';

  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => setShowPopup(true);
  const closePopup = () => setShowPopup(false);

  if (!orderUuid && !isMockup) return <></>;

  return (
    <PickUpContainer style={style}>
      {isOC && !orderUuid && smsEnabled &&
        <SmsContainer>
          <p>
            ✉️
            <Trans i18nKey={"You'll get an sms when your order is ready!"}>
              <b>You&apos;ll get an sms</b> when your order is <b>ready to be picked up!</b>
            </Trans>
          </p>
        </SmsContainer>
      }
      {orderUuid &&
        <QRContainer onClick={openPopup}>
          <QRCode
            className='qr'
            fgColor={theme.qr_fg_color}
            bgColor={theme.qr_bg_color}
            value={orderUuid}
          />
          <div>
            <b>{t('tap_here')}</b>
            <p>{t('show_this_qr_if_req')}</p>
          </div>
          <Popup header={`${t('order')} ${orderCode}`} isOpened={showPopup} onClose={closePopup}>
            <QRCode
              className='qr'
              fgColor={theme.qr_fg_color}
              bgColor={theme.qr_bg_color}
              value={orderUuid}
            />
          </Popup>
        </QRContainer>
      }
    </PickUpContainer>
  );
};