import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./en.json";
import esTranslation from "./es.json";
import catTranslation from "./cat.json";
import deTranslation from "./de.json";
import frTranslation from "./fr.json";
import itTranslation from "./it.json";  
import ptTranslation from "./pt.json";

const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
  ca: {
    translation: catTranslation,
  },
  de: {
    translation: deTranslation,
  },
  fr: {
    translation: frTranslation,
  },
  it: {
    translation: itTranslation,
  },
  pt: {
    translation: ptTranslation,
  }
};

const getDefaultLanguage = () => {
  const functionality = sessionStorage.getItem('functionality') || '';
  const isOrderAndPay = ['order_at_table', 'order_and_collect'].includes(functionality);
  const selectedLanguage = sessionStorage.getItem("language");
  const navigatorLanguage = window.navigator.language?.split('-')[0];
  return selectedLanguage && (!isOrderAndPay || ['es', 'en', 'fr', 'ca'].includes(selectedLanguage)) ?
      selectedLanguage
    : navigatorLanguage && Object.keys(resources).includes(navigatorLanguage) ?
      navigatorLanguage
    : 'es';
};

const language = getDefaultLanguage();

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
