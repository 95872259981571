export const brandColors = {
  yumminn: '#5FB894',
  yumminn_10: '#EFF8F4',
  yumminn_20: '#EDFAF5',
  yumminn_40: '#E1F3EC',
  yumminn_blue: '#094553',
  cash: '#FFECBA',
  cash_10: '#FFFAEB',
  cash_20: '#FFF9E7',
  cash_40: '#FFF2CB',
  cash_a10: '#F7B5001A',
  discount: '#FEF282',
  blue_10: '#0A4553',
  wifi_blue: '#1A91E7',
};