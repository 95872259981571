import React, { useState } from "react";
import { Icon, IconType } from "../icons";
import { Font, FontSize } from "../fonts";
import "./contact-info.component.scss";
import { IContactInfo } from "./interfaces";
import { useTranslation } from "react-i18next";
import * as _ from 'lodash';
import styled, { useTheme } from "styled-components";

export const DAYS = [
  { id: '1', day: 'monday' },
  { id: '2', day: 'tuesday' },
  { id: '3', day: 'wednesday' },
  { id: '4', day: 'thursday' },
  { id: '5', day: 'friday' },
  { id: '6', day: 'saturday' },
  { id: '7', day: 'sunday' },
];

export const ContactInfoComponent = ({ content }: IContactInfo) => {
  const [openHours, setOpenHours] = useState(false);
  const [horarioOrdenado, setHorarioOrdenado] = React.useState<any[]>([])
  const { t } = useTranslation();
  const theme: any = useTheme();

  React.useEffect(() => {
    let ordenados = _.orderBy(content.opening_hours, ['day', 'hour_from']);
    ordenados = ordenados.map((o) => ({
      ...o,
        day: renderDay(o.day),
    }));
    setHorarioOrdenado(ordenados)
  },[content])

  const handleClick = () => {
    setOpenHours(!openHours);
  };

  const renderDay = (id: number) => {
    switch (id) {
      case 1:
        return t("mon");
      case 2:
        return t("tue");
      case 3:
        return t("wed");
      case 4:
        return t("thu");
      case 5:
        return t("fri");
      case 6:
        return t("sat");
      case 7:
        return t("sun");
      default:
        return;
    }
  };

  return (
    <div>
      {content.address && (
        <LabelContact>
          <Item>
            <Icon type={IconType.Position} />
          </Item>
          <div>
            <Font
              text={String(
                content.zip_code + " " + content.city + ", " + content.address
              )}
              size={FontSize.Normal}
            />
          </div>
        </LabelContact>
      )}

      {content.link && (
        <LabelContact>
          <Item>
            <Icon type={IconType.Globe} />
          </Item>
          <a href={content.link} target="_blank" rel="noreferrer">
            <Font
              text={content.link}
              size={FontSize.Normal}
              color={theme.primary}
              underline={true}
            />
          </a>
        </LabelContact>
      )}

      {content.phone && (
        <LabelContact>
          <Item>
            <Icon type={IconType.Phone} />
          </Item>
          <Font text={content.phone} size={FontSize.Normal} />
        </LabelContact>
      )}

      {content.email && (
        <LabelContact>
          <Item>
            <Icon type={IconType.Evelope} size={20} />
          </Item>
          <Font
            text={content.email}
            size={FontSize.Normal}
            color={theme.primary}
            underline={true}
          />
        </LabelContact>
      )}

      {content.opening_hours[0] && (
        <LabelContact>
          <Item>
            <Icon type={IconType.Time} />
          </Item>
          <Font text={t("opening hours")} size={FontSize.Normal} />
          <ArrowDown onClick={handleClick}>
            {openHours ? (
              <Icon type={IconType.ArrowUp}/>
            ) : (
              <Icon type={IconType.ArrowDown}/>
            )}
          </ArrowDown>
        </LabelContact>
      )}

      {openHours ? (
        <Hours>
          {/* eslint-disable-next-line */}
          {horarioOrdenado.map((item: any) =>
            item.opened ? (
              <div key={item.id}>
                <Font
                  text={`${item.day}: ${item.hour_from} - ${
                    item.hour_to
                  }`}
                  size={FontSize.Normal}
                />
              </div>
            ) : (
              <div key={item.id}>
                <Font
                  text={`${renderDay(item.day)}: Close`}
                  size={FontSize.Normal}
                />
              </div>
            )
          )}
        </Hours>
      ) : null}

      {content.instagram && (
        <LabelContact>
          <Item>
            <Icon type={IconType.Instagram} />
          </Item>
          <a href={content.instagram} target="_blank" rel="noreferrer">
            <Font text={content.instagram} size={FontSize.Normal} />
          </a>
        </LabelContact>
      )}

      {content.facebook && (
        <LabelContact>
          <Item>
            <Icon type={IconType.Facebook} colorIcon="#3888E7" size={22} />
          </Item>
          <a href={content.facebook} target="_blank" rel="noreferrer">
            <Font text={content.facebook} size={FontSize.Normal} />
          </a>
        </LabelContact>
      )}
    </div>
  );
};


const LabelContact = styled.div`
  display: flex;
  flex-direction: flex-row;
  padding-top: 8px;
`;

const Item = styled.div`
  margin-top: 0.5px;
  padding-right: 12px;
`;

const ArrowDown = styled.div`
  padding-left: 8px;
`;

const Hours = styled.div`
  padding-left: 32px;
  padding-top: 4px;
`;
