import React from 'react';
import { ProgressBarContainer } from './ProgressBarContainers';

export const ProgressBarComponent = ({current, total, icon, animated, className = ''}: Props) => {
  const progress = Math.min(100 / total * current, 100);
  
  const animate = (element: HTMLDivElement) => {
    if (animated && element) {
      const observer = new IntersectionObserver(([entry]) => {
        entry.isIntersecting && element.style.setProperty('width', `${progress}%`);
      });
  
      element.style.setProperty('width', `0%`);
      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    }
  };

  return (
    <ProgressBarContainer className={className}>
      <div style={{width: progress + '%'}} ref={animate}>
        {icon &&
          <span>{icon}</span>
        }
      </div>
    </ProgressBarContainer>
  );
};

type Props = {
  current: number;
  total: number;
  icon?: any;
  animated?: boolean;
  className?: string;
};