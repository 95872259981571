import React, {useState} from "react";

type SwipeInput = {
  onSwipedLeft: () => void
  onSwipedRight: () => void
}

export const useSwipe = (input: SwipeInput) => {
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const minSwipeDistance = 50;

  const onTouchStart = (event: React.TouchEvent) => {
    setTouchEnd(0);
    setTouchStart(event.targetTouches[0].clientX);
  }

  const onTouchMove = (event: React.TouchEvent) => setTouchEnd(event.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) {
      return;
    }

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      input.onSwipedLeft();
    }
  
    if (isRightSwipe) {
      input.onSwipedRight();
    }
  }

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd
  }
}