import React from "react";
import styled from "styled-components";
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion"

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({theme}) => theme.backdrop_color};
`;
const ModalContainer = styled(motion.div)<ModalContainerProps>`
  width: 100%;
  height: ${({ height }) => height};
  background-color: white;
  border-radius: 10% 10% 0 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  overflow: hidden
`;

type ModalContainerProps = {
  height: any
}

const CloseButton = styled.svg`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
`;

const modalVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 }
};
const containerVariant = {
  initial: { top: "100%", transition: { type: "spring" } },
  isOpen: { top: "60%" },
  exit: { top: "100%" }
};

const containerBigVariant = {
  initial: { top: "100%", transition: { type: "spring" } },
  isOpen: { top: "35%" },
  exit: { top: "100%" }
};

const ZeroSixModal = ({ handleClose, children, isOpen, response }: props) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay
          initial={"initial"}
          animate={"isOpen"}
          exit={"exit"}
          variants={modalVariant}
        >
          <ModalContainer height={response != "" ? '40%': '65%'} 
          initial={"initial"}
          animate={"isOpen"}
          exit={"exit"}
          variants={response != "" ? containerVariant : containerBigVariant}>

            <CloseButton
              onClick={handleClose}
              viewBox="0 0 20.39 20.39"
            >
              <title>close</title>
              <line
                x1="19.39"
                y1="19.39"
                x2="1"
                y2="1"
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
              <line
                x1="1"
                y1="19.39"
                x2="19.39"
                y2="1"
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
            </CloseButton>
            {children}
          </ModalContainer>
        </Overlay>
      )}
    </AnimatePresence>
  );
};

type props={
    handleClose: any,
    children: any,
    isOpen: any,
    response: string
  
  }
export default ZeroSixModal;
