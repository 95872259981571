import React, { useLayoutEffect, useRef } from 'react';
import { InnerContainer, Label, OuterConainer, Slider, TabContainer } from './ScrollTabsContainers';

export const ScrollTabsComponent = ({name, value, options, disabled, onChange}: Props) => {
  const tabsRef = useRef<Array<HTMLLabelElement | null>>(new Array(options.length));
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const valueIndex = options.indexOf(value);
  const sliderWidth = tabsRef.current[valueIndex]?.clientWidth || 0;
  const sliderPosition = tabsRef.current[valueIndex]?.offsetLeft || 0;

  const handleChange = (event: any) => {
    onChange(event.target.value);
  };

  useLayoutEffect(() => {
    scrollRef.current?.scrollTo({
      left: sliderPosition - scrollRef.current.clientWidth / 2 + sliderWidth / 2,
      behavior: 'smooth'
    });
  }, [value]);

  return (
    <OuterConainer ref={scrollRef}>
      <InnerContainer>
        <TabContainer disabled={disabled}>
          {options.map((option, index) => (
            <Label key={'option-' + option} ref={tab => tabsRef.current[index] = tab} selected={option === value}>
              <span>{option}</span>
              <input
                type="radio"
                name={name}
                value={option}
                checked={value === option}
                disabled={disabled}
                onChange={handleChange}
              />
            </Label>
          ))}
          <Slider style={{width: sliderWidth + 'px', left: sliderPosition + 'px'}}/>
        </TabContainer>
      </InnerContainer>
    </OuterConainer>
  );
};

type Props = {
  name: string;
  value: any;
  options: string[];
  disabled?: boolean;
  onChange: React.Dispatch<React.SetStateAction<any>>;
};