import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ScrollTabs } from '../scroll-tabs';
import { ContentWrapper, OuterWrapper, TabsHeader } from './ScrollSectionsContainers';

type Ref = {
  [key: string]: HTMLElement | null;
};

type Offsets = {
  id: string;
  value: number;
};

type Section = {
  id: number | string,
  label: string,
  element: JSX.Element
};

type Props = {
  name: string;
  sections: Section[];
  top?: number;
};

export const ScrollSectionsComponent = ({name, sections, top = 0}: Props) => {
  const [selectedSection, setSelectedSection] = useState<Section | null>(null);
  const sectionsRef = useRef<Ref>({});
  let timeout: NodeJS.Timeout;
  const TABS_HEIGHT = 62;

  const getOffsets = () => Object.entries(sectionsRef.current).reduce((arr: Offsets[], [key, value]) => {
    arr.push({id: key, value: value?.offsetTop || 0})
    return arr;
  }, [])

  const handleScroll = useCallback(() => {
    const currentScroll = window.scrollY;
    const sectionsOffset = getOffsets();

    if (currentScroll <= 0) {
      setSelectedSection(sections[0]);
      return;
    }

    if (document.documentElement.scrollHeight - currentScroll - innerHeight < 1) {
      setSelectedSection(sections[sections.length - 1]);
      return;
    }

    const currentOffset = sectionsOffset.find(offset => offset.value === Math.max(
      ...sectionsOffset.map(offset => offset.value < currentScroll + innerHeight / 2 ? offset.value : 0)
    ));
    const currentSection = sections.find(section => section.id.toString() === currentOffset?.id);

    currentSection && setSelectedSection(currentSection);
  }, [sections, window, setSelectedSection]);

  const handleTabClick = useCallback((value: string) => {
    const selectedTab = sections.find(section => section.label === value);

    const sectionsOffset = getOffsets();
    
    if (selectedTab) {
      setSelectedSection(selectedTab);
      clearTimeout(timeout);

      window.removeEventListener('scroll', handleScroll);

      const offset = sectionsOffset.find(offset => offset.id === selectedTab.id.toString());
      const margin = top + TABS_HEIGHT;

      offset && window.scrollTo({top: offset?.value - margin, behavior: 'smooth'});
      timeout = setTimeout(() => window.addEventListener('scroll', handleScroll), 1500);
    }
  }, [sections, window, handleScroll]);

  useEffect(() => {
    if (window && sections.length > 1) {
      window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [window, sections, handleScroll]);

  useEffect(() => {
    setSelectedSection(prev => prev || sections[0]);
  }, [sections]);

  return (
    <OuterWrapper>
      {sections.length > 1 &&
        <TabsHeader top={top}>
          <ScrollTabs name={name} value={selectedSection?.label} options={sections.map(section => section.label)} onChange={handleTabClick}/>
        </TabsHeader>
      }
      <ContentWrapper>
        {sections.map(section => (
          <section key={`${name}-${section.id}`} ref={el => sectionsRef.current[section.id] = el}>
            {section.element}
          </section>
        ))}
      </ContentWrapper>
    </OuterWrapper>
  );
};