import React from 'react';
import { useTheme } from 'styled-components';
import { ProgressButton, ProgressContent, ProgressOverlay } from './ProgressButtonContainers';

type Props = {
  total: number
  current: number
  title: React.ReactNode
  size: string
  color?: string
  bgColor?: string
  progressBg?: boolean
  progressOverlay?: boolean
  disabled?: boolean
  elementName?: string
  onClick: () => void
};

export const ProgressButtonComponent = ({
  total,
  current,
  title,
  size,
  color,
  bgColor,
  progressBg,
  progressOverlay,
  disabled,
  onClick
}: Props) => {
  const theme: any = useTheme();
  const progress = Math.min(100 / total * current, 100);
  const sectorProgress = `${31.4 * progress / 100} 31.4`;
  const ringProgress = `${Math.max(62.8 * progress / 100 - 0.75, 0)} 62.8`;
  const maskOffset = -31.4 * progress / 100;
  const maskProgress = `${31.4 * (100 - progress) / 100} 31.4`;

  return (
    <ProgressButton
      size={size}
      color={color}
      bgColor={bgColor}
      disabled={disabled}
      onClick={onClick}
    >
      <svg height="20" width="20" viewBox="0 0 22 22">
        {progressBg &&
          <circle
            r="5"
            cx="11"
            cy="11"
            strokeDasharray={sectorProgress}
          />
        }
        <circle
          r="10"
          cx="11"
          cy="11"
        />
        <circle
          r="10"
          cx="11"
          cy="11"
          stroke={progress ? color || theme.tertiary : 'transparent'}
          strokeDasharray={ringProgress}
        />
      </svg>
      <ProgressContent isGrayScale={progressOverlay}>
        {title}
      </ProgressContent>
      {progressOverlay &&
        <ProgressOverlay size={size}>
          <svg id="mask" height="20" width="20" viewBox="0 0 22 22">
            <circle
              r="5"
              cx="11"
              cy="11"
              strokeDashoffset={maskOffset}
              strokeDasharray={maskProgress}
            />
          </svg>
          {title}
        </ProgressOverlay>
      }
    </ProgressButton>
  );
};