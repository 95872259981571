import styled from "styled-components";

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.background};
  z-index: 1;
`;

const TabsHeader = styled.div<Tabs>`
  width: 100%;
  height: fit-content;
  background-color: ${({theme}) => theme.background};
  box-sizing: border-box;
  position: sticky;
  top: ${({top}) => top ?? 0}px;
  z-index: 2;
`;

const ContentWrapper = styled.div`
  flex: 1;
  background-color: ${({theme}) => theme.background};
  z-index: 1;
`;

type Tabs = {
  top?: number;
}

export {OuterWrapper, TabsHeader, ContentWrapper};