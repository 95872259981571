import React, { useContext } from 'react';
import { t } from 'i18next';
import { Icon, IconType } from '../icons';
import { BadgeContainer, SvgWrapper } from './AllergensContainers';
import { ALLERGENS_LIST } from '../../shared/constants/allergens';
import { Allergens } from '../../context/app';
import { AppContext } from '../../context/AppContext';

type Props = {
  value: Allergens;
  size?: number;
  showLabel?: boolean;
};

export const AllergenBadgeComponent = ({value, size = 16, showLabel = false}: Props) => {
  const { state: { restaurantCustomization : { theme } } } = useContext(AppContext);

  const allergen = ALLERGENS_LIST.find(allergen => allergen.value === value);

  if (!allergen) return <></>;

  const { label, color } = allergen;

  const themeColor = ['light', 'red'].includes(theme) ? color : 'currentColor';

  return (
    <BadgeContainer>
      <SvgWrapper size={size} color={themeColor}>
        <Icon type={IconType[label]} colorIcon={themeColor} size={size}/>
      </SvgWrapper>
      {showLabel &&
        <span>
          {t(label)}
        </span>
      }
    </BadgeContainer>
  );
};