import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../../context/AppContext'
import { PopupType } from '../../../context/popups.enum'
import { Icon, IconType } from '../../../elements'
import { usePopupNavigation } from '../../../shared/utils/usePopupNavigation'
import { PopupContext } from '../../../context/PopupContext'


export const McNotification = () => {
  const {
    state: {
      restaurant: {
        masterC2pPromotionCents,
      },
    },
  } = useContext(AppContext);
  const { popup } = useContext(PopupContext);
  const {goToPopup} = usePopupNavigation()
  const discount = Math.floor(masterC2pPromotionCents / 100);

  return (
    <MasterPill isShown={!!discount && popup.current === PopupType.None}
    onClick={() => {!!discount && popup.current === PopupType.None ? goToPopup(PopupType.MasterPromoInfo) : ""}}>
      <Icon type={IconType.Master} size={12}/>
      <span >-{discount}€</span>
    </MasterPill>
  )
}

const MasterPill = styled.div<{isShown: boolean}>`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: black;
  border-radius: ${({theme}) => theme.radius_round};
  color: ${({theme}) => theme.text_light};
  font-size: ${({theme}) => theme.font_xs};
  font-weight: 700;
  line-height: 1em;
  padding: 6px;
  text-transform: uppercase;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  translate: ${({isShown}) => isShown ? '60% -25%' : '60% 0%'};
  scale: ${({isShown}) => isShown ? 1 : 0.5};
  z-index: 4;
  opacity: ${({isShown}) => isShown ? 1 : 0};
  transition: 0.2s ease-in-out;
`;