const memorizeExperiments = () => {
  let experiments: any[] = [];

  const getViewedExperiments = () => experiments;

  const setViewedExperiments = (newExp: any[]) => {
    experiments = newExp;
  };

  return { getViewedExperiments, setViewedExperiments };
};

export const { getViewedExperiments, setViewedExperiments } = memorizeExperiments();