import React from "react";

import { Font } from "../fonts";
import { InputType } from "./enums";
import { IInput } from "./interfaces";
import styled from "styled-components";

export const InputComponent = ({
  name,
  icon,
  label = '',
  value,
  checked,
  fontSize = '16px',
  placeholder,
  disabled,
  autoComplete,
  type = InputType.Text,
  onChange,
  onBlur,
  defaultChecked,
}: IInput) => (
    type === "checkbox" ? (
      <Checkbox>
        <input
          type="checkbox"
          id={name}
          name={name}
          defaultChecked={defaultChecked}
          onChange={onChange}
          defaultValue={value}
          disabled={disabled}
          onBlur={onBlur}
          checked={checked}
          autoComplete={autoComplete}
          />
      </Checkbox>
    ) : (
      <div>
        <Font text={label} />

        <InputStyledWrapper>
          <Input
            fontSize={fontSize}
            id={name}
            type={type}
            name={name}
            defaultValue={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
          />
          {icon && <Icon>{icon}</Icon>}
        </InputStyledWrapper>
      </div>
    )
);

type inputProps = {
  fontSize?: string;
}

const InputStyledWrapper = styled.div`
    border-radius: 9px;
    border-width: 2px;
    display: block;
`;

const Input = styled.input<inputProps>`
  margin: 8px 0;
  border-radius: 9px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({theme}) => theme.gray_40};
  appearance: none;
  padding: 10px 12px;
  width: 100%;
  box-sizing: border-box;
  font-family: ${({theme}) => theme.typography};
  font-size: ${({fontSize}) => fontSize || '16px'};
  
  &:focus {
    outline: none;
    background: white;
  }
  
  &::placeholder {
    font-family: ${({theme}) => theme.typography};
    font-size: ${props => props.fontSize ? props.fontSize : ""};
  }
`;

const Icon = styled.div`
  display: flex;
  padding-right: 12px;
  right: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  align-items: center;
`;

const Checkbox = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13px;
  height: 13px;
  border: 2px solid ${({theme}) => theme.gray_50};
  border-radius: 4px;
  padding: 3px;
  flex-shrink: 0;
  
  &:has(:checked)::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${({theme}) => theme.primary};
    border-radius: 2px;
  }

  input {
    opacity: 0;
    width: 0;
    position: absolute;
  }
`;