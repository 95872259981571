import React from 'react';
import { t } from 'i18next';
import illustration from '../../assets/images/redesign/404.svg'
import { Trans } from 'react-i18next';
import { NotFoundContainer } from './NotFoundContainers';

type Props = {
  cta?: React.ReactNode
};

export const EventsNotFound = ({cta}: Props) => {
  return (
    <NotFoundContainer>
      <img src={illustration} alt='event not found' />
      <p>
        <Trans i18nKey={"events_error_message"}>
          <b>Oops!</b>There was a problem loading the events.
        </Trans>
      </p>
      <p>{t(`Contact the nearest waiter`)}</p>
      {cta}
    </NotFoundContainer>
  );
};