import React from 'react';
import { useTheme } from 'styled-components';
import { CircleContainer, Gap, TitleContainer } from './ProgressCircleContainers';

type Props = {
  total: number;
  current: number;
  title?: string;
  sectors?: boolean;
};

export const ProgressCircleComponent = ({total, current, title, sectors}: Props) => {
  const theme: any = useTheme();
  const progress = Math.min(100 / total * current, 100);
  const gapDegree: string[] = [];

  for (let i = 0; i < total; i++) {
    gapDegree.push(i * 360 / total + 'deg')
  }

  return (
    <CircleContainer
      style={{
        backgroundImage: `conic-gradient(
          from 0deg at 50% 50%,
          ${theme.primary} 0% ${progress}%,
          transparent ${progress}% 100%
        )`
      }}
    >
      {sectors &&
        gapDegree.map(deg => <Gap style={{rotate: deg}} key={'gap-' + deg}/>)
      }
      <TitleContainer>
        {title}
      </TitleContainer>
    </CircleContainer>
  );
};