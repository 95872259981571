import styled, { keyframes } from "styled-components";

const RoadmapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: relative;
`;

const RoadmapContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  `;

const RoadmapLabel = styled.span`
  font-size: ${({theme}) => theme.font_xs};
  font-weight: 500;
  line-height: 1em;
  text-align: center;
  color: ${({theme}) => theme.text_a80};
  margin: 0 -15vw;
`;

const blink = (theme: any) => keyframes`
  0% {
    background-color: ${theme.tertiary};
  }
  100% {
    background-color: ${theme.background_sunken};
  }
`;

const DotsContainer = styled.div<Dots>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: calc(8px + ${({theme}) => theme.font_xs});
  
  hr {
    height: 5px;
    width: 5px;
    background-color: ${({isCompleted, theme}) => isCompleted ? theme.tertiary : theme.background_sunken};
    border: none;
    border-radius: ${({theme}) => theme.radius_round};
    margin: 0;
    animation: ${({isActive, theme}) => isActive ? blink(theme) : 'none'} 0.5s ease-in-out alternate infinite;

    &:nth-of-type(2) {
      animation-delay: 0.25s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.5s;
    }
  }
`;

type Dots = {
  isCompleted: boolean
  isActive: boolean
}

export {
  RoadmapWrapper,
  RoadmapContainer,
  RoadmapLabel,
  DotsContainer,
};