import { EventCatalogState } from "./app";

export type EventCatalogAction =
	| { type: 'SET_TICKETS', payload: EventCatalogState['tickets'] }
	| { type: 'CLEAR_TICKETS' }
;

export const eventCatalogReducer = (state: EventCatalogState, action: EventCatalogAction): EventCatalogState => {
  switch (action.type) {
    case 'SET_TICKETS':
      return {
        ...state,
        tickets: action.payload,
        totalPriceCents: action.payload.reduce((total, ticket) => total += (ticket.price_cents * ticket.amount), 0),
        totalAmount: action.payload.reduce((total, ticket) => total += ticket.amount, 0),
      };

    case 'CLEAR_TICKETS':
      return {
        ...state,
        tickets: [],
        totalAmount: 0,
      };
  };
};