import styled from "styled-components";
import iconBackground from '../../assets/images/redesign/icon_background.svg'

const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: ${({theme}) => theme.font_xs};
  font-weight: 500;

  span {
    margin-left: 8px;
  }

  & > i {
    font-size: ${({theme}) => theme.font_xs};
    line-height: 1;
    color: ${({theme}) => theme.danger};
    display: flex;
    margin: 4px 0 0;
  }
`;

const InputWrapper = styled.div<inputWrapper>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: clamp(6px, 1vh, 8px);
  color: ${({theme}) => theme.text};
  border: 2px solid ${({theme, $error}) => $error ? theme.danger_70 : theme.gray_60};
  border-radius: 9999px;

  img {
    background-image: url(${iconBackground});
    background-size: cover;
    height: 24px;
    width: 24px;
    padding: 3px;
    margin: -3px;
  }

  & > input,
  & > select,
  .react-datepicker-wrapper,
  .react-datepicker__input-container input {
    appearance: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    height: 24px;
    width: 15ch;
    color: currentColor;
    background-color: transparent;
    font-size: ${({theme}) => theme.font_md};
    font-weight: ${({value}) => value === '' ? 500 : 600};
    font-family: ${({theme}) => theme.typography};
    flex: 1;

    &::placeholder {
      font-weight: 500;
    }
  }

  // labels can't open select lists :(
  & > select {
    box-sizing: content-box;
    padding: 6px 38px;
    margin: -6px -38px;
    z-index: 2;
    color: ${({value, theme}) => value ? 'currentColor' : theme.gray_80}
  }

  .react-datepicker {
    font-family: Montserrat, sans-serif;
    font-size: ${({theme}) => theme.font_md};
    border-radius: 20px;
    border-color: ${({theme}) => theme.gray_40};
    filter: drop-shadow(0 10px 10px #00000020);
  }

  .react-datepicker__header {
    background-color: transparent;
    border: none;
  }

  .react-datepicker__navigation {
    margin: 14px 8px 14px 0;
    align-items: center;
  }

  .react-datepicker__navigation-icon::before {
    border-color: ${({theme}) => theme.text};
  }

  .react-datepicker__current-month {
    font-size: ${({theme}) => theme.font_lg};
    text-transform: capitalize;
    margin: 12px 0;
    color: ${({theme}) => theme.text};
  }
  
  .react-datepicker__month-select, .react-datepicker__year-select {
    border-radius: 999px;
    color: ${({theme}) => theme.text};
    border: 2px solid ${({theme}) => theme.gray_60};
    font-size: 16px;
    padding: 6px 24px 6px 6px;
    margin: 0 4px;
    appearance: none;
    background-color: #FFF;
    background-image: url("data:image/svg+xml;utf8,<svg fill='rgb(95, 184, 148)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 3px;

    &:focus-visible {
      outline: none;
      border-color: ${({theme}) => theme.primary};
    }
  }

  .react-datepicker__day, .react-datepicker__day-name {
    padding: 5px;
    color: ${({theme}) => theme.text};
  }

  .react-datepicker__day-names {
    margin-top: 16px;
    background-color: ${({theme}) => theme.primary};
  }

  .react-datepicker__day-name {
    color: #FFF;
    font-weight: 600;
  }
  
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #5FB894!important;
    border-radius: 9999px;
    color: #FFF;
    font-weight: 600;
  }

  [type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus{
    border-color: #5FB894!important;
    --tw-ring-color: #5FB894!important;
  }

  &:has(:focus-visible) {
    border-color: ${({theme}) => theme.primary};
  }

  &:not(:has([type='checkbox'])), &:has(input:disabled) {
    color: ${({theme}) => theme.gray_80};
    background-color: ${({theme}) => theme.gray_20};
    border: 2px solid ${({theme}) => theme.gray_60};
  }
`;

type inputWrapper = {
  $error: boolean,
  value: any,
}

export {Label, InputWrapper};