import React from 'react';
import styled from 'styled-components';
import { t } from 'i18next';

const HelpLink = styled.a`
  font-size: ${({theme}) => theme.font_xs};
  font-weight: 600;
  text-align: center;
  text-decoration: underline;
  color: ${({theme}) => theme.text};
  margin: auto 0 16px 0;
  display: none;
`;

export const HelpLinkComponent = () => { // link ??????????????????????
  return (
    <HelpLink href='#' target='_blank' rel='noopener'>
      {t('detected a problem? contact us')}
    </HelpLink>
  );
};
