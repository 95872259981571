import React, { ReactNode, Fragment, useCallback, useEffect, useContext } from "react";
import ScrollToTop from "./ScrollToTop";
import * as Sentry from '@sentry/react';
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { LayoutContainer } from "../shared/layout";
import { AuthContext } from "../context/auth/AuthContext";
import { HeaderBar } from "../elements/header-bar/HeaderBar";
import { QrUuidReader } from "../elements/qr-uuid-reader";
import { ZeroSixCards } from "../pages/zerosix/zerosix-cards";
import { PaymentError } from "../elements/payment-error";
import { lazyImportHandler } from "./lazyImportHandler";
import { initSentry } from "../../sentry/Sentry";
import { VerificationEmail } from "../elements/register/VerificationEmail";
import { Request } from "../api/auth/Login.api";
import { postError } from "../shared/utils/postError";


const Home                  = lazyImportHandler(() => import("../pages/home"                    ), 'Home'                  );
const QrReader              = lazyImportHandler(() => import("../pages/qr-reader"               ), 'QrReader'              );
const PaymentSuccessful     = lazyImportHandler(() => import("../pages/payment-successful"      ), 'PaymentSuccessful'     );
const CompletePayment       = lazyImportHandler(() => import("../pages/complete-payment"        ), 'CompletePayment'       );
const Event                 = lazyImportHandler(() => import("../pages/event"                   ), 'Event'                 );
const EventSuccessful       = lazyImportHandler(() => import("../pages/event-payment-successful"), 'EventPaymentSuccessful');
const Profile               = lazyImportHandler(() => import("../pages/profile"                 ), 'Profile'               );
const Menu                  = lazyImportHandler(() => import("../pages/menu"                    ), 'Menu'                  );
const Legal                 = lazyImportHandler(() => import("../pages/legal"                   ), 'Legal'                 );
const ForgotPassword        = lazyImportHandler(() => import("../pages/forgot-password"         ), 'ForgotPassword'        );
const Register              = lazyImportHandler(() => import("../pages/register"                ), 'Register'              );
const SuccessForgotPassword = lazyImportHandler(() => import("../pages/success-forgot-password" ), 'SuccessForgotPassword' );
const Login                 = lazyImportHandler(() => import("../pages/auth"                    ), 'Login'                 );
const PersonalInfo          = lazyImportHandler(() => import("../pages/personal-info"           ), 'PersonalInfo'          );
const ChangePassword        = lazyImportHandler(() => import("../pages/change-password"         ), 'ChangePassword'        );
const LatestBills           = lazyImportHandler(() => import("../pages/latest-bills"            ), 'LatestBills'           );
const RestaurantBill        = lazyImportHandler(() => import("../pages/restaurant-bill"         ), 'RestaurantBill'        );
const SavedRestaurants      = lazyImportHandler(() => import("../pages/saved-restaurants"       ), 'SavedRestaurants'      );
const LoyaltyCards          = lazyImportHandler(() => import("../pages/loyalty"                 ), 'LoyaltyCards'          );
const LoyaltyDetails        = lazyImportHandler(() => import("../pages/loyalty-details"         ), 'LoyaltyDetails'        );
const SavedCards            = lazyImportHandler(() => import("../pages/saved-cards"             ), 'SavedCards'            );
const AddCard               = lazyImportHandler(() => import("../pages/add-card"                ), 'AddCard'               );
const RestaurantGroup       = lazyImportHandler(() => import("../pages/restaurant-group"        ), 'RestaurantGroup'       );
const Feedback              = lazyImportHandler(() => import("../pages/feedback"                ), 'Feedback'              );
const DeliveryTakeaway      = lazyImportHandler(() => import("../pages/delivery-takeaway"       ), 'DeliveryTakeaway'      );
const EventCatalog          = lazyImportHandler(() => import("../pages/event-catalog"           ), 'EventCatalog'          );
const Components            = lazyImportHandler(() => import("../pages/components"              ), 'Components'            );
const NotFound              = lazyImportHandler(() => import("../pages/not-found"               ), 'NotFound'              );
const ZeroSixLoyaltyCards   = lazyImportHandler(() => import("../pages/zerosix"                 ), 'ZeroSixLoyaltyCards'   );
const BookingLanding        = lazyImportHandler(() => import("../pages/booking-landing"         ), 'BookingLanding'        );
const Booking               = lazyImportHandler(() => import("../pages/booking"                 ), 'Booking'               );
const BookingSuccessful     = lazyImportHandler(() => import("../pages/booking-successful"      ), 'BookingSuccessful'     );
const AccountVerification   = lazyImportHandler(() => import("../pages/account-verification"    ), 'AccountVerification'   );
const MyAccount             = lazyImportHandler(() => import("../pages/my-account"              ), 'MyAccount'             );
const WebviewAndroidGpay    = lazyImportHandler(() => import("../pages/webview-android-gpay"    ), 'WebviewAndroidGpay'    );
const CreditHistory         = lazyImportHandler(() => import("../pages/credit-history"          ), 'CreditHistory'         );
const Wallet                = lazyImportHandler(() => import("../pages/wallet"                  ), 'Wallet'                );
const CashPayment           = lazyImportHandler(() => import("../pages/cash-payment"            ), 'CashPayment'           );
const MahouAuth             = lazyImportHandler(() => import("../pages/mahou-auth"              ), 'MahouAuth'             );


let SentryRoute: typeof Route;
if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
  SentryRoute = Route;
} else {
  initSentry()
  SentryRoute = Sentry.withSentryRouting(Route);
}

type AuthenticatedRouteProps = {
  path: string;
  children: ReactNode;
};

const AuthenticatedRoute = ({ path, children, ...rest }: AuthenticatedRouteProps) => {
  const { isLogged } = useContext(AuthContext);
  const {isVerified, loadingVerification} = Request.useUserVerification(isLogged);
  const type = localStorage.getItem("type");

  if (loadingVerification) {
    return null; // Don't render anything until useUserVerification check is completed
  }

  return (
    <SentryRoute
      {...rest}
      path={path}
      render={({ location }) =>
        isLogged && !isVerified && type === 'internal' ? (
          <Redirect
            to={{
              pathname: '/verify_account',
              state: { from: location},
            }}
          />
        ) : (
          children
        )
      }
    />
  );
};

export const BootstrapRouting = () => {
  const { isLogged } = useContext(AuthContext);
  const { isVerified } = Request.useUserVerification(isLogged);

  const history = useHistory();

  const handleRedirectRegister = useCallback((isVerified: boolean) => {
      const restaurantGroupUuid = sessionStorage.getItem("restaurantGroupUuid") || "";
      const qrUuid = sessionStorage.getItem('qrUuid') || "";
      const goBackUrl = history?.location?.state as { goBackUrl: string };
      const goBackUrlString:string = goBackUrl?.goBackUrl
      if (goBackUrlString) {
        return <Redirect to={goBackUrlString}/>;
      } else {
        if(restaurantGroupUuid){
          return <Redirect to={{pathname: `/restaurant_group/${restaurantGroupUuid}`, state: { isVerified } }}/>;
        } 
        else if (qrUuid) {
          return <Redirect to={{pathname: `/${qrUuid}`, state: { isVerified } }}/>;
        }
        return <Redirect to={'/profile'}/>;
      }
  }, []);

  useEffect(() => {
    try {
      // We do not allow page translations of any type
      const root = document.getElementById("root");
      if (root && "classList" in root) {
        root.setAttribute("translate", "no");
        root.classList.add('notranslate');
      }
    } catch (e: any) {
      console.log(e)
      postError(e)
    }

    const setDynamicSizes = () => {
      const root = document.documentElement;

      root.style.setProperty(`--dvh`, `${window?.visualViewport?.height || innerHeight}px`)
      root.style.setProperty(`--dvw`, `${window?.visualViewport?.width || innerWidth}px`)
    };

    setDynamicSizes();

    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', setDynamicSizes);
    } else {
      window.addEventListener('resize', setDynamicSizes);
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', setDynamicSizes);
      } else {
        window.removeEventListener('resize', setDynamicSizes);
      }
    };
  }, []);

  return (
    <Fragment>
      <ScrollToTop>
        <Switch>

          <AuthenticatedRoute path="/home">
            <QrReader />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/checkout">
            <Home />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/event">
            <Event />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/restaurant_group">
            <RestaurantGroup />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/delivery_takeaway/:uuid">
            <DeliveryTakeaway />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/booking_landing/:uuid">
            <BookingLanding />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/booking/:uuid">
            <Booking />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/booking_successful">
            <BookingSuccessful />
          </AuthenticatedRoute>

          <AuthenticatedRoute path={"/event_catalog"}>
            <EventCatalog />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/event-payment-successful">
            <LayoutContainer>
              <EventSuccessful />
            </LayoutContainer>
          </AuthenticatedRoute>

          <AuthenticatedRoute path={"/wallet"}>
            <Wallet/>
          </AuthenticatedRoute>
          
          <AuthenticatedRoute path={"/cash-payment"}>
            <CashPayment/>
          </AuthenticatedRoute>

          <AuthenticatedRoute path={"/mahou-auth"}>
            <MahouAuth/>
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/payment-successful">
            <LayoutContainer>
              <PaymentSuccessful />
            </LayoutContainer>
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/complete-payment">
            <LayoutContainer>
              <CompletePayment />
            </LayoutContainer>
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/profile">
            <LayoutContainer>
              <Profile />
            </LayoutContainer>
          </AuthenticatedRoute>
          
          <AuthenticatedRoute path="/payment-error">
            <PaymentError />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/menu">
            <Menu />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/legal">
            <Legal />
          </AuthenticatedRoute>

          <SentryRoute path="/register">
            {isVerified ? 
            handleRedirectRegister(isVerified)
            :
            <LayoutContainer>
              <Register />
            </LayoutContainer>
            }
          </SentryRoute>

          <SentryRoute path="/verify_account">
            {isVerified ?
                handleRedirectRegister(isVerified)
            :
            <LayoutContainer>
              <VerificationEmail />
            </LayoutContainer>
            }
          </SentryRoute>

          <SentryRoute path="/forgot-password">
            <LayoutContainer>
              <ForgotPassword />
            </LayoutContainer>
          </SentryRoute>

          <SentryRoute path="/success-forgot-password">
            <LayoutContainer>
              <SuccessForgotPassword />
            </LayoutContainer>
          </SentryRoute>

          <SentryRoute path="/login">
            <LayoutContainer>
              <Login />
            </LayoutContainer>
          </SentryRoute>

          <AuthenticatedRoute path="/my-account">
            <LayoutContainer>
              <MyAccount/>
            </LayoutContainer>
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/personal-info">
            <LayoutContainer>
              <PersonalInfo />
            </LayoutContainer>
          </AuthenticatedRoute>

          <SentryRoute path="/change-password/:token">
            <LayoutContainer>
              <HeaderBar />
              <ChangePassword />
            </LayoutContainer>
          </SentryRoute>

          <AuthenticatedRoute path="/latest-bills">
            <LayoutContainer>
              <LatestBills />
            </LayoutContainer>
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/restaurant-bill">
            <LayoutContainer>
              <RestaurantBill />
            </LayoutContainer>
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/saved-restaurants">
            <LayoutContainer>
              <SavedRestaurants />
            </LayoutContainer>
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/loyalty-cards">
            <LayoutContainer>
              <LoyaltyCards />
            </LayoutContainer>
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/credit-history">
            <LayoutContainer>
              <CreditHistory />
            </LayoutContainer>
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/zerosix-cards">
            <LayoutContainer>
              <ZeroSixCards />
            </LayoutContainer>
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/loyalty-details">
            <LayoutContainer>
              <LoyaltyDetails />
            </LayoutContainer>
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/saved-cards">
            <LayoutContainer>
              <SavedCards />
            </LayoutContainer>
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/zerosix">
            <LayoutContainer>
              <ZeroSixLoyaltyCards />
            </LayoutContainer>
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/add-card">
            <LayoutContainer>
              <AddCard />
            </LayoutContainer>
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/webview-android-gpay">
            <LayoutContainer anotherBackground={true}>
              <WebviewAndroidGpay />
            </LayoutContainer>
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/feedback">
            <Feedback/>
          </AuthenticatedRoute>

          <SentryRoute path="/account-verification/:uuid">
            <AccountVerification />
          </SentryRoute>

          {window.location.hostname === 'localhost' &&
            <AuthenticatedRoute path='/components'>
              <Components/>
            </AuthenticatedRoute>
          }

          <AuthenticatedRoute path="/:qr_uuid">
            <QrUuidReader />
          </AuthenticatedRoute>

          <AuthenticatedRoute path='*'>
            <LayoutContainer>
              <HeaderBar/>
              <NotFound/>
            </LayoutContainer>
          </AuthenticatedRoute>

        </Switch>
      </ScrollToTop>
    </Fragment>
  );
};
