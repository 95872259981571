import React, { Dispatch, createContext, useContext, useEffect } from 'react';
import { ThemeProvider } from "styled-components";
import { baseTheme } from "../../shared/themes";
import { useReducer } from "react";
import { ThemeAction, themeReducer } from "./themeReducer";
import { Theme } from "./theme";

const setCssVars = (theme: Partial<Theme>) => {
  const root = document.documentElement;

  Object.entries(theme).forEach(([property, value]) => {
    root.style.setProperty(`--${property}`, value);
  });
};

const savedTheme = localStorage.getItem('theme');
localStorage.removeItem('theme');

const initialTheme = savedTheme ? JSON.parse(savedTheme) : baseTheme;

const ThemeContext = createContext<{theme: Theme, dispatch: Dispatch<ThemeAction>}>({theme: baseTheme as Theme, dispatch: () => null});

export const GlobalThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, dispatch] = useReducer(themeReducer, initialTheme);

  useEffect(() => {
    const saveTheme = () => {
      localStorage.setItem('theme', JSON.stringify(theme));
    };

    window.addEventListener('beforeunload', saveTheme);

    return () => window.removeEventListener('beforeunload', saveTheme);
  }, [theme]);

  useEffect(() => {
    setCssVars(initialTheme);
  }, []);

  return (
    <ThemeContext.Provider value={{theme, dispatch}}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useGlobalTheme = () => {
  const { theme, dispatch } = useContext(ThemeContext);

  const editTheme = (payload: Partial<Theme>) => {
    setCssVars(payload);
    dispatch({type: 'EDIT_THEME', payload});
  };
  
  const resetTheme = () => {
    setCssVars(baseTheme);
    dispatch({type: 'RESET_THEME'});
  };

  return { theme, editTheme, resetTheme };
};
