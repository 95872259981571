import styled from "styled-components";
import iconBackground from '../../assets/images/redesign/icon_background.svg';

const ImgContainer = styled.img<Props>`
  background-image: url(${iconBackground});
  background-size: cover;
  height: ${({size}) => size}px;
  width: ${({size}) => size}px;
  padding: ${({size}) => size / 8}px;
  margin: -${({size}) => size / 8}px;
  box-sizing: border-box;
  flex: 0 0 auto;
`;

type Props = {
  size: number,
};

export {ImgContainer};