import { t } from 'i18next';
import React, { useContext } from 'react';
import { ListContainer, ListItem, ProductList, SplitList } from './ProductDetailsListContainers';
import { Icon, IconType } from '../icons';
import { calculateEuros } from '../../pages/checkout/functions/calculatePrices';
import { Payment, Receipt } from '../../context/app';
import { ModifiersList } from '../../pages/order-and-pay/product/ModifiersList';
import { DashedSeparator } from '../../pages/payment-successful/PaymentSuccessfulContainers';
import { AppContext } from '../../context/AppContext';

type Props = {
  receipt: Receipt | Payment
};

export const ProductDetailsListComponent = ({receipt}: Props) => {
  const {
    state: {
      checkout: {
        totalBill,
      }
    }
  } = useContext(AppContext);

  if (receipt.order_items?.length) return (
    <>
      <ListContainer>
        <h4>
          <span>
            {(receipt.order_items?.length || 0) + (receipt.free_products?.length || 0)}
          </span>
          {t('products')}
        </h4>
        <ProductList>
          {receipt.order_items?.map(product => (
            <React.Fragment key={product.id}>
              <ListItem>
                <span>{product.amount}</span>
                <h5>{product.name}</h5>
                <b>{calculateEuros(product.base_unit_price_cents) + '€'}</b>
              </ListItem>
              <ModifiersList modifiers={product.modifiers} receipt/>
            </React.Fragment>
          ))}
          {receipt.free_products?.map(product => (
            <React.Fragment key={product.id}>
              <ListItem>
                <span>{product.amount}</span>
                <h5>{product.name}</h5>
                <b className='gift'>{t('gift')}</b>
              </ListItem>
              <ModifiersList modifiers={product.modifiers} receipt/>
            </React.Fragment>
          ))}
        </ProductList>
      </ListContainer>
      <DashedSeparator/>
    </>
  );

  if (receipt.items?.length) return (
    <>
      <ListContainer>
        <h4>
          <span>
            {(receipt.items?.length || 0) + (receipt.free_products?.length || 0)}
          </span>
          {t('products')}
        </h4>
        <ProductList>
          {receipt.items?.map(product => (
            <React.Fragment key={product.key}>
              <ListItem>
                <span>{product.amount}</span>
                <h5>{product.name}</h5>
                <b>{product.price + '€'}</b>
              </ListItem>
              <RecursiveList modifiers={product.modifiers}/>
            </React.Fragment>
          ))}
          {receipt.free_products?.map(product => (
            <React.Fragment key={product.id}>
              <ListItem>
                <span>{product.amount}</span>
                <h5>{product.name}</h5>
                <b className='gift'>{t('gift')}</b>
              </ListItem>
              <ModifiersList modifiers={product.modifiers} receipt/>
            </React.Fragment>
          ))}
        </ProductList>
      </ListContainer>
      <DashedSeparator/>
    </>
  );

  if (['amount', 'evenly'].includes(receipt.split_type || '')) return (
    <>
      <SplitList>
        <h4>{t('split_' + receipt.split_type)}</h4>
        <p>
          {t('table_total')}
          <b>{calculateEuros(totalBill) + '€'}</b>
        </p>
        <p>
          {t('subtotal')}
          <b>{calculateEuros(receipt.base_amount_cents) + '€'}</b>
        </p>
      </SplitList>
      <DashedSeparator/>
    </>
  );

  return (
    <div>
      <p>{t('subtotal')}</p>
      <p>{calculateEuros(receipt.base_amount_cents) + '€'}</p>
    </div>
  );
};

type Mods = {
  modifiers: {
    name: string
    amount: number
    total_price: number
  }[]
};

const RecursiveList = ({modifiers}: Mods) => {
  if (!modifiers || !modifiers.length) return <></>;

  return (
    <ProductList>
      {modifiers.map((mod, index) => (
        <React.Fragment key={'modifier-' + index + mod.name}>
          <ListItem>
            <Icon type={IconType.TabArrow} size={10}/>
              <span>{mod.amount}</span>
              <h5>{mod.name}</h5>
              {!!mod.total_price &&
                <span>{calculateEuros(mod.total_price) + '€'}</span>
              }
          </ListItem>
        </React.Fragment>
      ))}
    </ProductList>
  );
};