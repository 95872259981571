import endpoints from "../endpoints/Endpoints.api";
import ManagerAuth from "../manager/ManagerAuth.api";

// eslint-disable-next-line
const loyaltyCents =  (token:any, type:any) => ManagerAuth.client(token, type).get(endpoints.loyalty.client)
const loyaltyPoints =  (token:any, type:any) => ManagerAuth.client(token, type).get(endpoints.loyalty.points)
const restaurantCard =  (token:any, type:any, id: number) => ManagerAuth.client(token, type).get(`${endpoints.loyalty.restaurant}?id=${id}`)
const zerosixClient =  (token:any, type:any) => ManagerAuth.client(token, type).get(endpoints.loyalty.zerosix)

export const Request = {
    loyaltyCents,
    loyaltyPoints,
    restaurantCard,
    zerosixClient
}
