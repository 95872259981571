import styled from "styled-components";

export const LogoContainer = styled.span<{size: number}>`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  height: ${({size}) => size}px;
  min-width: ${({size}) => size}px;
  overflow: hidden;
`;

export const Isotype = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
  viewBox: '0 0 25 26',
})<{size: number}>`
  height: ${({size}) => size}px;
  width: ${({size}) => size}px;
`;

export const Logotype = styled.svg.attrs({
  version: '1.1', 
  xmlns: 'http://www.w3.org/2000/svg', 
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
  viewBox: '0 0 90 27',
})<{size: number}>`
  height: ${({size}) => size}px;
  width: ${({size}) => size * 90/27}px;
  margin-left: ${({size}) => size / 8}px;
`;