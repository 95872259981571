import React from 'react';
import { t } from 'i18next';
import { NotFoundContainer, NotFoundIcon } from './NotFoundContainers';
import { IconType } from '../../elements';

export const NotFound = () => {
  return (
    <NotFoundContainer>
      <NotFoundIcon type={IconType.QrScan}/>
      <strong>{t('Oops!')}</strong>
      <p>{t('page not found')}</p>
      <p>{t('try_again_or_contact_staff')}</p>
    </NotFoundContainer>
  );
};