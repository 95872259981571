import styled from 'styled-components';

const MyTotal = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: ${({theme}) => theme.font_md};
    color: ${({theme}) => theme.text};
    background: ${({theme}) => theme.primary_10};
    border-radius: ${({theme}) => theme.radius_sm};
    padding: 12px;
    margin: 0 16px;
`;

const Total = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: ${props => props.theme.text};
    font-weight: 700;
    font-size: ${props => props.theme.font_lg};
    padding: 1.25rem 0 0 0;
    margin: 0 20px;

    span {
        font-size: ${({theme}) => theme.font_md};
        font-weight: 600;
        line-height: 1em;
    }
`;

const PriceBadge = styled.span<Badge>`
    font-weight: 700;
    line-height: 1em;
    height: fit-content;
    color: ${({theme}) => theme.text_dark};
    background-color: ${({theme, type}) => theme[badgeColor[type]]};
    border-radius: ${({theme}) => theme.radius_round};
    padding: 8px;
    margin: -8px;
    display: flex;
    align-items: center;
    gap: 4px;
`;

const badgeColor = {
    total: 'yumminn_40',
    remaining: 'danger_10',
    payment: 'gray_10',
}

type Badge = {
  type: 'total' | 'remaining' | 'payment'
}

export { MyTotal, Total, PriceBadge };

