import styled from "styled-components";

const BackgroundContainer = styled.div<BackgroundProps>`
  display: flex;
  flex-direction: column;
  flex: ${({isOrderAndPay}) => isOrderAndPay ? '' : '1'};
  height: ${({isOrderAndPay}) => isOrderAndPay ? '30vh' : ''};
  position: relative;
  background-image:
    linear-gradient(${({theme}) => theme.photo_overlay}),
    url(${({image}) => image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: ${({theme, isOrderAndPay}) => isOrderAndPay ? `44px 0 ${theme.radius_xl}` : `0 0 calc(${theme.radius_xl} * 2) 0`};
  margin-bottom: ${({isOrderAndPay, theme}) => isOrderAndPay ? `-${theme.radius_lg}` : '0'};
`;

const HeaderContainer = styled.div<HeaderProps>`
  position: ${({isOrderAndPay}) => isOrderAndPay ? 'fixed' : 'absolute'};
  top: ${({isOrderAndPay}) => isOrderAndPay ? '42px' : '0'};
  left: 0;
  width: 100%;
  min-height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  color: #FFF;
  padding: 16px;
  box-sizing: border-box;
  z-index: 9;
  
  span {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 600;
    line-height: 1em;
    text-align: center;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 2em;
    overflow: hidden;
    transition: 0.2s ease-in-out;
  }
`;

const TableContainer = styled.div`
  position: absolute;
  top: 44px;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${({theme, color}) => color || theme.text_light};
  font-weight: 600;
  line-height: 1em;
  padding: 16px;
  box-sizing: border-box;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  
  a {
    display: flex;
    height: fit-content;
  }
`;

const LogoContainer = styled.div<LogoContainerProps>`
  height: 100%;
  padding: ${({isOrderAndPay}) => !isOrderAndPay ? '44px 0 24px' : '0'};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const Logo = styled.div<LogoProps>`
  height: ${({aspectRatio, logoWidth}) => logoWidth ? '100%' : aspectRatio >= 1 ? '35%' : '55%'};
  width: ${({aspectRatio, logoWidth}) => logoWidth ? `${logoWidth}%` : aspectRatio >= 1 ? '80%' : '55%'};
  display: flex;
  justify-content: center;
  align-items: center;
  
  img {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: ${({aspectRatio, logoWidth}) => logoWidth || aspectRatio > 1 ? '100%' : '50%'};
    object-fit: contain;
    border-radius: ${({theme}) => theme.radius_md};
    margin: -16px 0;
  }
`;

const TableBadge = styled.div`
  font-weight: 500;
  font-size: ${({theme}) => theme.font_xs};
  line-height: 14px;
  white-space: nowrap;
  height: fit-content;
  color: ${({theme}) => theme.background};
  border: 2px solid ${({theme}) => theme.background};
  padding: 8px 16px;
  border-radius: ${({theme}) => theme.radius_round};
  filter: ${({theme}) => theme.shadow_drop_sm};
`;

const IgIcon = styled.svg.attrs({
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 21.73 21.73",
})`
  fill: ${({theme}) => theme.background};
  width: 24px;
  height: 24px;
  filter: ${({theme}) => theme.shadow_drop_sm};
`;

export const CornerLogo = styled.img`
  max-width: 30%;
  max-height: 70px;
  object-fit: contain;
  margin-left: auto;
`;

type BackgroundProps = {
  image: string | any;
  restaurantId: number;
  isOrderAndPay: boolean;
};

type HeaderProps = {
  isOrderAndPay?: boolean;
};

type LogoContainerProps = {
  isOrderAndPay?: boolean;
};

type LogoProps = {
  aspectRatio: number;
  logoWidth: number | null;
};

export {
  BackgroundContainer,
  HeaderContainer,
  TableContainer,
  IconsContainer,
  LogoContainer,
  Logo,
  TableBadge,
  IgIcon
};