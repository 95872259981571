import React from 'react'
import { Icon, IconType } from '../icons'
import { SpinningContainer } from './SpinningCoinContainers'

type Props = {
  type: 'cashback' | 'point'
  size?: number
}

const icon = {
  cashback: IconType.CashbackColor,
  point: IconType.PointColor,
}

export const SpinningCoinComponent = ({type, size}: Props) => {
  return (
    <SpinningContainer type={type} size={size || 24}>
      <Icon type={icon[type]} size={size}/>
    </SpinningContainer>
  )
}