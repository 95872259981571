import React, { MouseEventHandler } from 'react';
import { Icon, IconType } from '../icons';
import { CounterButton } from './CounterContainers';

type BaseProps = Props & {
  children: React.ReactNode;
}

type Props = {
  disabled?: boolean
  onClick: MouseEventHandler
  style?: any
};

const CounterButtonComponent = ({disabled, onClick, style={}, children}: BaseProps) => {
  const handleClick = (event: React.MouseEvent) => {
    if (disabled) return;

    event.stopPropagation();
    onClick(event);
  };

  return (
    <CounterButton style={style} disabled={disabled} onClick={handleClick}>
      {children}
    </CounterButton>
  );
};

export { CounterButtonComponent as CounterButton};

export const PlusButtonComponent = ({disabled, onClick, style={}}: Props) => {
  return (
    <CounterButtonComponent disabled={disabled} onClick={onClick} style={style}>
      <Icon type={IconType.Plus}/>
    </CounterButtonComponent>
  );
};

export const MinusButtonComponent = ({disabled, onClick, style={}}: Props) => {
  return (
    <CounterButtonComponent disabled={disabled} onClick={onClick} style={style}>
      <Icon type={IconType.Minus}/>
    </CounterButtonComponent>
  );
};
