import React from 'react';

import { IRenderWhen } from './interfaces';

export const RenderWhenContainer = ({ children }: IRenderWhen) => (
    //detectMob() ? (
        <>
            { children }
        </>
    /*) : (
        <>
            <h4>No correct device</h4>
        </>
    )*/
)
