import React from 'react'
import { Content, Ribbon, RibbonWrapper } from './CornerRibbonContainers'

type Props = {
  color?: string
  children: React.ReactNode
  fontSize?: string
  isShown: boolean
  position: 'right' | 'left'
  text: string
}

export const CornerRibbonComponent = ({color, children, fontSize, isShown, position, text}: Props) => {
  return (
    <RibbonWrapper>
      <Content>{children}</Content>
      <Ribbon $position={position} $color={color} $isShown={isShown} $fontSize={fontSize}>
        <span>{text}</span>
      </Ribbon>
    </RibbonWrapper>
  )
}