import styled from "styled-components";

const SwitchContainer = styled.label<Switch>`
    position: relative;
    display: inline-flex;
    width: 50px;
    height: 30px;
    background-color: ${({checked}) => checked ? 'currentColor' : 'transparent'};
    border: 2px solid currentColor;
    border-radius: ${({theme}) => theme.radius_round};
    padding: 3px;
    cursor: pointer;
    box-sizing: border-box;
    mix-blend-mode: ${({switchType}) => (switchType === 'credit_true' ||  switchType === 'credit_false' || switchType === 'loyalty_true' || switchType === 'loyalty_false') ? '' : 'multiply'};
    opacity: ${({disabled}) => disabled ? 0.5 : 1};
    transition: background-color 0.2s ease-in-out;
    flex-grow: 0;
    flex-shrink: 0;
`;

const Checkbox = styled.input.attrs({
    type: 'checkbox'
})`
    position: absolute;
    width: 0;
    opacity: 0;
    &:checked + span {
        margin-left: 20px;
        background-color: #FFF;
    }
`;

const Slider = styled.span`
    width: 20px;
    height: 20px;
    background-color: currentColor;
    border-radius: 50%;
    transition-property: margin, background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    z-index: 1;
`;

type Switch = {
    checked: boolean
    disabled?: boolean
    switchType?: string
}

export {SwitchContainer, Checkbox, Slider}