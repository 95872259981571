import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import moment from "moment";

export const useRestaurantData = () => {
  const {
    state: {
      restaurantId: restaurantIdFromContext,
      tableNumber: tableNumberFromContext,
      restaurant: {
        id,
        uuid,
        restaurantGroupUuid: restaurantGroupUuidFromContext,
      },
    },
  } = useContext(AppContext);

  const date = moment().format("YYYY-MM-DD");
  const lastScan = JSON.parse(localStorage.getItem('lastScan') || '{}');
  
  const restaurantId = restaurantIdFromContext || id ||  Number(sessionStorage.getItem("restaurantId")) || (lastScan?.date === date ? Number(lastScan.restaurantId) : 0);
  const restaurantUuid = uuid || sessionStorage.getItem("restaurantUuid") || '';
  const tableNumber = tableNumberFromContext || sessionStorage.getItem("tableNumber") || (lastScan?.date === date ? lastScan.tableNumber : '');
  const restaurantGroupUuid = restaurantGroupUuidFromContext || sessionStorage.getItem('restaurantGroupUuid');

  return { restaurantId, restaurantUuid, tableNumber, restaurantGroupUuid };
};