import { animate } from 'framer-motion/dist/framer-motion';
import React from 'react';
import { Icon, IconType } from '../icons';
import { Star, StarsContainer } from './StarExplosionContainers';

type Props = {
  animated: boolean
  children: React.ReactNode
}

export const StarExplosion = ({animated, children}: Props) => {
  const stars = new Array(5).fill(null).map(() => [Math.random(), Math.random()]);

  return (
    <StarsContainer>
      {stars.map(([position, size], index) =>
        <Star
          key={index}
          index={index}
          position={position}
          size={size}
          className={animated ? 'animated' : ''}
        >
          <Icon type={IconType.Star} size={20}/>
        </Star>
      )}
      {children}
    </StarsContainer>
  );
};