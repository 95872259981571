import { t } from 'i18next'
import React from 'react'
import { useTheme } from 'styled-components'
import { BizumNotification } from '../../pages/checkout/components/BizumNotification'
import { RewardNotification } from '../../pages/checkout/components/RewardNotification'
import { ProgressButton } from '../progress-button'
import { Toggletip } from '../toggletip'
import { DotsContainer, RoadmapContainer, RoadmapLabel, RoadmapWrapper } from './RoadmapContainers'
import { McNotification } from '../../pages/checkout/components/McNotification'

type Props = {
  elements: RoadmapStep[]
  step: number
  progress: number
}

export type RoadmapStep = {
  name: string
  icon: React.ReactNode
  total: number
  onClick: () => void
  toggletip?: {
    content: React.ReactNode
    isVisible: boolean
    onClose: () => void
    position?: 'top' | 'bottom' | 'right' | 'left'
    alignment?: 'start' | 'center' | 'end'
    overlay?: boolean
  }
}

export const RoadmapComponent = ({elements, step, progress}: Props) => {
  const theme: any = useTheme();

  return (
    <RoadmapContainer>
      {elements.map((element, index) => {
        const stepProgress = step === index + 1 ? progress : step > index + 1 ? element.total : 0;
        const areDotsCompleted = step > index + 1
        const areDotsActive = step === (index + 1 && progress >= element.total) || (step === index + 2 && progress === 0);
        const zIndex = element.toggletip?.isVisible && element.toggletip?.overlay ? 99999 : 0;
        
        if (element.toggletip?.content) return (
          <React.Fragment key={'roadmap-' + element.name}>
            <RoadmapWrapper style={{zIndex}}>
              <Toggletip {...element.toggletip}>
                <ProgressButton
                  progressBg
                  bgColor={theme.background}
                  progressOverlay={element.name !== 'reward'}
                  title={element.icon}
                  total={element.total}
                  current={element.toggletip.isVisible ? element.total : stepProgress}
                  size={'15vw'}
                  onClick={element.onClick ? element.onClick : () => undefined}
                />
                {element.name === 'payment' &&
                  <BizumNotification/>
                }
                {element.name === 'reward' &&
                  <RewardNotification/>
                }
              </Toggletip>
              <RoadmapLabel>
                {t(element.name)}
              </RoadmapLabel>
            </RoadmapWrapper>
            {index < elements.length - 1 &&
              <DotsContainer
                isCompleted={areDotsCompleted}
                isActive={areDotsActive}
              >
                <hr/>
                <hr/>
                <hr/>
              </DotsContainer>
            }
          </React.Fragment>
        )

        return (
          <React.Fragment key={'roadmap-' + element.name}>
            <RoadmapWrapper style={{zIndex}}>
              <ProgressButton
                progressBg
                bgColor={theme.background}
                progressOverlay
                title={element.icon}
                total={element.total}
                current={element?.toggletip?.isVisible ? element.total : stepProgress}
                size={'15vw'}
                onClick={element.onClick ? element.onClick : () => undefined}
              />
              {element.name === 'payment' &&
                <McNotification/>
              }
              {element.name === 'reward' &&
                <RewardNotification/>
              }
              <RoadmapLabel>
                {t(element.name)}
              </RoadmapLabel>
            </RoadmapWrapper>
            {index < elements.length - 1 &&
              <DotsContainer
                key={'dots-' + index}
                isCompleted={areDotsCompleted}
                isActive={areDotsActive}
              >
                <hr/>
                <hr/>
                <hr/>
              </DotsContainer>
            }
          </React.Fragment>
        )
      })}
    </RoadmapContainer>
  )
}