import styled from "styled-components";
import { BottomSheet } from "../popup";

export const NavigateAllSheet = styled(BottomSheet)`
  border-radius: 0;
  height: 100%;
  
  &.open, &.close {
    animation-duration: 0.4s;
  }

  & > header {
    color: ${({theme}) => theme.text_light};
    background-color: ${({theme}) => theme.header_bg};
    padding: 16px 24px;
    
    h4 {
      font-size: ${({theme}) => theme.font_lg};
    }

    button {
      color: currentColor;
      background: transparent;
      margin: -16px;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    overflow: auto;

    h3 {
      font-size: ${({theme}) => theme.font_lg};
      font-weight: 600;
      line-height: 1.3;
      margin: 0 0 4px;
      text-align: center;
    }

    ul {
      --button_text_selected: ${({theme}) => theme.text};
      --button_bg_selected: ${({theme}) => theme.primary_a40};
      --button_border_selected: ${({theme}) => `${theme.border_xs} ${theme.text}`};

      &:first-of-type {
        --button_text_selected: ${({theme}) => theme.background};
        --button_bg_selected: ${({theme}) => theme.text};
        --button_border_selected: ${({theme}) => `${theme.border_xs} ${theme.background}`};
      }

      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 0;
      margin: 0;
    }

    hr {
      width: 100%;
      border: none;
      border-top: 1px solid ${({theme}) => theme.text_a20};
    }

    h3 + hr {
      display: none;
    }

    button {
      font-family: ${({theme}) => theme.typography};
      font-size: ${({theme}) => theme.font_md};
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 8px;
      color: ${({theme}) => theme.text};
      background-color: ${({theme}) => theme.background_rised};
      border: ${({theme}) => `${theme.border_xs} ${theme.text_a20}`};
      border-radius: ${({theme}) => theme.radius_round};
      width: 100%;
      height: 44px;
      padding: 0 16px;
      box-sizing: border-box;
      box-shadow: ${({theme}) => theme.shadow_xs};

      span::first-letter {
        text-transform: capitalize;
      }

      &.selected {
        color: var(--button_text_selected);
        background-color: var(--button_bg_selected);
        border: var(--button_border_selected);
      }

      span + svg {
        margin-left: auto;
      }
    }
  }
`;
