import React from 'react';
import { Icon, IconType } from '../icons';
import { Checkbox, Label } from './RoundCheckboxContainers';

export const RoundCheckboxComponent = ({isChecked, disabled = false, color, size, handleChange}: props) => {
  return (
    <Label color={color} size={size} className={isChecked ? 'checked' : ''}>
      <Checkbox checked={isChecked} onChange={handleChange} disabled={disabled} readOnly={!handleChange}/>
      <Icon type={IconType.Check}/>
    </Label>
  );
};

type props = {
  isChecked: boolean
  disabled?: boolean
  handleChange?: () => void
  color?: string
  size?: number
};