import React from 'react';
import styled from 'styled-components';

const MyButton = styled.button<MyButtonProps>`
--button-bg-color: ${({enabled, variant, theme}) => enabled && !variant ? theme.primary : variant === "white" ? theme.background : variant === "bordered" ? theme.background : theme.gray_50};
  background-color:  var(--button-bg-color);
  color: ${({enabled, variant, theme}) => variant === theme.background && enabled ? theme.text : variant === "white" && !enabled ? theme.disabled_variant : variant === "bordered" ? enabled ? theme.primary : theme.gray_50 : theme.background};
  border: 2.5px solid ${({enabled, variant, theme}) => variant ? 'currentColor' : enabled ? theme.primary : theme.gray_50};
  box-shadow: ${({enabled, variant, theme}) => !variant && enabled ? theme.shadow_sm : ''};
  border-radius: 9999px;
  font-size: ${({theme}) => theme.font_md};
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  padding: 0.75rem 1.2rem;
  font-family: ${({theme}) => theme.typography};
  line-height: 14px;
  position: relative;
`;

const Arrow = styled.span`
  position: absolute;
  top: 100%;
  left: 50%;
  translate: -50% -50%;
  width: 20px;
  height: 20px;
  rotate: 45deg;
  transform: skew(10deg, 10deg);
  background-color: var(--button-bg-color);
  border-radius: 100% 0 4px 0;
`;

type ButtonProps = {
  title?: string;
  onClick: () => void;
  enabled: boolean;
  children?: JSX.Element|JSX.Element[];
  variant?: 'bordered' | 'white';
  hasArrow?: boolean
}

type MyButtonProps = {
  onClick: () => void;
  enabled: boolean;
  variant?: 'bordered' | 'white';
}

const FinalButtonPay = ({
  title,
  onClick,
  children,
  enabled,
  variant,
  hasArrow,
}: ButtonProps): JSX.Element => {
  return (
    <MyButton
      onClick={onClick}
      enabled={enabled}
      variant={variant}
    >
      {children}
      {title}
      {hasArrow && <Arrow/>}
    </MyButton>
  )
};

export default FinalButtonPay;