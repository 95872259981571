import React, { ComponentType, lazy } from "react";
import { postError } from "../shared/utils/postError";
import { UnexpectedError } from "../pages/not-found/unexpected-error";

// Retry loading a chunk to avoid chunk load error for outdated code

export const lazyImportHandler = (componentImport: () => Promise<{default: ComponentType<any>}>, name: string) => {
  return lazy(async () => {
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(`hasRefreshed_${name}`) || 'false');
  
    try {
      const component = await componentImport();
      window.sessionStorage.removeItem(`hasRefreshed_${name}`);
      return component;
    } catch (error: any) {
      if (!hasRefreshed) {
        window.sessionStorage.setItem(`hasRefreshed_${name}`, 'true');
        window.location.reload();
      }
      postError(error)
      return { default: (() => <UnexpectedError/>) as ComponentType<any> };
    }
  });
};