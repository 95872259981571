import React, { useState, Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleSwitch } from '../../elements';
import { calculateEuros } from '../checkout/functions/calculatePrices';
import { LoyaltyBannerContainer, LoyaltyBannerContainerNoRedeem } from './LoyaltyContainers';
import { Icon, IconType } from '../../elements/icons';
import { getProgramsFromStorage } from '../checkout/functions/getProgramsFromStorage';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { PopupType } from '../../context/popups.enum';
import { AuthContext } from '../../context/auth/AuthContext';
import { AppContext } from '../../context/AppContext';
import { eventTrack } from '../../../useGaTracker';
import { Trans } from 'react-i18next';
import { Popover } from '../../elements/popover';

export const LoyaltyBanner = ({setDiscountApplied, amountMinusDiscountsAndCredit} : props) => {
    const {
        state: {
            checkout: {
                myTotal,
                generalDiscountCents,
                productDiscountCents,
                codeCouponCents,
                availableLoyaltyCents,
                loyaltyDiscountCents,
                loyaltyUnavailableCents,
                creditCents,
                tip: {
                    amount
                }
            }
        },
    } = useContext(AppContext);
    const {t} = useTranslation();
    const {loyaltyProgram} = getProgramsFromStorage()
    const loyalty_min_redeem_amount_cents = parseInt(sessionStorage.getItem('loyalty_min_redeem_amount_cents') || '0');
    const loyalty_hours_until_cashback_granted = parseInt(sessionStorage.getItem('loyaltyHoursUntilCashbackGranted') || '0');
    const calculatedLoyalty = availableLoyaltyCents ? availableLoyaltyCents : loyaltyUnavailableCents
    const euros = calculateEuros(calculatedLoyalty);
    const subtotal = myTotal - amount;
    const amountMinusDiscounts = Math.max(subtotal - generalDiscountCents - productDiscountCents - codeCouponCents - creditCents, 0);
    const loyaltyAfterDiscounts = Math.min(loyaltyDiscountCents, amountMinusDiscounts)
    const loyaltyAfterDiscountsEuros = calculateEuros(loyaltyAfterDiscounts)
    const minRedeemEuros = calculateEuros(loyalty_min_redeem_amount_cents);
    const {isLogged} = useContext(AuthContext);
    const {goToPopup} = usePopupNavigation();
    const handleClick = () => !isLogged && goToPopup(PopupType.Register);
    const disabled = (amountMinusDiscountsAndCredit === 0 && loyaltyDiscountCents === 0) || (loyaltyAfterDiscounts === 0 && loyaltyDiscountCents > 0)
    const [openedPopover, setOpenPopover] = useState(false)

    React.useEffect(() => {
        const hasLoyaltyDiscount = !!loyaltyDiscountCents
        if(hasLoyaltyDiscount){
            eventTrack("03_details_button_discountOn", "03_details_button_discountOn", "03_details_button_discountOn")
        } else {
            eventTrack("03_details_button_discountOff", "03_details_button_discountOff", "03_details_button_discountOff")
        }
    }, [loyaltyDiscountCents])

    const UnavailableLoyaltyCentsMessage = ({
      hasCashbackAvailable = false,
    }: {
      hasCashbackAvailable: boolean;
    }) => {
      const hours = loyalty_hours_until_cashback_granted;
      return (
        <p style={{ fontSize: 12, padding: "0 10px 0 10px", display: "block"}}>
          <Trans i18nKey={"you_can_use_cashback_after_{{hours}}"}>
            The use of cashback is enabled <b style={{ display: "inline" }}> {{hours}} hours after </b> having obtained it.
          </Trans>
          {hasCashbackAvailable ? (
            <span>
              <b>{t("you_can_use_cashback_from_prev_visit")}</b>
            </span>
          ) : (
            <span>{t("it_will_be_available_on_your_next_visit")}😉</span>
          )}
        </p>
      );
    };

    const DisabledDiscount = () => {
        return (
          <div style={{ fontSize: 12, padding: "0 10px 0 10px", display: "inline-block", whiteSpace: "nowrap"}}>
            <Trans i18nKey={"disabled_discount_msg"}>
              <b>It is not possible to apply this discount</b> because your bill is <strong>€0.00</strong>
            </Trans>
          </div>
        );
    };

    if (!loyaltyProgram) return <></>

    if (!isLogged) return (
        <LoyaltyBannerContainer onClick={handleClick}>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <Icon type={IconType.CashbackColor}/>
                <p>
                    {t('Earn one euro')}
                </p>
            </div>
            <Icon type={IconType.Arrowforward}/>
        </LoyaltyBannerContainer>
    )

    if (availableLoyaltyCents && availableLoyaltyCents >= loyalty_min_redeem_amount_cents && !disabled){ 
        return (
            <>
                <LoyaltyBannerContainer switchType={`loyalty_${!!loyaltyDiscountCents}`} onClick={() => setDiscountApplied(wasApplied => !wasApplied)}>
                    <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                        <Icon type={IconType.CashbackColor}/>
                        <p>
                            {loyaltyDiscountCents === 0 ? t("you've got {{euros}} of discount", {euros}) : t('{{loyalty}} applied', {loyalty: loyaltyAfterDiscountsEuros})}
                        </p>
                    </div>
                    <ToggleSwitch switchType={`loyalty_${!!loyaltyDiscountCents}`} isChecked={!!loyaltyDiscountCents}/>
                </LoyaltyBannerContainer>
                {loyaltyUnavailableCents ? <UnavailableLoyaltyCentsMessage hasCashbackAvailable={true}/> : <></>}
            </>
        )
    }
    
    if((loyaltyUnavailableCents && loyaltyUnavailableCents >= loyalty_min_redeem_amount_cents) || (availableLoyaltyCents && availableLoyaltyCents >= loyalty_min_redeem_amount_cents && disabled)) return (
        <div>
            <LoyaltyBannerContainer disabled={true} onClick={() => disabled ? setOpenPopover(true) : null}>
                <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <Icon type={IconType.CashbackColor}/>
                    <p>
                        {t("you've got {{euros}} of discount", {euros})}
                    </p>
                </div>
                <ToggleSwitch isChecked={!!loyaltyDiscountCents} disabled={true}/>
            </LoyaltyBannerContainer>
           {loyaltyUnavailableCents ? <UnavailableLoyaltyCentsMessage hasCashbackAvailable={false}/> : <></>}
           <Popover setOpened={setOpenPopover} isOpened={openedPopover}>
            <DisabledDiscount/>
           </Popover>
        </div>
    )

    if (availableLoyaltyCents && availableLoyaltyCents < loyalty_min_redeem_amount_cents) return (
        <LoyaltyBannerContainerNoRedeem>
            <b>
                {t("you've got {{euros}} in your loyalty card", {euros})}
            </b>
            <p>
                {t("redeem them when you get to", {loyalty_min_redeem_amount_cents: minRedeemEuros})}
            </p>
        </LoyaltyBannerContainerNoRedeem>
    )

    return <></>
};

type props = {
    setDiscountApplied: Dispatch<SetStateAction<boolean>>
    amountMinusDiscountsAndCredit: number
}
