import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateAllSheet } from './NavigateAllContainers';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { PopupContext } from '../../context/PopupContext';
import { Icon, IconType } from '../icons';
import { checkLastLevel, getTreeId } from './functions';
import { HeaderTree, Section } from './virtual-sections.component';
import { PopupType } from '../../context/popups.enum';

type NavigateAllProps = {
  name: string
  tree: HeaderTree
  sections: Section[]
  scrollTo: (index: number) => void
};

type RecursiveNavProps = {
  name: string
  sections: Section[]
  tree: HeaderTree | number
  level?: number
  scrollTo: (index: number) => void
};

const RecursiveNav = ({name, tree, level = 1, sections, scrollTo}: RecursiveNavProps) => {
  const categories = Object.entries(tree).sort((a, b) => a[1].order - b[1].order);

  const [selectedIndex, setSelectedIndex] = useState<number|null>(categories.length > 1 ? null : 0);

  const nextLevel = selectedIndex !== null && categories[selectedIndex][1].value;

  const onChange = (index: number, isLastLevel: boolean) => {
    setSelectedIndex(prev => prev === index ? null : index);

    if (isLastLevel) {
      const sectionIndex = sections.findIndex(section => section.id === getTreeId(tree, index))
      scrollTo(sectionIndex);
    }
  };

  return (
    <>
      {selectedIndex === null &&
        <hr/>
      }
      {categories.length > 1 &&
        <ul>
          {categories.map(([label, tree], index) => {
            if (selectedIndex !== null && index !== selectedIndex) {
              return <React.Fragment key={`${name}-l${level}-${index}`}/>
            };
            
            const isLastLevel = checkLastLevel(tree);
            
            return (
              <li key={`${name}-l${level}-${index}`}>
                <button
                  className={index === selectedIndex ? 'selected' : ''}
                  onClick={() => onChange(index, isLastLevel)}
                >
                  {!isLastLevel && index === selectedIndex &&
                    <Icon type={IconType.Arrowback} size={14}/>
                  }
                  <span>{label.split('parentId:')[0]}</span>
                  {!isLastLevel && index !== selectedIndex &&
                    <Icon type={IconType.Arrowforward} size={14}/>
                  }
                </button>
              </li>
            );
          })}
        </ul>
      }
      {selectedIndex !== null && typeof nextLevel !== 'number' &&
        <RecursiveNav
          name={name}
          tree={nextLevel}
          level={level + 1}
          sections={sections}
          scrollTo={scrollTo}
        />
      }
    </>
  );
};

export const NavigateAllPopup = ({ name, tree, sections, scrollTo } : NavigateAllProps) => {
  const { popup } = useContext(PopupContext);
  const { t } = useTranslation();
  const { closePopup } = usePopupNavigation();
  
  const selectAndClosePopup = (index: number) => {
    scrollTo(index);
    closePopup();
  };

  return (
    <NavigateAllSheet header='entire menu' isOpened={popup.current === PopupType.NavigateAll}>
      <div>
        <h3>{t('what would you like to order')}</h3>
        <RecursiveNav
          name={name}
          tree={tree}
          sections={sections}
          scrollTo={selectAndClosePopup}
        />
      </div>
    </NavigateAllSheet>
  );
}