import { Tags, TagLabels } from "../../context/app";

export type TagObj = {value: Tags, label: TagLabels, color: string, bgColor: string};

export const TAG_LIST: TagObj[] = [
  {value: 1, label: "Vegetarian", color: "#2E9F62", bgColor: "#EFF3C3"},
  {value: 2, label: "Vegan", color: "#59C127", bgColor: "#D7EEC8"},
  {value: 3, label: "GlutenFree", color: "#439CDE", bgColor: "#D9EFFF"},
  {value: 4, label: "Kosher", color: "#000000", bgColor: "#F3D3B5"},
  {value: 5, label: "Halal", color: "#000000", bgColor: "#DBF9C8"},
  {value: 6, label: "Spicy1", color: "#CE1536", bgColor: "#FECFD8"},
  {value: 7, label: "Spicy2", color: "#CE1536", bgColor: "#FECFD8"},
  {value: 8, label: "Spicy3", color: "#CE1536", bgColor: "#FECFD8"},
];