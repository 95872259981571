import styled from "styled-components";

const PopoverOuterContainer = styled.div`
  position: relative;
  background-color: transparent;
  border: none;
  padding: 0;
  max-height: 0%;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
`;

const PopoverWrapper = styled.div`
  position: absolute;
  width: fit-content;
  z-index: 999;
`;

const PopoverChildren = styled.div`
  z-index: 999;
  display: flex;
  background-color:  ${({theme}) => theme.dark_overlay};
  box-shadow: ${({theme}) => theme.shadow_overlay};
  transition: 0.2s ease-in-out;

  & > * {
    transition: scale 0.2s ease-in-out;
    scale: 1;
  }
`;

const PopoverContainer = styled.div`
  position: absolute;
  filter: ${({theme}) => theme.shadow_drop_md};
  z-index: 999;
  width: 80svw;
  scale: 1;
  opacity: 1;
  transition-property: scale, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  &::before {
    position: absolute;
    content: '';
    width: 24px;
    height: 24px;
    rotate: 45deg;
    background-color: ${({theme}) => theme.card_bg};
    bottom: 55px;
    right: 5px;
  }
`;

const PopoverContent = styled.div`
  color: ${({theme}) => theme.text};
  background: ${({theme}) => theme.card_bg};
  border-radius: ${({theme}) => theme.radius_md};
  padding: 16px;
  position: absolute;
  transition: translate 0.1s ease-in-out;
  bottom: 55px;
  right: 0;

`;


export {
  PopoverOuterContainer,
  PopoverWrapper,
  PopoverChildren,
  PopoverContainer,
  PopoverContent,
}