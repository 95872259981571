import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../../context/AppContext'
import { PopupType } from '../../../context/popups.enum'
import { Icon, IconType } from '../../../elements'
import { PopupContext } from '../../../context/PopupContext'


export const BizumNotification = () => {
  const {
    state: {
      restaurant: {
        bizumPromotionCents,
      },
    },
  } = useContext(AppContext);
  const { popup } = useContext(PopupContext);

  const discount = Math.floor(bizumPromotionCents / 100);

  return (
    <BizumPill isShown={!!discount && popup.current === PopupType.None}>
      <Icon type={IconType.BizumIsotype} size={12}/>
      <span >-{discount}€</span>
    </BizumPill>
  )
}

const BizumPill = styled.div<{isShown: boolean}>`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #05C0C7;
  border-radius: ${({theme}) => theme.radius_round};
  color: ${({theme}) => theme.background};
  font-size: ${({theme}) => theme.font_xs};
  font-weight: 700;
  line-height: 1em;
  padding: 6px;
  text-transform: uppercase;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  translate: ${({isShown}) => isShown ? '60% -25%' : '60% 0%'};
  scale: ${({isShown}) => isShown ? 1 : 0.5};
  z-index: 4;
  opacity: ${({isShown}) => isShown ? 1 : 0};
  transition: 0.2s ease-in-out;
`;