import styled from "styled-components";

const RibbonWrapper = styled.div`
  position: relative;
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
`;

const Ribbon = styled.div<RibbonType>`
  position: absolute;
  top: 0px;
  right: ${({$position}) => $position === 'right' ? '0' : ''};
  left: ${({$position}) => $position === 'left' ? '0' : ''};
  z-index: 2;
  overflow: hidden;
  width: 60px;
  height: 60px;
  display: ${({$isShown}) => $isShown ? '' : 'none'};
  
  span {
    position: absolute;
    top: 23%;
    right: ${({$position}) => $position === 'right' ? '-37%' : ''};
    left: ${({$position}) => $position === 'left' ? '-37%' : ''};
    width: 140%;
    font-size: ${({theme, $fontSize}) => $fontSize ? $fontSize : theme.font_xxs};
    font-weight: 600;
    line-height: 1em;
    text-align: center;
    color: #FFF;
    background-color: ${({$color, theme}) => $color || theme.primary};
    padding: 4px;
    transform: ${({$position}) => $position === 'right' ? 'rotate(45deg)' : 'rotate(-45deg)'};
  }
`;

type RibbonType = {
  $position: 'right' | 'left'
  $color?: string
  $isShown?: boolean
  $fontSize?: string
}

export {RibbonWrapper, Content, Ribbon};