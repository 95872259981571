import { t } from 'i18next';
import React from 'react';
import { Icon, IconType } from '../icons';
import { ClipboardInputContainer } from './InputContainers';
import { toast } from 'react-toastify';

type InputProps = {
  value?: string
  title?: string | React.ReactNode
  label?: string | React.ReactNode
  disabled?: boolean
};

export const ClipboardInput = ({
  value,
  title,
  label,
  disabled = false,
}: InputProps) => {
  const copyToClipboard = () => {
    if (!value) return;

    navigator.clipboard.writeText(value);
    toast.success(t('text_copied'));
  };

  return (
    <ClipboardInputContainer>
      {title &&
        <h5>
          {typeof title === 'string' ?  t(title) : title}
        </h5>
      }
      <label>
        {label &&
          <h6>
            <span>
              {typeof label === 'string' ?  t(label) : label}
            </span>
          </h6>
        }
        <span>
          {value}
        </span>
        <hr/>
        <button disabled={disabled} onClick={copyToClipboard}>
          <Icon type={IconType.Copy}/>
          <span>{t('copy')}</span>
        </button>
      </label>
    </ClipboardInputContainer>
  );
};