// Each property corresponds to a GTM variable, built-in or user-defined

const getRestaurantData = () => ({
    restaurant_id: Number(sessionStorage.getItem('restaurantId')),
    restaurant_name: sessionStorage.getItem('restaurantName'),
    table: sessionStorage.getItem('tableNumber'),
    functionality: sessionStorage.getItem('functionality'),
});

// This mocks old events triggered by the previous library 'react-GA4'
export const eventTrack = (category: string, action?: string, label?: string) => {
    window.dataLayer.push({
        event: 'custom_event',
        category,
        action: action || category,
        label: label || category,
        ...getRestaurantData(),
    });
};

export const customEvent = ({ event, data }: { event: string, data: Record<string, any> }) => {
    window.dataLayer.push({
        event,
        ...getRestaurantData(),
        ...data,
    });
};