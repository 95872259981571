import styled, { keyframes } from "styled-components";
import { getTextColor } from "../../shared/utils/colors";

const fadeInBg = keyframes`
  0% {
    background-color: var(--background_sunken);
  }
  30% {
    background-color: var(--secondary);
  }
  80% {
    opacity: 1;
    background-color: var(--secondary);
  }
  100% {
    opacity: 0;
    background-color: var(--secondary);
  }
`;

const fadeInImg = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const SplashScreenContainer = styled.div<ContainerProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* fallback */
  height: var(--dvh); /* fallback */
  height: 100dvh;
  width: 100vw;
  background-color: ${({theme}) => theme.background_sunken};
  box-sizing: border-box;
  overflow: hidden;
  z-index: 999999;
  animation: ${fadeInBg} 2.2s ease-in-out forwards;
  
  img {
    height: ${({logoWidth, ratio}) => logoWidth ? 'auto' :  ratio >= 1 ? '30%' : '50%'};
    width: ${({logoWidth, ratio}) => logoWidth ? logoWidth + '%' : ratio >= 1 ? '50%' : '30%'};
    object-fit: contain;
  }
  
  .logo {
    opacity: 0;
    animation: ${fadeInImg} 0.6s 0.35s ease-in-out forwards;
  }

  .yumminn {
    max-width: ${innerWidth/5}px;
  }
  
  footer {
    position: absolute;
    bottom: 32px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${({theme}) => getTextColor(theme.secondary)};
    
    span {
      font-size: ${({theme}) => theme.font_xxs};
    }
  }
`;

type ContainerProps = {
  ratio: number
  logoWidth: number | null
}

export {SplashScreenContainer}