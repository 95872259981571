import React from 'react';
import { Modifier } from '../../../context/app';
import { Icon, IconType } from '../../../elements';
import { calculateEuros } from '../../checkout/functions/calculatePrices';
import { PaymentBadge } from '../../payment-successful/PaymentSuccessfulContainers';
import { listByFather } from '../logic/modifiersFunctions';
import { ModList } from '../styled-components/ProductCardContainers';

type Props = {
  modifiers?: Modifier[]
  receipt?: boolean
};

export const ModifiersList = ({modifiers, receipt = false}: Props) => {
  if (!modifiers || !modifiers.length) return <></>;

  const modsByFather = listByFather(modifiers);
  
  return (
    <ModList receipt={receipt}>
      {modsByFather.map(([father, modifiers]) => (
        <React.Fragment key={'modifier-' + father}>
          {father && !receipt &&
            <h5>{father}</h5>
          }
          {modifiers.map(mod => (
            <React.Fragment key={'modifier-' + father}>
              <li>
                <Icon type={IconType.TabArrow}/>
                {mod.visible_name}
                {mod.amount > 1 && `x ${mod.amount}`}
                {!!(mod.tpv_price_cents * mod.amount) && !receipt &&
                  <PaymentBadge>
                    {`${(mod.tpv_price_cents > 0 ? "+" : "") + calculateEuros(mod.tpv_price_cents * mod.amount)}€`}
                  </PaymentBadge>
                }
                {!!(mod.tpv_price_cents * mod.amount) && receipt &&
                  <span>
                    {`${(mod.tpv_price_cents > 0 ? "+" : "") + calculateEuros(mod.tpv_price_cents * mod.amount)}€`}
                  </span>
                }
              </li>
              <RecursiveList modifiers={mod.modifiers} receipt={receipt}/>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </ModList>
  );
};

const RecursiveList = ({modifiers, receipt = false}: Props) => {
  if (!modifiers || !modifiers.length) return <></>;

  return (
    <ModList receipt={receipt}>
      {modifiers.map(mod => (
        <React.Fragment key={'modifier-' + mod.id}>
          <li>
            <Icon type={IconType.TabArrow}/>
            {mod.visible_name}
            {mod.amount > 1 && ` x ${mod.amount}`}
            {!!(mod.tpv_price_cents * mod.amount) && !receipt &&
              <PaymentBadge>
                {`+${calculateEuros(mod.tpv_price_cents * mod.amount)}€`}
              </PaymentBadge>
            }
            {!!(mod.tpv_price_cents * mod.amount) && receipt &&
              <span>
                {`+${calculateEuros(mod.tpv_price_cents * mod.amount)}€`}
              </span>
            }
          </li>
          <RecursiveList modifiers={mod.modifiers}/>
        </React.Fragment>
      ))}
    </ModList>
  );
};
