import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Popup } from './popup.component';

const TRANSLATIONS = {
  up: '0 150%',
  down: '0 -150%',
  left: '150% 0',
  right: '-150% 0',
};

const slide = (direction: keyof typeof TRANSLATIONS) => keyframes`
  from {
    translate: ${TRANSLATIONS[direction]};
  }
  to {
    translate: 0 0;
  }
`;

const Sheet = styled(Popup).attrs({
  translate: 'no',
})`
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
`;

export const BottomSheet = styled(Sheet)`
  width: 100%;
  max-width: 100vw;
  height: fit-content;
  border-radius: ${({theme}) => `${theme.radius_xl} ${theme.radius_xl} 0 0`};
  margin: auto 0 0 0;
  
  &.open, &.close {
    animation-name: ${slide('up')};
  }
`;

export const TopSheet = styled(Sheet)`
  width: 100%;
  max-width: 100vw;
  height: fit-content;
  border-radius: ${({theme}) => `0 0 ${theme.radius_xl} ${theme.radius_xl}`};
  margin: 0 0 auto 0;

  &.open, &.close {
    animation-name: ${slide('down')};
  }
`;

export const LeftSheet = styled(Sheet)`
  height: 100%;
  max-height: 100vh;
  width: fit-content;
  border-radius: ${({theme}) => `0 ${theme.radius_xl} ${theme.radius_xl} 0`};
  margin: 0 auto 0 0;

  &.open, &.close {
    animation-name: ${slide('right')};
  }
`;

export const RightSheet = styled(Sheet)`
  height: 100%;
  max-height: 100vh;
  width: fit-content;
  border-radius: ${({theme}) => `${theme.radius_xl} 0 0 ${theme.radius_xl}`};
  margin: 0 0 0 auto;

  &.open, &.close {
    animation-name: ${slide('left')};
  }
`;