import ManagerAuth from '../manager/ManagerAuth.api'
import Endpoints from '../endpoints/Endpoints.api'
import useSWR, { mutate, useSWRConfig } from 'swr'

// eslint-disable-next-line
const favorites = (token:any, type:any) => ManagerAuth.client(token, type).get(Endpoints.favorites.favorite)

// eslint-disable-next-line
const setFavorites = (token:any, type:any, value:any) => ManagerAuth.client(token, type).post(Endpoints.favorites.add, value)

// eslint-disable-next-line
const deleteFavorites = (token:any, type:any, id:number) => ManagerAuth.client(token, type).delete(`${Endpoints.favorites.delete}?id=${id}`)


// eslint-disable-next-line
export const UseFavorites = ( access:any, type:any) => {
  const token = localStorage.getItem("token_yumminn");
  const endpoint = token && type ? Endpoints.favorites.favorite : null
  const { data, error } = useSWR(endpoint, () => favorites(access, type), { revalidateOnFocus: false })
  // eslint-disable-next-line
  const dataContent: any = data ? data.data : []
  const { mutate } = useSWRConfig()

  return {
    content: data ? dataContent : [],
    error,
    loading: !error && !data,
    mutate :  () => mutate(Endpoints.favorites.favorite)

  }
}

export const Request = {
    favorites,
    setFavorites,
    deleteFavorites
}