import ManagerAuth from '../manager/ManagerAuth.api'
import Endpoints from '../endpoints/Endpoints.api'
import useSWR, { useSWRConfig } from 'swr'
import React from 'react'

// eslint-disable-next-line
const user = (token:any, type:any) => {
  const isLogged = localStorage.getItem('token_yumminn')
  if(!isLogged) return null
  //console.log(`Users.api.ts -> user(token=${token}, type=${type})`);
  return ManagerAuth.client(token, type).get(Endpoints.users.user);
}
// eslint-disable-next-line
const updateuser = (token:any, type:any, values:any) => ManagerAuth.client(token, type).put(Endpoints.users.update, values)

const giveLoyaltyPoints = (token:any, type:any, values:any) => ManagerAuth.client(token, type).post(Endpoints.users.give_loyalty_points, values)

// eslint-disable-next-line
export const UseUser= (access:any, type:any) => {
  const isLogged = localStorage.getItem('token_yumminn')

  const endpoint =
  isLogged && type ? Endpoints.users.user
  : null;
  
  const { data, error } = useSWR(endpoint, () => user(access, type))
  // eslint-disable-next-line
  const content:any = data ? data.data : []
  // eslint-disable-next-line
  const loading:boolean = !error && !data
  const { mutate } = useSWRConfig()

  return {
    content: content,
    error,
    loading: loading,
    mutate :  () => mutate(Endpoints.users.user)
  }
  
}

export const UseUserProfile= (access:any, type:any) => {
    const isLogged = localStorage.getItem('token_yumminn')

    const random = React.useRef(Date.now())

    const endpoint =
    isLogged && type ? [Endpoints.users.user, random]
    : null;

    const { data, error } = useSWR(endpoint, () => user(access, type), {
      revalidateOnFocus: false
    })
    // eslint-disable-next-line
    const content:any = data ? data.data : []
    // eslint-disable-next-line
    const loading:boolean = !error && !data
    const { mutate } = useSWRConfig()
  
    return {
      content: content,
      error,
      loading: loading,
      mutate :  () => mutate(Endpoints.users.user)
    }
}

export const RequestUser = {
  user,
  updateuser,
}

export const RequestPoints = {
  giveLoyaltyPoints
}