import styled, { css } from "styled-components";

const resetInput = css`
  font-family: ${({theme}) => theme.typography};
  font-size: ${({theme}) => theme.font_md};
  color: currentColor;
  background-color: transparent;
  border: none;
  height: 100%;
  margin: 0;
  padding: 0;
  
  &::placeholder {
    color: ${({theme}) => theme.text_a50};
  }

  &:focus {
    outline: none;
  }

  &[type=number], &[type=tel] {
    text-align: right;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }
`;

const errorStyles = css`
  font-size: ${({theme}) => theme.font_xs};
  line-height: 1;
  color: ${({theme}) => theme.danger};
  display: flex;
  margin: 4px 0 0;
`;

const focusStyles = css`
  outline: ${({theme}) => `${theme.border_sm} ${theme.primary}`};
  outline-offset: 2px;
`;

const disabledStyles = css`
  color: ${({theme}) => theme.text_a30};
  box-shadow: none;
  opacity: 0.65;
  filter: brightness(0.9);
`;

const buttonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({theme}) => theme.typography};
  font-weight: 600;
  color: currentColor;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
`;

const BaseContainer = styled.div<{component?: string|null}>`
  position: relative;
  flex: 1;

  label {
    position: relative;
    width: 100%;
  }

  h5 {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 600;
    margin: 0 0 16px 0;

    &::first-letter {
      text-transform: capitalize;
    }
  }
  
  h6 {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 300;
    margin: 0 0 8px;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  i {
    ${errorStyles}
  }

  .required::after {
    content: '*';
    color: ${({theme}) => theme.danger};
  }

  .error {
    color: ${({theme}) => theme.danger};
  }
`;

export const InputContainer = styled(BaseContainer)`
  flex: 1;
  
  label {
    display: flex;
    width: 100%;
    height: 48px;
    color: ${({theme}) => theme.text};
    background-color: ${({theme}) => theme.input_bg};
    border: ${({theme}) => `${theme.border_sm} ${theme.input_border}`};
    border-radius: ${({theme}) => theme.radius_sm};
    padding: 8px 12px;
    box-sizing: border-box;
    box-shadow: ${({theme}) => theme.shadow_inset_xs};
  }
  
  h6 {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 500;
    margin: 0;
    width: min-content;
    line-height: 1.2;
    max-width: var(--max_width);
    align-self: center;
    overflow: visible;
    position: relative;
    transition: 0.2s ease-in-out;

    span {
      background: linear-gradient(transparent 30%, ${({theme}) => theme.input_bg} 30%);
      padding: 0 0.25em;
      margin: 0 -0.25em;
    }
  }
  
  hr {
    display: block;
    border: none;
    border-right: ${({theme}) => `${theme.border_sm} ${theme.input_border}`};
    margin: 0 12px;
    width: 0;
    transition: 0.2s ease-in-out;
  }
  
  div {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  input {
    ${resetInput}
    width: 100%;
  }

  button {
    ${buttonStyles}
  }

  &:has(i) label {
    border-color: ${({theme}) => theme.danger};
  }

  &:has(input:focus) label {
    ${focusStyles}
  }
  
  &:has(input:focus), &:has(input:not(:placeholder-shown)) {
    h6 {
      font-size: ${({theme}) => theme.font_sm};
      translate: 0 -24px;
      max-width: 0;
      white-space: nowrap;
    }
  
    hr {
      margin: 0 -1px;
      opacity: 0;
    }
  }

  &:has(:disabled) label {
    ${disabledStyles}
  }
`;

export const PhoneInputContainer = styled(BaseContainer)`
  .react-tel-input {
    width: 100%;
    height: ${({component}) => component ? '' : "48px"};
    padding: ${({component}) => component ? '0 12px' : "8px 12px;"};
    background-color: ${({theme}) => theme.input_bg};
    border: ${({theme, component}) => component ? 'none' : `${theme.border_sm} ${theme.input_border}`};
    box-shadow: ${({theme, component}) => component ? '' : theme.shadow_inset_xs};
    border-radius: ${({theme}) => theme.radius_sm};
    box-sizing: border-box;
    
    input {
      font-family: ${({theme}) => theme.typography};
      font-weight: ${({component}) => component ? 600 : 500};
      font-size: ${props => props.theme.font_md};
      color: ${({theme}) => theme.text};
      background-color: transparent;
      border: none;
      line-height: 20px;
      width: 100%;
      height: 100%;
      border: none;
    }

    .form-control{
      padding-left: ${({component}) => component ? '30px' : "48px"};
    }
 

    &:has(:focus) {
      outline: ${({theme, component}) => component ? 'none' : `${theme.border_sm} ${theme.primary}`};
      outline-offset: 2px;
    }

    &:has(::placeholder) {
      font-family: ${({theme}) => theme.typography};
      color: ${({theme}) => theme.text_a50};
    }

    .flag-dropdown {
      background-color: transparent;
      border: none;
      border-right: ${({theme}) => `${theme.border_sm} ${theme.input_border}`};
      margin: 8px 0;
    }

    .country-list {
      bottom: 28px;
      border-radius: ${({theme}) => theme.radius_sm};
      border: ${({theme}) => `${theme.border_sm} ${theme.input_border}`};
      box-shadow: ${({theme}) => theme.shadow_inset_xs};
      background-color: ${({theme}) => theme.background_rised};

      .country-name {
        color: ${({theme}) => theme.text};
      }
      
      .country .dial-code {
        color: ${({theme}) => theme.text_a50};
      }

      .country:hover {
        background-color: ${({theme}) => theme.primary_a20};
      }

      .highlight {
        background-color: ${({theme}) => theme.primary_a40};
      }
    }

    .selected-flag {
      padding: 0;
      width: ${({component}) => component ? '0' : "38px"};

      .arrow {
        border-top-color: ${({theme}) => theme.text_a80};
      }
      
      .up {
        border-bottom-color: ${({theme}) => theme.text_a80};
      }
      
      &.open {
        background-color: transparent;
      }
    }
  }




  
  .error {
    position: absolute;
    top: 14px;
    right: 12px;
  }
  
  &:has(i) .react-tel-input {
    border-color: ${({theme}) => theme.danger};
  }
`;

export const TextAreaContainer = styled(BaseContainer)`
  textarea {
    display: flex;
    font-family: ${({theme}) => theme.typography};
    font-size: ${({theme}) => theme.font_md};
    color: ${({theme}) => theme.text};
    background-color: ${({theme}) => theme.input_bg};
    border: ${({theme}) => `${theme.border_sm} ${theme.input_border}`};
    border-radius: ${({theme}) => theme.radius_sm};
    box-shadow: ${({theme}) => theme.shadow_inset_xs};
    resize: none;
    width: 100%;
    padding: 8px 12px;
    box-sizing: border-box;

    &:focus-visible {
      ${focusStyles}
    }

    &::placeholder {
      color: ${({theme}) => theme.text_a50};
    }

    &:disabled {
      ${disabledStyles}
    }
  }

  &:has(i) textarea {
    border-color: ${({theme}) => theme.danger};
  }
`;

export const ClipboardInputContainer = styled(InputContainer)`
  h6 {
    font-size: ${({theme}) => theme.font_sm};
    translate: 0 -24px;
    max-width: 0;
    white-space: nowrap;
  }

  label > span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.75;
    color: ${({theme}) => theme.text_a80};
  }

  button {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      text-decoration: underline;
      display: block;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  &:has(input:focus), &:has(input:not(:placeholder-shown)) {
    hr {
      margin: 0 12px;
      opacity: 1;
    }
  }
`;