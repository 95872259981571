import { RestaurantCustomization } from "../../context/app";
import { generatePalette } from "../utils/colors";
import { baseTheme } from "./baseTheme";
import * as themeOptions from "./theme-options";

const DEFAULT_COLORS = { primary: '#094553', secondary: '#FFFFFF', tertiary: '#094553' };

export const restaurantTheme = ({theme, colors, photoOverlay, typography}: Partial<RestaurantCustomization>) => {
  const primary = colors?.primary || DEFAULT_COLORS.primary;
  const secondary = colors?.secondary || DEFAULT_COLORS.secondary;
  const tertiary = colors?.tertiary || DEFAULT_COLORS.tertiary;

  const primaryPalette = generatePalette(primary, 'primary');
  const secondaryPalette = generatePalette(secondary, 'secondary');
  const tertiaryPalette = generatePalette(tertiary, 'tertiary');

  const newTheme = {
    ...baseTheme,
    ...themeOptions[theme || 'light'],
    ...primaryPalette,
    ...secondaryPalette,
    ...tertiaryPalette,
    photo_overlay: photoOverlay ? '0deg, #0003, #000A' : '0deg, transparent, transparent',
    typography: typography || `'Poppins', sans-serif`,
  };
  const root = document.documentElement;

  for (const property in newTheme) {
    root.style.setProperty(`--${property}`, newTheme[property as keyof typeof newTheme]);
  }
  return newTheme;
};