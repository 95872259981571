import styled, { keyframes } from "styled-components";

type MarqueeProps = {
  height: number,
  color: string
}

const movingToLeft = keyframes`
  0% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%)
  }
`;

const MarqueeContainer = styled.div<MarqueeProps>`
  width: 100%;  
  background: ${props => props.color};
  height: ${props => props.height > 1000 ? "68px" : window.innerHeight < 320 ? "25px" : "38px"};
  display: flex;
  overflow: hidden;
  white-space: nowrap;
`;

const MarqueeMovingContainer = styled.div`
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-shrink: 0;
  color: #FFF;
  font-weight: 700;
  animation: ${movingToLeft} 60s linear infinite;
  -webkit-animation: ${movingToLeft} 60s linear infinite;
  font-size: 15px;
`;

export {MarqueeContainer, MarqueeMovingContainer};