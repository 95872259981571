import styled, { keyframes } from "styled-components";

const TapArea = styled.div`
  padding: 6px 6px 6px 48px ;
  margin: -6px -6px -6px -48px;
`;

const CheckboxContainer = styled.div<CheckboxProps>`
  border-radius: ${({theme}) => theme.radius_round};
  border: ${({isChecked, theme}) => `${theme.border_sm} ${isChecked ? theme.text : theme.input_border}`};
  width: 18px;
  height: 18px;
  margin-left: 8px;
  color: ${({theme}) => theme.background};
  background-color: ${({isChecked, theme}) => isChecked ? theme.text : theme.input_bg};
  box-shadow: ${({isDisabled, theme}) => isDisabled ? 'none' : theme.shadow_inset_xs};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transition: 0.15s ease-in-out;
  opacity: ${({isDisabled}) => isDisabled ? 0.6 : 1};
  filter: brightness(${({isDisabled}) => isDisabled ? 0.9 : 1});

`;

const bounce = keyframes`
  0%{
    transform:scale(0.7);
    opacity: 0;
  }
  50%{
    transform:scale(1.2);
    opacity: 1;
  }
  80%{
    transform:scale(0.9);
    opacity: 1;
  }
  100%{
    transform:scale(1);
    opacity: 1;
  }
`;

const Checkmark = styled.svg.attrs({
  width: "14",
  height: "10",
  viewBox: "0 0 14 10",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
})`
  animation: ${bounce} 0.4s ease-in-out forwards;
  stroke: currentColor;
`;

type CheckboxProps = {
  isChecked: boolean;
  isDisabled: boolean;
}

export {CheckboxContainer, Checkmark, TapArea};