import React, { useEffect, useContext, useState } from 'react';
import { InputWrapper, Label } from './ProfileInputContainers';
import { RoundCheckbox } from '../round-checkbox';
import { t } from 'i18next';
import { Icon, IconType } from '../icons';
import { PhoneInput } from '../../elements';
import { getPhoneFunctions, useClientInptusConfig } from '../../shared/utils/clientInputsLogic';
import { UserContext, UserContextDispatchContext } from '../../context/auth/UserContext';

export const ProfileInputComponent = ({
  type = 'text', 
  name, 
  value, 
  options, 
  placeholder, 
  isCompleted, 
  error, 
  onChange,
  onBlur
}: props) => {
  const {
    isPhoneRequired,
  } = useClientInptusConfig();
  const { updateUser } = useContext(UserContextDispatchContext);
  const { user: { phone } } = useContext(UserContext);

  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const {handlePhoneChange, handlePhoneBlur} = getPhoneFunctions({isPhoneRequired, setIsPhoneValid, updateUser});

  const handleChange = (phoneNumber: any) =>  {
    console.log('Phone number changed:', phoneNumber); // Log the phone number when it changes

    handlePhoneChange(phoneNumber); 
    const formattedNumber = `+${phoneNumber}`

    if (onChange) {
      onChange(formattedNumber);
    }  
  }
 
  return (
    <Label htmlFor={'profile-input-' + name}>
      <span>{t(name)}</span>
      <InputWrapper $error={!!error} value={value}>
        {onChange &&
          <RoundCheckbox isChecked={isCompleted || false}/>
        }
        {type !== 'select' && name !== "phone" ? 
          <input
            id={'profile-input-' + name}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            readOnly={!onChange}
            onChange={onChange}
            onBlur={onBlur}
          />
          : name === "phone" ?
            <PhoneInput
              component='personalInfo'
              value={value as string}
              onChange={handleChange}
              onBlur={handlePhoneBlur}
            />
          :
          <select
            id={'profile-input-' + name}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
          >
            <option hidden disabled value=''>{placeholder}</option>
            {options?.map(({value, label}) => <option key={value} value={value}>{label}</option>)}
          </select>
        }
        {onChange &&
          <Icon type={IconType.Edit}/>
        }
      </InputWrapper>
      {error &&
        <i>{t(error)}</i>
      }
    </Label>
  );
};

type props = {
  type?: string,
  name: string,
  value: string | number,
  options?: {value: string, label: string}[]
  placeholder?: string,
  isCompleted?: boolean,
  error?: string,
  onChange?: any,
  onBlur?: any,
};