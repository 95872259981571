import { t } from 'i18next';
import React from 'react';
import { RadioButton, ButtonsContainer } from './RadioButtonsContainers';

export const RadioButtonsComponent = ({name, value, options, disabled, onChange}: Props) => {
  return (
    <ButtonsContainer $disabled={disabled}>
      {options.map((option) => (
        <RadioButton $checked={value === option} key={'option-' + option}>
          {t(option)}
          <input
            type="radio"
            name={name}
            value={option}
            checked={value === option}
            disabled={disabled}
            onChange={(event) => onChange(event.target.value)}
          />
        </RadioButton>
      ))}
    </ButtonsContainer>
  );
};

type Props = {
  name: string;
  value: any;
  options: string[];
  disabled?: boolean;
  onChange: React.Dispatch<React.SetStateAction<any>>;
};