export enum ButtonSize {
    ExtraSmall = '12px',
    Small = '14px',
    Medium = '16px',
    Large = '18px',
    ExtraLarge = '20px',
}

export enum ButtonLineHeight {
    ExtraSmall = '22px',
    Small = '24px',
    Medium = '28px',
    Large = '32px',
    ExtraLarge = '36px',
    None = '0'
}
