import { Popup } from "./app";

export type PopupAction =
	| { type: 'UPDATE_POPUP', payload: Partial<Popup> }

export const popupReducer = (state: Popup, action: PopupAction): Popup => {
  switch (action.type) {
    case 'UPDATE_POPUP':
      return {
        ...state,
        ...action.payload
      }
  };
};