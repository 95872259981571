import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { LoadingContainer } from "../pages/loading";
import { BootstrapRouting } from "./bootstrap.routing";
import AuthProvider from "../context/auth/AuthContext";
import { GlobalToastContainer } from "../elements";
import { GBConditionalWrapper } from "./GBConditionalWrapper";
import { GlobalThemeProvider } from "../context/theme/ThemeContext";
import UserProvider from "../context/auth/UserContext";

export const BootstrapContainer = () => (
  <BrowserRouter>
    <Suspense fallback={<LoadingContainer parentComponent='BootstrapContainer'/>}>
      <GBConditionalWrapper>
        <GlobalToastContainer/>
        <AuthProvider>
          <UserProvider>
            <GlobalThemeProvider>
              <BootstrapRouting />
            </GlobalThemeProvider>
          </UserProvider>
        </AuthProvider>
      </GBConditionalWrapper>
    </Suspense>
  </BrowserRouter>
);
