import styled from 'styled-components';

export const HomeOuterContainer = styled.div<Home>`
    display: flex;
    flex-direction: column;
    background-color: ${({theme}) => theme.background};
    min-height: var(--dvh); /* fallback */
    min-height: 100svh;
    height: ${props => props.orderAndPay ? "" : 'var(--dvh)'}; /* fallback */
    height: ${props => props.orderAndPay ? "" : '100svh'};
`;

export const LoaderContainer = styled.div`
    background-color: ${({theme}) => theme.background};
    height: var(--dvh); /* fallback */
    height: 100svh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
`;

type Home = {
    orderAndPay?: boolean
}
