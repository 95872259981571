import _ from 'lodash';
import React, { useContext, useState, useEffect } from 'react'
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { eventTrack } from '../../../useGaTracker';
import { Request, UseFavorites } from "../../api/favorites/Favorites.api";
import { AuthContext } from '../../context/auth/AuthContext';
import { PopupType } from '../../context/popups.enum';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { postError } from '../../shared/utils/postError';

export const useSavedButton = (id?: number) => {
  const restaurantId = id || Number(sessionStorage.getItem('restaurantId'));
  const token: any = localStorage.getItem("token_yumminn");
  const token_type: any = localStorage.getItem("type");
  
  const { isLogged } = useContext(AuthContext);
  const { goToPopup } = usePopupNavigation()

  const [isFavorite, setIsFavorite] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [idFavorite, setIdFavorite] = useState(0)

  const {
    content: contentFavorites,
    loading: loadingFavorite,
    mutate,
  } = UseFavorites(token, token_type);

  const favorite =
  !loadingFavorite &&
  contentFavorites.filter(
    (item: any) => item.restaurant.id === restaurantId
  );

  useEffect(() => {
    if (!_.isEmpty(favorite)) {
      setIdFavorite(favorite[0].id);
      if (!isFavorite) {
        setIsFavorite(true);
        mutate && mutate();
      }
    } else {
      if (isFavorite) {
        setIsFavorite(false);
        mutate && mutate();
      }
    }
  }, [])

  const onSubmit = () => {

    if (isLoading) {
      return;
    }

    if (isLogged) {
      setIsLoading(true);
      eventTrack("favorite button home DA", "favorite-button-home-DA", "Click favorite button homepage while logged")
      const token: any = localStorage.getItem("token_yumminn");
      const token_type: any = localStorage.getItem("type");
      if (isFavorite) {
        Request.deleteFavorites(token, token_type, idFavorite)
          .then(() => onSuccess())
          .catch((error: any) => onFailure(error));
      } else {
        Request.setFavorites(token, token_type, {"restaurant_id": restaurantId})
          .then(() => onSuccess())
          .catch((error: any) => onFailure(error));
      }
    } else {
      eventTrack("favorite button", "favorite-button", "Click favorite button")
      goToPopup(PopupType.Register)
    }
  };

  const onSuccess = () => {
    setIsFavorite(!isFavorite);
    setIsLoading(false);
  };

  const onFailure = (error: any) => {
    postError(error)
    setIsLoading(false);
    toast.error(`${error}`, {
      theme: "colored",
      position: "bottom-center",
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  };

  return {isFavorite, isLoading, onSubmit};
}