import styled from "styled-components";

const ProgressBarContainer = styled.div`
  height: 8px;
  width: 100%;
  margin: 8px 0;
  background-color: ${({theme}) => theme.background_contrast};
  border-radius: ${({theme}) => theme.radius_round};

  & > div {
    border-radius: ${({theme}) => theme.radius_round};
    height: 8px;
    min-width: min-content;
    background: ${({theme}) => theme.primary};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: width 0.2s ease;
    
    & > span {
      display: flex;
      width: 24px;
      height: 24px;
      padding: 1.5px;
      margin: -1.5px;
      background-color: #FFF;
      border-radius: ${({theme}) => theme.radius_round};
      box-sizing: border-box;
      box-shadow: ${({theme}) => theme.shadow_sm};

      &:not(:has(*)) {
        display: none;
      }
    }
  }
`;

export {ProgressBarContainer}