import styled from "styled-components";

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: ${({theme}) => theme.font_lg};
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 8px;

    span {
      color: ${({theme}) => theme.background};
      background-color: ${({theme}) => theme.text};
      height: 18px;
      min-width: 18px;
      border-radius:  ${({theme}) => theme.radius_round};
      display: flex;
      justify-content: center;
      padding: 4px;
      box-sizing: border-box;
    }
  }

  span {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: ${({theme}) => theme.font_xs};
    color: ${({theme}) => theme.text_a80};
  }
`;

export const ProductList = styled.ul`
  list-style: none;
  padding: 0 0 0 16px;
  margin: 0;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
  }

  span {
    font-weight: 600;
    color: ${({theme}) => theme.text};
    background-color: ${({theme}) => theme.gray_20};
    height: 16px;
    min-width: 16px;
    border-radius:  ${({theme}) => theme.radius_round};
    display: flex;
    justify-content: center;
    padding: 4px;
    box-sizing: border-box;

    &:last-child {
      background: none;
      margin: 0 0 0 auto;
    }
  }

  h5 {
    font-size: ${({theme}) => theme.font_md};
    line-height: 1.5;
    font-weight: 400;
    margin: 0;
  }
  
  b {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 500;
    margin: 0 0 0 auto;

    &.gift {
      font-size: ${({theme}) => theme.font_sm};
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
      color: ${({theme}) => theme.primary};
      background-color: ${({theme}) => theme.tertiary_a20};
      border: 1px solid currentColor;
      border-radius: ${({theme}) => theme.radius_round};
      padding: 4px 8px;
    }
  }
`;

export const SplitList = styled.div`
  flex-direction: column;
  gap: 8px;

  h4 {
    font-weight: 600;
    margin: 0;
    
    &::first-letter {
      text-transform: uppercase;
    }
  }

  p {
    display: flex;
    font-size: ${({theme}) => theme.font_sm};
    margin: 0 0 0 16px;
  }

  b {
    margin: 0 0 0 auto;
    font-weight: 600;
  }
`;