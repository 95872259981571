import React, { useEffect, useMemo, useState } from 'react';
import { StickyLabel, HeaderTree, Section } from './virtual-sections.component';
import { HeaderContainer } from './VirtualSectionsContainers';
import { ScrollTabs } from '../scroll-tabs';
import { Icon, IconType } from '../icons';
import { t } from 'i18next';
import { checkLastLevel, findTreeIndex, getTreeId } from './functions';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { PopupType } from '../../context/popups.enum';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GB_FEATS } from '../../growthbook';

type SimpleProps = {
  top: number
  name: string
  sections: Section[]
  selectedSection: Section | null
  stickyLabels?: StickyLabel[] | null
  scrollTo: (index: number) => void
}

type TreeProps = SimpleProps & {
  tree: HeaderTree
  isCollapsed: boolean
  isSeeAllCollapsed: boolean
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>
};

type RecursiveProps = {
  name: string
  tree: HeaderTree | Section['id']
  level?: number
  visible?: boolean
  selectedSection: Section | null
  sections: Section[]
  showAll: boolean
  stickyLabels?: StickyLabel[] | null
  isCollapsed: boolean
  isSeeAllCollapsed: boolean
  scrollTo: (index: number) => void
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>
};

type Props = SimpleProps | TreeProps;

const RecursiveHeader = ({
  name,
  tree,
  level = 1,
  visible = true,
  selectedSection,
  sections,
  showAll,
  stickyLabels,
  isCollapsed,
  isSeeAllCollapsed,
  scrollTo,
  setIsCollapsed
}: RecursiveProps) => {
  const { goToPopup } = usePopupNavigation();

  const categories = Object.entries(tree).sort((a, b) => a[1].order - b[1].order);

  const getLabelId = (index: number) => `${name}-l${level}-${index}`;

  const selectedIndex = useMemo(() => {
    const index = findTreeIndex(tree, selectedSection?.id)

    document.getElementById(getLabelId(index))?.scrollIntoView({inline: 'center', block: 'nearest'});

    return index === -1 ? 0 : index;
  }, [selectedSection, tree]);

  const skipThisLevel = !visible || categories.length <= 1;
  const nextLevel: HeaderTree | Section['id'] = categories[selectedIndex]?.[1].value;

  const [showChildren, setShowChildren] = useState(categories.length <= 1);
  const [isSeeAllCollapsedLocal, setIsSeeAllCollapsedLocal] = useState(false);
  
  const scrollToSection: React.ChangeEventHandler = event => {
    event.target.parentElement?.scrollIntoView();
    const treeIndex = Number((event.target as HTMLInputElement).value);
    const sectionIndex = sections.findIndex(section => section.id === getTreeId(tree, treeIndex))
    scrollTo(sectionIndex);
  };

  const toggleChildren = (isSelected: boolean, isCollapsed: boolean, isLastLevel: boolean) => {
    if (isCollapsed) {
      setIsCollapsed(false);
      setShowChildren(true);
    } else if (isLastLevel) {
      setIsCollapsed(true);
    } else if (!isSelected) {
      setShowChildren(true);
    } else {
      setShowChildren(prev => !prev);
    }
  };

  const toggleSeeAllCollapsed: React.UIEventHandler = event => {
    showAll && setIsSeeAllCollapsedLocal((event.target as HTMLDivElement).scrollLeft > 0);
  };

  const openSeeAll = () => goToPopup(PopupType.NavigateAll);

  useEffect(() => {
    setShowChildren(categories.length <= 1);
  }, [categories.length]);

  return (
    <>
      {(categories.length !== 1 || ['number', 'string'].includes(typeof nextLevel)) &&
        <header className={visible ? 'visible' : ''}>
          {stickyLabels?.map(({children, className, ...labelProps}, index) =>
            <label
              key={`sticky_label_${index}`}
              className={`sticky ${className}`}
              {...labelProps}
            >
              {children}
            </label>
          )}
          {showAll &&
            <label
              className={`sticky see_all ${(isSeeAllCollapsed || isSeeAllCollapsedLocal) ? 'collapsed' : ''}`}
              onClick={openSeeAll}
            >
              <Icon size={12} type={IconType.Hamburger}/>
              <span>{t('see all')}</span>
            </label>
          }
          <div onScroll={toggleSeeAllCollapsed}>
            {categories.map(([label, tree], index) => {
              const isLastLevel = checkLastLevel(tree);

              return (
                <label key={getLabelId(index)} id={getLabelId(index)} className={showChildren ? 'expanded' : ''}>
                  <span>{label.split('parentId:')[0]}</span>
                  {!isLastLevel &&
                    <Icon type={IconType.ArrowDown} size={14}/>
                  }
                  <input
                    type='radio'
                    name={`l${level}`}
                    value={index}
                    checked={index === selectedIndex}
                    onChange={scrollToSection}
                    onClick={() => toggleChildren(index === selectedIndex, isCollapsed, isLastLevel)}
                  />
                </label>
              );
            })}
          </div>
        </header>
      }
      {selectedIndex !== null && nextLevel && !['number', 'string'].includes(typeof nextLevel) &&
        <RecursiveHeader
          name={name}
          tree={nextLevel}
          level={level + 1}
          selectedSection={selectedSection}
          sections={sections}
          showAll={showAll && skipThisLevel}
          stickyLabels={skipThisLevel ? stickyLabels : undefined}
          visible={visible && showChildren}
          isCollapsed={isCollapsed}
          isSeeAllCollapsed={isSeeAllCollapsed}
          scrollTo={scrollTo}
          setIsCollapsed={setIsCollapsed}
        />
      }
    </>
  );
};

const TreeHeader = ({top, ...props}: TreeProps) => {
  const showAll = useFeatureIsOn(GB_FEATS.MENU_SEE_ALL);
  
  return (
    <HeaderContainer top={top} className={props.isCollapsed ? 'collapsed' : ''}>
      <RecursiveHeader {...props} showAll={showAll}/>
    </HeaderContainer>
  );
};

const SimpleHeader = ({top, name, selectedSection, sections, stickyLabels, scrollTo}: SimpleProps) => {
  const onTabChange = (value: string) => {
    const index = sections.findIndex(section => section.label === value);
    scrollTo(index);
  };

  return (
  <HeaderContainer top={top}>
    <header>
      {stickyLabels?.map((label, index) =>
        <label key={`sticky_label_${index}`} className='sticky' onClick={label.onClick}>
          {label.children}
        </label>
      )}
      <ScrollTabs
        name={name}
        value={selectedSection?.label}
        options={sections.filter(section => !!section.label).map(section => section.label)}
        onChange={onTabChange}
      />
    </header>
  </HeaderContainer>
  );
};

export const TabsHeader = (props: Props) => {
  return ('tree' in props && props.tree) ? <TreeHeader {...props}/> : <SimpleHeader {...props}/>
};
