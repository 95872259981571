import { t } from 'i18next';
import React, { useEffect, useRef } from 'react';
import { InputContainer } from './InputContainers';
import { Icon, IconType } from '../icons';

type Props = {
  name: string
  value: string
  label?: string | React.ReactNode
  title?: string | React.ReactNode
  inputMode?: 'decimal' | 'numeric'
  step?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  error?: string | false
  unit?: string
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  onChange: React.ChangeEventHandler
  onFocus?: React.FocusEventHandler
  onBlur?: React.FocusEventHandler
  varForTranslation?: string
};

export const NumberInput = ({
  name,
  value,
  label,
  title,
  inputMode = 'decimal',
  step = '.01',
  placeholder = '',
  disabled = false,
  required = false,
  error,
  unit,
  leftIcon,
  rightIcon,
  onChange,
  onFocus,
  onBlur,
  varForTranslation
}: Props) => {
  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const maxWidth = titleRef.current?.clientWidth ? `${titleRef.current.clientWidth}px` : 'auto';
    titleRef.current?.style.setProperty('--max_width', maxWidth);
  }, []);

  const translateWithInterpolation = (key: string, interpolation?: { [key: string]: any }) => {
    return interpolation ? t(key, interpolation) : t(key);
  };

  return (
    <InputContainer>
    {title &&
      <h5 className={required ? 'required' : ''}>
        {typeof title === 'string' ?  t(title) : title}
      </h5>
    }
    <label>
      {label &&
        <>
          <h6 ref={titleRef} className={(required && !title) ? 'required' : ''}>
            <span>
              {typeof label === 'string' ?  t(label) : label}
            </span>
          </h6>
          <hr/>
        </>
      }
      <div>
        {leftIcon}
        {error &&
          <div className='error'>
            <Icon type={IconType.Error}/>
          </div>
        }
        <input
          type='tel'
          inputMode={inputMode}
          step={step}
          id={name}
          name={name}
          value={value}
          placeholder={t(placeholder)}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          autoComplete='off'
        />
        {unit}
        {rightIcon}
      </div>
    </label>
    {error &&
      <i>{typeof error === 'string' ? translateWithInterpolation(error, { varForTranslation }) : error}</i>
    }
    </InputContainer>
  );
};