import React, { useContext, useMemo } from 'react';
import { ToastContainer } from 'react-toastify';
import { PopupContext } from '../../context/PopupContext';
import { PopupType } from '../../context/popups.enum';

export const GlobalToastContainer = () => {
  const { popup } = useContext(PopupContext);

  const isDialogVisible = useMemo(() => {
    const dialog: HTMLDialogElement | null = document.querySelector(".dialog_popup");
    return popup.current !== PopupType.None && dialog?.open;
  }, [popup]);

  // Toasts can't be placed on top of dialogs (popups) unless ToastContainer is inside that element
  // To avoid duplicated toasts, remove this container when a dialog is open
  // This is a temporal workaround, ideal solution => https://fkhadra.github.io/react-toastify/multi-containers
  if (isDialogVisible) return <></>;

  return (
    <div translate="no" className="modal-toastify notranslate" id="modal-toastify" style={{zIndex: 99999}}>
      <div id="modal-toastify-body">
        <ToastContainer
          style={{ zIndex: 200000 }}
          position="bottom-center"
          theme='colored'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          icon={false}
        />
      </div>
    </div>
  )
}