import axios from "axios"

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
// Settings
const apiConfig = {
  baseURL: REACT_APP_BASE_URL,
}


// Client
const API = () => {
  // eslint-disable-next-line
  const headers: any = {
    "Content-Type": "application/json",
    "authorization-type": "front"
  }

  const instance = axios.create({ baseURL: apiConfig.baseURL, headers: headers })

  instance.interceptors.request.use(
    // eslint-disable-next-line
    async (config: any) => {
      config.metadata = { startTime: performance.now() }
      const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
      if (yumminnToken) {
        config.headers["yumminn-token"] = yumminnToken;
      }
      return config;
    },
  );

  instance.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    // Set default retry and retryDelay values if not already set in the request
    config.retry = config.retry || 3;
    config.retryDelay = config.retryDelay || 1000;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message?.includes("timeout") || message?.includes("Network Error"))) {
      return Promise.reject(err);
    }
    const delayRetryRequest = new Promise<void>((resolve) => {
      setTimeout(() => {
        console.log("retry the request", config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => {
      config.retry -= 1;
      if (config.retry === 0) {
        // Throw an error in the last retry attempt
        if (err.response && err.response.data && err.response.data.detail) {
          throw new Error(err.response.data.detail);
        } else {
          throw new Error(err.message);
        }
      }
      return instance(config);
    });
  });

  return instance;
};
  
const APIProduct = (restaurant: any, tableId: any) => {
  // eslint-disable-next-line
  let headers: any = {
    "Content-Type": "application/json",
    "authorization-type": "front",
  }

  const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
  if (yumminnToken) {
    headers["yumminn-token"] = yumminnToken;
  }

  const params: any = {
    "restaurant": restaurant,
    "tableId": tableId
  }

  const instance = axios.create({ baseURL: apiConfig.baseURL, params: params, headers: headers })
  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    console.log(response)
    return response;
  }, function (error) {
    console.log(error)
  });

  instance.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    // Set default retry and retryDelay values if not already set in the request
    config.retry = config.retry || 3;
    config.retryDelay = config.retryDelay || 1000;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message?.includes("timeout") || message?.includes("Network Error"))) {
      return Promise.reject(err);
    }
    const delayRetryRequest = new Promise<void>((resolve) => {
      setTimeout(() => {
        console.log("retry the request managerAuth", config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => {
      config.retry -= 1;
      if (config.retry === 0) {
        // Throw an error in the last retry attempt
        if (err.response && err.response.data && err.response.data.detail) {
          throw new Error(err.response.data.detail);
        } else {
          throw new Error(err.message);
        }
      }
      return instance(config);
    });
  });

  return instance
}

const Manager = {
  apiConfig: apiConfig,
  client: () => API(),
  clientProduct: (restaurant: any, tableId: any) => APIProduct(restaurant, tableId),
}

export default Manager