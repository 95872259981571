import styled from "styled-components";
import { SquareIcon } from "../../elements";

type props = {
  standAloneRoute?: boolean
}

export const NotFoundContainer = styled.div<props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1;
  text-align: center;
  padding: 0 40px 40px;
  color: var(--text);
  background-color: var(--background);

  &:has(img) {
    margin: 15vh 0px;
  }

  img {
    margin-bottom: auto;
    max-height: 250px;
    max-width: 250px;
    width: auto;
    object-fit: contain;
  }

  strong {
    display: block;
    font-size: ${({theme}) => theme.font_xl};
    font-weight: 600;
    margin: 8px 0;
    text-transform: uppercase;
  }

  b {
    font-weight: 600;
  }

  p {
    margin: 0;
    text-wrap: balance;
    line-height: 1.3;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    width: 100%;
    text-transform: lowercase;
    text-wrap: balance;

    &::first-letter {
      text-transform: capitalize;
    }
  }
`;

export const NotFoundIcon = styled(SquareIcon).attrs({
  size: 68,
})`
  --padding: 14px !important;

  &::after {
    font-size: 18px !important;
  }
`;