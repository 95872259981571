import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '../../elements';
import ZeroSixLoyaltyCards from '../zerosix';


export const ZeroSixCards = () => {
  const history = useHistory();

  return (
    <>
      <PageHeader text='ZeroSix' onGoBack={() => history.push('/profile')}/>
      <ZeroSixLoyaltyCards />
    </>
  )
};