import React, { useState, useEffect } from 'react';
import {useTranslation} from 'react-i18next';
import { ListContainer, RestaurantList, EmptyList } from './LoyaltyContainers';
import styled, { ThemeProvider } from 'styled-components';
import { Loading} from '../../elements';
import { ZeroSixRestaurantCard } from './components/ZerosixRestaurantCard';
import loyaltyIllustration from '../../assets/images/redesign/loyalty_card.svg'
import { RegisterPopup } from './components/RegisterPopup';
import ZeroSixModal from './components/ZeroSixModal';
import { baseTheme } from '../../shared/themes';
import { postError } from '../../shared/utils/postError';

type Voucher = {
    name: any;
    code: number;
    value: number;
    unit: number;
    expiration: number;
    minimum_amount: number;
}

type CardsData = {
    restaurant: any;
    points_balance: number;
    total_points: number;
    vouchers: Voucher[];
}

type ClientInfo = {
    available_vouchers: CardsData[]
}
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

function addAuthorizationHeaders(headers: any) {
    const token: any = localStorage.getItem("token_yumminn");
    const type: any = localStorage.getItem("type");
    if (token && type && token !== "undefined" && type !== "undefined") {
      headers["Authorization"] = token;
      headers["Authorization-Type"] = type;
    }
    return headers;
  }

export const ZeroSixLoyaltyCards = () => {
    const {t} = useTranslation();
    const [clientInfo, setClientInfo] = useState<ClientInfo | any>(null);
    const zerosix_available = sessionStorage.getItem('zerosix_available');
    const restaurantName = sessionStorage.getItem('restaurantName');
    const [isLoading, setIsLoading] = useState(true);
    const [response , setResponse] = useState("")
    const [isOpened, setOpened] = useState(false);
    const [infoUrl, setInfoUrl] = useState('https://zerosix.com/es')
    
    const ModalContent = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: #5c3aff;
    }
  `;

  const cardsInfo = async (changeOpen: boolean) => {
  getCardsInfo(changeOpen).then((result) => {
    setClientInfo(result); 
    setIsLoading(false)
  })
  .catch((e: any) => {
    postError(e);
    setIsLoading(false);
  });
}

const getCardsInfo = async (changeOpen: boolean) => {
    const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
    const response = await fetch(`${REACT_APP_BASE_URL}/loyalty/client/zerosix_client_vouchers`, {
    method: "GET",
    headers
    });
    if (response.ok) {
    const result = await response.json();
    let found = false
    for (let i = 0; i < result?.available_vouchers.length; i++) {
        if (result.available_vouchers[i].restaurant.name == restaurantName){
            found = true
        } 
    } 
    if (!found && Number(zerosix_available) == 1 && changeOpen) {
        setOpened(true)
    }
    
    return result;
  }
    }

    const handleClose = () => {
        setOpened(false)
        setIsLoading(true)
        setResponse("")
        cardsInfo(false)
    }

    useEffect(() => {
        cardsInfo(true)
    },[])

    return (
        <ThemeProvider theme={baseTheme}>
                {isLoading ?
                    <Loading parentComponent='ZeroSixLoyaltyCards' style={{flex: 1}}/>
                :
                    <>
                        <ListContainer>
                            {clientInfo?.available_vouchers.length ?
                                        <RestaurantList>
                                            {
                                                clientInfo.available_vouchers.map((item: CardsData, index: number) => <ZeroSixRestaurantCard key={'card-' + index} card={item}/>)
                                            }
                                        </RestaurantList>
                                :
                                    <EmptyList>
                                        <img src={loyaltyIllustration} alt='loyalty card'/>
                                        <p>{t('no loyalty cards')}</p>
                                    </EmptyList>}
                        </ListContainer>
                    </>
                }
                <ZeroSixModal isOpen={isOpened} handleClose={handleClose} response={response}>
                    <ModalContent>
                        <RegisterPopup setResponse={setResponse} response={response} setInfoUrl={setInfoUrl} infoUrl={infoUrl}/>
                    </ModalContent>
                </ZeroSixModal>
        </ThemeProvider>
        )
    };
