import React from "react";
import { Checkbox, Slider, SwitchContainer } from "./ToggleSwitchContainers";

type Props = {
    isChecked: boolean,
    disabled?: boolean,
    handleChange?: () => void,
    switchType?: string
}

export const ToggleSwitchComponent = ({isChecked, disabled, handleChange, switchType}: Props) => {
    return(
        <SwitchContainer switchType={switchType} checked={isChecked} disabled={disabled}>
            <Checkbox checked={isChecked} disabled={disabled} onChange={handleChange}/>
            <Slider/>
        </SwitchContainer>
    );
};