import styled, { keyframes } from 'styled-components';
import loyaltyBg from '../../assets/images/loyalty_bg.svg'

const InformationBody = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 20px 20px 20px;
    flex-direction: column;
    text-align: center;
`;

const LoyaltyCardContent = styled.div`
    display: flex;
    padding: 0 0px 0 10px;
    align-items: flex-start;
    gap: 25px;
`;

type LoyaltyBannerProps = {
    switchType?: string
    disabled?: boolean
}

const LoyaltyBannerContainer = styled.div<LoyaltyBannerProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    color: ${({theme, disabled}) => disabled ? '' : theme.text_dark};
    background: ${({theme, switchType, disabled}) => disabled ? theme.switch_disabled : switchType === 'credit_true' ? theme.bg_credit_banner : switchType === 'loyalty_true' ? theme.bg_loyalty_banner : switchType === 'credit_false' ? theme.credit_banner : theme.cash_gradient_40};
    border-radius: ${({theme}) => theme.radius_md};
    padding: 12px;
    min-height: 32px;
    border: ${({theme, switchType}) => switchType === 'credit_true' ? `1px solid ${theme.border_switch_credit_banner}` :  switchType === 'loyalty_true' ? `1px solid ${theme.border_switch_loyalty}` : ''};

    svg {
        flex-shrink: 0;
    }

    p {
        font-size: ${({theme}) => theme.font_sm};
        font-weight: 600;
        margin: 0 auto 0 0;
        color: ${({theme, switchType, disabled}) => disabled ? theme.switch_disabled_text : switchType === 'credit_true' || switchType === 'loyalty_true' ? theme.text_credit_banner : ''};
    }

    & > label {
        pointer-events: none;
        background: ${({theme, switchType}) => switchType === 'credit_true' ? theme.bg_switch_credit_banner : switchType === 'loyalty_true' ? theme.bg_switch_loyalty : switchType === 'credit_false' ? theme.switch_credit_banner : switchType === 'loyalty_false' ? theme.switch_loyalty : '#434343'};

        span{
            background: ${({ theme, switchType }) => switchType === 'credit_true' ? `${theme.switch_credit_banner} !important` : switchType === 'loyalty_true' ? `${theme.switch_loyalty}!important` : switchType === 'credit_false' ? theme.bg_switch_credit_banner : switchType === 'loyalty_false' ? theme.bg_switch_loyalty : ''};
        }
    }

`;

const LoyaltyBannerContainerNoRedeem = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${props => props.theme.font_sm};
    line-height: 1em;
    color: ${({theme}) => theme.text_dark};
    background: ${({theme}) => theme.cash_gradient_40};
    padding: 12px;
    gap: 8px;
    border-radius: ${({theme}) => theme.radius_md};

    b {
        font-size: ${({theme}) => theme.font_sm};
        font-weight: 600;
        margin: 0;
    }

    p {
        margin: 0;
    }
`;

const CardsMenu = styled.div`
    padding: 8px 0;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    border-bottom-width: 2px;
    overflow-x: auto;
    padding-right: 20px;
`;

const HowItWorksContainer = styled.div`
    display: flex;
    padding: 0 20px;
    flex-direction: column;
    line-height: 21px;
    margin-top: 25px;
`;

const CardsContainer = styled.div`
    display: flex;
    color: #767676;
    padding: "40px";
    flex-direction: column;
    line-height: 23.4px;
`;

const Circle = styled.div`
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 10px;
    background: #fff;
    background: #EFF8F4;
    text-align: center;
    margin-right: 20px;
    margin-bottom: 10px;
`;

const LoyaltyCard = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(149, 157, 165, 0.2);
    border-radius: 20px;
    display: flex;
    position: relative;
    margin-bottom: 40px;
    max-width: 360px;
    min-width: 300px;
`;

const animatePop = keyframes`
    0% {
        opacity: 0;
        transform: scale(0.5, 0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
`;

const SalmonBanner = styled.div`
    font-size: 13px;
    font-weight: 400;
    color: #404040;
    animation-duration: 0.5s;
    animation-name: ${animatePop};
    animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
    height: 30;
    width: 300px;
    flex-grow: 1;

    button {
        margin: -5px 0;
        min-width: 100px;
    }
`;

const OuterWrapper = styled.div`
    max-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0 24px;
    color: ${({theme}) => theme.text};
    background: #FBFBFB;

    h2 {
        font-size: ${({theme}) => theme.font_md};
        margin: 8px 0;
    }

    & > p {
        font-size: ${({theme}) => theme.font_sm};
        font-weight: 600;
    }

    hr {
        margin: 0;
        border: none;
        border-top: 1px solid ${({theme}) => theme.gray_50};
    }

    button {
        margin: auto 24px 16px;
    }
`;

const TotalLoyaltyContainer = styled.div<TotalLoyaltyProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({type, theme}) => type === 'credit' ? theme.yumminn_10 : theme.cash_10};
    background-image: url(${loyaltyBg});
    background-size: cover 100%;
    background-position: ${({type}) => type === 'cashback' ? 'bottom left' : 'bottom right'};
    padding: 16px;
    margin: 0 -24px;

    transition: background 0.5s ease-in-out;

    b {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: ${({theme}) => theme.font_xl};
        width: 800;
        margin-top: 16px;
    }

    span {
        font-size: ${({theme}) => theme.font_sm};
        font-weight: 500;
    }
`;

const Link = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    color: ${({theme}) => theme.text};
    font-size: ${({theme}) => theme.font_xs};
    font-weight: 500;
    text-decoration: underline;
    margin: 16px 0 8px;
`;

const ListContainer = styled.div`
    padding: 8px 0 0;
    margin: 0 -8px -8px;
    overflow: hidden;
    flex: auto;
    position: relative;
`;

const RestaurantList = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    margin: 0 0 8px;
`;

const EmptyList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 100%;

    & > img {
        height: 115px;
        width: 115px;
        object-fit: contain;
    }

    & > p {
        font-weight: 600;
        text-align: center;
        margin: 0;
        max-width: 26ch;
    }
`;

const RestaurantCardContainer = styled.li`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    color: ${({theme}) => theme.text};
    border: 1px solid ${({theme}) => theme.gray_30};
    background-color: '#FFF';
    border-radius: 16px;
    box-shadow: ${({theme}) => theme.shadow_xs};

    & > img {
        width: 64px;
        height: 64px;
        object-fit: contain;
        background-color: ${({theme}) => theme.secondary};
        border-radius: 8px;
        flex-shrink: 0;
    }

    & > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        height: 64px;
    }

    & > svg {
        margin: 0 -4px 0 -8px;
    }
`;

const CardTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    b {
        font-size: ${({theme}) => theme.font_lg};
        line-height: 1em;
        color: ${({theme}) => theme.text};
        background-color: ${({theme}) => theme.cash_10};
        border-radius: ${({theme}) => theme.radius_round};
        flex-shrink: 0;
        padding: 6px;
    }
`;

const CardTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4px;

    h3 {
        font-size: ${({theme}) => theme.font_sm};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
        max-width: 16ch;
    }

    span {
        font-size: ${({theme}) => theme.font_xs};
        line-height: 1em;
        display: flex;
        align-items: center;
        gap: 4px;
        color: ${({theme}) => theme.gray_70};
    }
`;

type TotalLoyaltyProps = {
    type: 'credit' | 'cashback'
}

export {
    InformationBody,
    LoyaltyBannerContainerNoRedeem,
    LoyaltyCardContent,
    SalmonBanner,
    LoyaltyBannerContainer,CardsMenu,
    CardsContainer,
    HowItWorksContainer,
    Circle,
    LoyaltyCard,
    OuterWrapper,
    TotalLoyaltyContainer,
    Link,
    ListContainer,
    RestaurantList,
    EmptyList,
    RestaurantCardContainer,
    CardTextContainer,
    CardTitleContainer,
};