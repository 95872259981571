export const shadows = {
  shadow_xs: `
    0px 1px 4px #00000010,
    0px 0px 6px #00000005
  `,
  shadow_sm: `
    0px 2px 4px #00000018,
    0px 3px 12px #00000012
  `,
  shadow_md: `
    0px 0px 12px #00000010,
    0px 0px 34px #00000010
  `,
  shadow_lg: `
    0px 0px 12px #00000020,
    0px 0px 24px #00000010
  `,
  shadow_inset_xs: `
    inset 1px 1px 2px #00000005,
    inset 2px 2px 7px #00000010,
    inset -1px -1px 2px #FFFFFF05,
    inset -2px -2px 7px #FFFFFF10
  `,
  shadow_inset_sm: `
    inset 2px 2px 4px #00000020,
    inset 4px 4px 14px #00000038
  `,
  shadow_overlay: `
    0px 0px 0 900vmax var(--backdrop_color)
  `,
  shadow_drop_xs: `
    drop-shadow(0 1px 1px #0002)
    drop-shadow(0 2px 2px #0002)
  `,
  shadow_drop_sm: `
    drop-shadow(0 0 2px #0004)
    drop-shadow(0 0 4px #0006)
  `,
  shadow_drop_md: `
    drop-shadow(0 0px 2px #0001)
    drop-shadow(0 2px 5px #0002)
    drop-shadow(0 4px 10px #0003)
  `,
  shadow_dropdown: `
    0px 4px 4px #00000005,
    0px 8px 8px #00000005
  `,
};