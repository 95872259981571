import styled, { keyframes } from "styled-components";

const getPoint = keyframes`
  0% {
    opacity: 0;
    scale: 0;
    translate: 0 0;
    transform: rotateY(90deg);
  }
  25% {
    opacity: 1;
    scale: 1;
    translate: 0 -100%;
    transform: rotateY(180deg);
  }
  50% {
    opacity: 0.7;
    scale: 1.5;
    translate: 0 -200%;
    transform: rotateY(270deg);
  }
  75% {
    opacity: 0.5;
    scale: 1;
    translate: 0 -250%;
    transform: rotateY(360deg);
  }
  100% {
    opacity: 0;
    scale: 0.2;  
    translate: 0 -300%;
    transform: rotateY(540deg);
  }
`;

export const AnimatedPoint = styled.img<AnimationProps>`
  position: absolute;
  opacity: 0;
  transform-style: preserve-3d;
  animation-name: ${getPoint};
  animation-delay: ${({delay}) => delay}s;
  animation-duration: 0.5s;
  animation-timing-function: linear;
`;

type AnimationProps = {
  delay: number;
}