import React, { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { eventTrack } from '../../../useGaTracker';
import { useSendBill } from '../../pages/checkout/functions/useSendBill';
import styled from 'styled-components';
import { TextInput } from '../inputs';
import { Button } from '../buttons';
import { Icon, IconType } from '../icons';
import { UserContext, UserContextDispatchContext } from "../../context/auth/UserContext";

const emailRegex = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;

type Props = {
  paymentId: number
  restaurantId?: number
  from: string
}

export const SendReceiptInputComponent = ({paymentId, restaurantId, from}: Props) => {
  const { user: { email } } = useContext(UserContext);
  const { updateUser } = useContext(UserContextDispatchContext)

  const restaurantIdFromStorage = Number(sessionStorage.getItem('restaurantId'));

  const [validEmail, setValidEmail] = useState(emailRegex.test(email));
  const [isSendingReceipt, setIsSendingReceipt] = useState(false);
  const [receiptSent, setReceiptSent] = useState(false);

  const onChange = (event: any) => {
    updateUser({ email: event.target.value });
  };

  useEffect(() => {
    setValidEmail(emailRegex.test(email));
  }, [email]);

  const onBillSuccess = () => setReceiptSent(true);

  const sendBill = useSendBill(setIsSendingReceipt, onBillSuccess);
  
  const onClick = () => {
    if (validEmail) {
      if(from === "payment-successful"){
        eventTrack("07_Success_button_receipt", "07_Success_button_receipt", "07_Success_button_receipt");
      } else if(from === 'home'){
        eventTrack("00_02_receipt_button_resend", "00_02_receipt_button_resend", "00_02_receipt_button_resend");
      } else {
        eventTrack(`Send receipt from ${from}`, `Send receipt from ${from}`, `Send receipt from ${from}`);
      }
      sendBill(restaurantId || restaurantIdFromStorage, paymentId);
    }
  };

  const sendAgain = () => receiptSent && setReceiptSent(false);

  return (
    <Container onClick={sendAgain}>
      <TextInput
        name={'email'}
        title={'send_receipt_label'}
        value={email}
        placeholder={'📩 ' + t('mail_placeholder')}
        onChange={onChange}
      />
      {!receiptSent &&
        <Button
          title={t('send')}
          shadow
          handleClick={onClick}
          disabled={!validEmail}
          loading={isSendingReceipt}
        />
      }
      {receiptSent &&
        <p className='sent'>
          {t('sent')}
          <Icon type={IconType.Refresh}/>
        </p>
      }
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;

  button {
    white-space: nowrap;
    width: min-content;
    min-width: 88px;
    height: 40px;
    margin: 4px 0;
  }

  .sent {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 12px 0;
  }

  svg {
    color: ${({theme}) => theme.primary};
  }
`;