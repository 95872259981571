import "./index.css";

import React from "react";
import ReactDOM from "react-dom";

//import reportWebVitals from "./reportWebVitals";

import { BootstrapContainer } from "./app/bootstrap";
import { AppContextProvider } from "./app/context/AppContext";
import './app/elements/ayden/toastify-styles.css';
import ErrorBoundary from "./app/shared/utils/ErrorBoundary";
import { initializeGtm } from "./tag-manager/Gtm";
import { PopupContextProvider } from "./app/context/PopupContext";
import { EventCatalogContextProvider } from "./app/context/EventCatalogContext";
import * as Sentry from "@sentry/react";
import { GoogleOAuthProvider } from '@react-oauth/google';

initializeGtm();

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <ErrorBoundary>
      <GoogleOAuthProvider clientId="599452072050-dj9tq4a9p4r6vl8ghqmperfc62eifah0.apps.googleusercontent.com">
        <React.StrictMode>
          <PopupContextProvider>
            <AppContextProvider>
              <EventCatalogContextProvider>
                <BootstrapContainer/>
              </EventCatalogContextProvider>
            </AppContextProvider>
          </PopupContextProvider>
        </React.StrictMode>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  </Sentry.ErrorBoundary>
  ,

  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log)) //
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
