import styled, { keyframes } from "styled-components";

export const StarsContainer = styled.div`
  position: relative;
  display: flex;
`;

const explode = (position: number, size: number) => keyframes`
  0% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
  
  100% {
    opacity: 0;
    transform:
    translate(${Math.max(position * 80, 40)}px)
    scale(${Math.max(size, 0.5)});
  }
`;

export const Star = styled.span<Star>`
  position: absolute;
  margin: auto;
  rotate: ${({index}) => index * 360 / 5}deg;
  color: ${({theme}) => theme.cash};
  filter: brightness(${({size}) => 1 + size});
  opacity: 0;
  
  &.animated {
    animation: ${({position, size}) => explode(position, size)} 0.8s ${({size}) => size / 3}s cubic-bezier(.16,.84,.69,.97);
  }
`;

type Star = {
  position: number
  size: number
  index: number
};