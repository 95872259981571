import styled from "styled-components";

const ProgressButton = styled.button<ButtonProps>`
  color: ${({color}) => color || 'currentColor'};
  background-color: ${({bgColor}) => bgColor || 'transparent'};
  border: none;
  border-radius: ${({theme}) => theme.radius_round};
  height: ${({size}) => size};
  width: ${({size}) => size};
  padding: 0;
  position: relative;

  &:disabled > span {
    opacity: 0.5;
  }

  & > svg {
    height: ${({size}) => size};
    width: ${({size}) => size};

    circle {
      fill: transparent;
      transform-origin: center;
      transition: all 0.5s ease-in-out;
      
      &:nth-of-type(1) {
        opacity: 0.3;
        stroke: ${({color, theme}) => color || theme.tertiary};
        stroke-width: 10;
        transform: rotate(-90deg);
      }
      
      &:nth-of-type(2) {
        stroke: ${({theme}) => theme.background_contrast};
        stroke-width: 1.5;
      }
      
      &:nth-of-type(3) {
        stroke-width: 1.5;
        stroke-linecap: round;
        transform: rotate(-87.5deg);
      }
    }
  }
`;

const ProgressContent = styled.span<ContentProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: ${({theme}) => theme.font_sm};
  line-height: 16px;
  font-weight: 600;
  border-radius: ${({theme}) => theme.radius_round};
  box-sizing: border-box;
  padding: 4px;
  z-index: 3;
  filter: ${({isGrayScale}) => isGrayScale ? 'grayscale(1)' : ''};
`;

const ProgressOverlay = styled(ProgressContent)<ButtonProps>`
  z-index: 4;
  mix-blend-mode: color;

  & > svg:nth-of-type(1) {
    height: ${({size}) => size};
    width: ${({size}) => size};
    position: absolute;

    circle {
      fill: transparent;
      transform-origin: center;
      transition: all 0.5s ease-in-out;
      stroke: ${({theme}) => theme.background};
      stroke-width: 10;
      transform: rotate(-90deg);
    }
  }
`;

type ButtonProps = {
  size: string
  color?: string
  bgColor?: string
}

type ContentProps = {
  isGrayScale?: boolean
}

export {
  ProgressButton,
  ProgressContent,
  ProgressOverlay,
}