import styled from "styled-components";

export const BaseCard = styled.div<BaseCardProps>`
  font-family: ${({theme}) => theme.typography};
  font-size: ${({theme}) => theme.font_md};
  color: ${({selected, theme}) => selected ? theme.card_text_selected : theme.text};
  background-color: ${({selected, theme}) => selected ? theme.card_bg_selected : theme.card_bg};
  border: ${({selected, theme}) => `${theme.card_border} ${selected ? theme.tertiary : theme.card_border_color}`};
  border-radius: ${({theme}) => theme.radius_sm};
  box-shadow: ${({theme}) => theme.shadow_xs};
  box-sizing: border-box;
`;

export const FlipCardContainer = styled.label`
  display: block;
  background-color: transparent;
  perspective: 1000px;

  & > input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }
  
  & > div {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s ease;
    transform-style: preserve-3d;
  }

  & > input:checked + div {
    transform: rotateY(180deg);
  }

  .front, .back {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .back {
    position: absolute;
    inset: 0;
    transform: rotateY(180deg);
  }
`;

export const CartCardContainer = styled(BaseCard).attrs({as: 'li'})<CardProps>`
  list-style: none;

  header {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: ${({theme}) => theme.font_md};
    line-height: 1;
    padding: 16px;
    
    span {
      font-size: ${({theme}) => theme.font_xxs};
      font-weight: 600;
      color: ${({theme}) => theme.background};
      background-color: ${({theme}) => theme.text};
      border-radius: ${({theme}) => theme.radius_round};
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0;
      height: 16px;
      min-width: 16px;
    }
    
    h3 {
      font-size: ${({theme}) => theme.font_md};
      font-weight: 500;
      margin: 0;
      font-size: ${({theme}) => theme.font_md};
      font-weight: 600;
      line-height: 1.2rem;
      color: currentColor;
      white-space: ${({isCollapsed}) => isCollapsed ? 'nowrap' : ''};
      text-overflow: ${({isCollapsed}) => isCollapsed ? 'ellipsis' : ''};
      overflow: ${({isCollapsed}) => isCollapsed ? 'hidden' : ''};
      margin: 0;
    }

    i {
      font-size: ${({theme}) => theme.font_xs};
      line-height: 1;
      text-decoration: line-through;
      color: ${({theme}) => theme.text};
      display: flex;
      margin-left: auto;
    }
    
    b {
      font-weight: 600;
    }

    svg {
      color: ${({theme}) => theme.text_a50};
      rotate: ${({isCollapsed}) => isCollapsed ? '0' : '180deg'};
      transition: rotate 0.2s ease-in-out;
    }
  }

  .collapsable {
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
    
    & > div {
      padding: 0 16px;
      box-sizing: border-box;
      
      & > div {
        display: flex;
        gap: 16px;
        position: relative;
    
        & > div {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }
    }
  }

  hr {
    padding-bottom: 16px;
  }

  img {
    height: 72px;
    width: 72px;
    border-radius: ${({theme}) => theme.radius_sm};
    object-fit: cover;
  }

  footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: end;
    gap: 16px;
    padding: 16px 0;
  }

  .handler {
    display: flex;
    flex: 1;
    background-color: transparent;
    border: none;
    padding: 0;

    &::after {
      content: '';
      width: 40%;
      height: 4px;
      background-color: ${({theme}) => theme.text_a30};
      border-radius: ${({theme}) => theme.radius_round};
      margin: auto auto 0;
    }
  }
`;

type BaseCardProps = {
  selected?: boolean
};

type CardProps = {
  isCollapsed: boolean
};