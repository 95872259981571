import React from 'react';

export const STATUS_EMOJIS: Record<string, JSX.Element | null> = {
  received: <span>👩🏻‍🍳</span>,
  ready_to_pick_up: <span>🍲</span>,
  success: null,
  closed: null,
  created: null,
  waiting_send_info_to_pos: null,
  error_sending_info_to_pos: null,
  order_placed: <span>👩🏻‍🍳</span>,
};

export const STATUS_BG: Record<string, string> = {
  success: '#D4F8D3',
  received: '#ECECEC',
  ready_to_pick_up: '#E2F2FD',
  closed: '#D4F8D3',
  created: '#ECECEC',
  waiting_send_info_to_pos: '#ECECEC',
  error_sending_info_to_pos: '#FFFAEB',
  order_placed: '#ECECEC',
  pending_cash: "#FFF8E2"
};