import React from 'react';
import { Trans } from "react-i18next";
import styled from "styled-components";
import * as Yup from "yup";

export const registerValidation = Yup.object().shape({
  email: Yup.string().email("valid_email").required("is_required"),
  confirm_password: Yup.string()
    .test("len", "character_password", val => val?.length ? val.length >= 8 : false)
    .oneOf([Yup.ref("password"), null], "passwords_no_match")
    .required("is_required"),
  password: Yup.string()
    .test("len", "character_password", val => val?.length ? val.length >= 8 : false)
    .oneOf([Yup.ref("confirm_password"), null], "")
    .required("is_required"),
  lopd: Yup.bool().required("terms_and_conditions_required").oneOf([true], "terms_and_conditions_required"),
  news: Yup.bool(),
});

export const loginValidation = Yup.object().shape({
  email: Yup.string().email("valid_email").required("is_required"),
  password: Yup.string()
    .test("len", "character_password", val => val?.length ? val.length >= 8 : false)
    .required("is_required"),
});

const B = styled.b`
font-weight: 600;
`;

export const termsAndConditionsRequired = (
  <Trans i18nKey={'terms_and_conditions_required'}>
    You must <B>read and accept</B> the <B>Privacy Policy</B> and the <B>Terms and Conditions</B> to proceed
  </Trans>
);