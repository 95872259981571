import styled from "styled-components";

export const TagList = styled.ul<{showLabel?: boolean}>`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: ${({showLabel}) => showLabel ? 'wrap' : 'nowrap'};
  padding: 0;
  margin: 0;
  list-style: none;
  flex-shrink: 0;
  overflow: hidden;
`;

export const BadgeContainer = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-size: ${({theme}) => theme.font_sm};
    font-weight: 500;
    line-height: 1em;
    color: ${({theme}) => theme.text};
  }
`;