import React from "react";
import { Icon, IconType } from "../icons";
import { Font, FontSize, FontVariant } from "../fonts";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const MainMenuSavedRestaurantComponent = () => {
  const { t } = useTranslation();

  const history = useHistory();
  // eslint-disable-next-line
  const id = sessionStorage.getItem("restaurantId");

  return (
    <MenuSavedContainer>
      <AboutOrMenuButton
        onClick={() => history.push(`/about-saved-restaurant/${id}`)}
      >
        <Icon type={IconType.About} />
        <Font
          text={t("about")}
          size={FontSize.Large}
          color="#404040"
          variant={FontVariant.SemiBold}
        />
      </AboutOrMenuButton>

      <AboutOrMenuButton
        onClick={() => history.push(`/menu-saved-restaurant/${id}`)}
      >
        <Icon type={IconType.Menu} />
        <Font
          text={t("menu")}
          size={FontSize.Large}
          color="#404040"
          variant={FontVariant.SemiBold}
        />
      </AboutOrMenuButton>
    </MenuSavedContainer>
  );
};



const MenuSavedContainer = styled.div`
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: space-between;
    padding: 16px;
    height: 100%;
    gap: 16px;
`;

const AboutOrMenuButton = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    border-width: 2px;
    border-radius: 8px;
    justify-content: center;
    border-style: solid;
    border-color: ${({theme}) => theme.gray_50}
`;