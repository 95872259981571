import React, { Dispatch, createContext, useReducer } from "react";
import { Popup } from "./app";
import { PopupAction, popupReducer } from "./popupReducer";
import { PopupType } from "./popups.enum";

const INITIAL_STATE: Popup = {
  current: PopupType.None,
  params: null,
  history: [],
  memory: [],
};

export const PopupContext = createContext<{popup: Popup, dispatch: Dispatch<PopupAction>}>({
  popup: INITIAL_STATE,
  dispatch: () => null,
});

export const PopupContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [popup, dispatch] = useReducer(popupReducer, INITIAL_STATE);

  return (
    <PopupContext.Provider value={{ popup, dispatch }}>
      {children}
    </PopupContext.Provider>
  );
};