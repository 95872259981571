import React from 'react';
import { Icon, IconType } from '../icons';
import { CounterButton } from './counter-buttons.component';

type Props = {
  count: number
  max?: number
  disabled?: boolean
  onAdd: React.MouseEventHandler
};

export const MiniCounterComponent = ({count, max, disabled, onAdd}: Props) => {
  return (
    <CounterButton disabled={disabled || !!max && max <= count} onClick={onAdd}>
      {!count &&
        <Icon type={IconType.Plus}/>
      }
      {!!count &&
        <span>{'+' + count}</span>
      }
    </CounterButton>
  );
};