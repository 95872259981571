import React from 'react';
import styled, { keyframes } from "styled-components";

type Props = {
  type: 'info' | 'warning' | 'error'
  message: string | React.ReactNode
  isVisible?: boolean;
};

const icon = {
  success: '✔️',
  info: 'ℹ️',
  warning: '⚠️',
  error: '❌',
};

const color = {
  success: 'yumminn_40',
  info: 'yumminn_40',
  warning: 'warning_40',
  error: 'danger_20',
};

export const InfoLabelComponent = ({type, message, isVisible}: Props) => {
  return (
    <LabelContainer
      type={type}
      isVisible={isVisible}
      className={type === 'error' && isVisible ? 'shake' : ''}
    >
      {typeof message === 'string' ?
        icon[type] + ' ' + message
      :
        <>
          {icon[type]}
          {message}
        </>
      }
    </LabelContainer>
  )
};

const shake = keyframes`
  0% {
    translate: 0;
  }
  25% {
    translate: -2px 0;
  }
  50% {
    translate: 0;
  }
  75% {
    translate: 2px 0;
  }
  100% {
    translate: 0;
  }
`

const LabelContainer = styled.div<Omit<Props, 'message'>>`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: ${({theme}) => theme.font_xxs};
  font-weight: 500;
  color: ${({theme}) => theme.text_dark};
  background-color: ${({theme, type}) => theme[color[type]]};
  border-radius: ${({theme}) => theme.radius_sm};
  padding: 4px 8px;
  opacity: ${({isVisible}) => isVisible ? 1 : 0};
  transition: opacity 0.3s ease-in-out;

  &.shake {
    animation: ${shake} 0.2s ease-in-out 0s 5;
  }
`;