import React from 'react';
import styled from "styled-components";

const Ticket = styled.div`
  filter: drop-shadow(0px 2px 4px #00000018) drop-shadow(0px 4px 14px #00000008);
  
  & > div {
    background-color: ${({theme}) => theme.background_rised};
    border-radius: ${({theme}) => `0 0 ${theme.radius_lg} ${theme.radius_lg}`};
    //padding-bottom: 16px;
/*     mask:
      radial-gradient(8.90px at 50% calc(100% - 8.90px),#000 99%,#0000 101%) calc(50% - 17.8px) 0/35.6px 100%,
      radial-gradient(8.90px at 50% calc(100% + 0.00px),#0000 99%,#000 101%) 50% calc(100% - 8.9px)/35.6px 100% repeat-x;
 */  }
`;

type Props = {
  children: React.ReactNode;
};

export const TicketWrapperComponent = ({children}: Props) => {
  return (
    <Ticket>
      <div>
        {children}
      </div>
    </Ticket>
  );
};