import { generatePalette } from "../utils/colors";
import { brandColors } from "./brandColors";
import { fonts } from "./fonts";
import { radius } from "./radius";
import { shadows } from "./shadows";
import { light } from "./theme-options";

export const baseTheme = {
  ...fonts,
  ...radius,
  ...shadows,
  ...brandColors,
  ...light,
  ...generatePalette('#094553', 'primary'),
  ...generatePalette('#2C4942', 'secondary'),
  ...generatePalette('#094553', 'tertiary'),
};