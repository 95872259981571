import React from 'react';
import { MarqueeContainer, MarqueeMovingContainer } from './MarqueeContainers';

type props = {
  children: React.ReactNode,
  color?: string,
  copies?: number, //Content width must be at least twice of the marquee visible width
}

export const MarqueeComponent = ({children, color = '#5FB894', copies = 2}: props) => {
  const content = [];
  copies = copies % 2 === 0 ? copies : copies + 1;
  
  for (let i = 0; i < copies; i++) {
    content.push(children)
  }

  return (
    <MarqueeContainer height={window.innerHeight} color={color}>
      <MarqueeMovingContainer>
        {content}
      </MarqueeMovingContainer>
    </MarqueeContainer>
  )
};