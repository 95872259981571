import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ToggleSwitch } from '../../elements';
import { calculateEuros } from '../checkout/functions/calculatePrices';
import { BannerContainer, ListContainer, LoyaltyBannerContainer, LoyaltyBannerContainerNoRedeem, RestaurantCardContainerVouchers, RestaurantList, VocuhersContainer } from './LoyaltyContainers';
import { Icon, IconType } from '../../elements/icons';
import { getProgramsFromStorage } from '../checkout/functions/getProgramsFromStorage';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { PopupType } from '../../context/popups.enum';
import { AuthContext } from '../../context/auth/AuthContext';
import { AppContext } from '../../context/AppContext';
import { eventTrack } from '../../../useGaTracker';
import styled from 'styled-components';

export const ZeroSixVoucherSelector = ({vouchers_length} : props) => {
    const {
        state: {
          checkout: {
            zeroSixSelectedVoucher,
          }
        },
        dispatch
      } = useContext(AppContext);
    const {t} = useTranslation();
    const {isLogged} = useContext(AuthContext);
    const {goToPopup} = usePopupNavigation();
    const handleClick = () => goToPopup(PopupType.ZeroSixVouchers);
    const handleDeleteSelectedVoucher = () => {
        dispatch({
            type: 'UPDATE_CHECKOUT',
            payload: {
              zeroSixSelectedVoucher: {
                name: "",
                code: "",
                value: 0,
                is_percentage: false,
                expiration: "",
                min_amount: 0
              },
            }
          })
    }

    return (
        <BannerContainer>
            {zeroSixSelectedVoucher.name == "" ? 
            <>
            <Icon type={IconType.ZerosixColor}/>
            <p>
                <Trans i18nKey={vouchers_length > 1 ? "You have zerosix available vouchers" : "You have zerosix one available voucher"} count={vouchers_length}></Trans>
                </p>
            <div onClick={handleClick}>
            <Icon type={IconType.Arrowforward}/>
            </div></> : <>
            <Icon type={IconType.ZerosixColor}/>
            <p>
            {zeroSixSelectedVoucher.name}
            </p>
            <div onClick={handleDeleteSelectedVoucher}>
            <Icon type={IconType.Close}/>
            </div></>} 
        </BannerContainer>
        )
    }

type props = {
    vouchers_length: number
}
