import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { OuterWrapper } from './VirtualSectionsContainers';
import { VariableSizeList } from 'react-window';
import { TabsHeader } from './TabsHeader';
import { NavigateAllPopup } from './NavigateAllPopup';
import { getMaxViewportHeight } from '../../shared/functions';

const TABS_HEIGHT = 44;

export type Section = {
  id: number | string
  label: string
  height: number
  order?: number
  element: JSX.Element
};

export type HeaderTree = {
  [label: string]: {
    order: number
    value: HeaderTree | Section['id']
  }
};

export type StickyLabel = React.LabelHTMLAttributes<HTMLLabelElement> & {
  'data-filters'?: number
};

type onItemsRenderedArgs = { visibleStartIndex: number };
type onScrollArgs = { scrollDirection: 'forward' | 'backward', scrollOffset: number, scrollUpdateWasRequested: boolean };

type Props = {
  name: string
  sections: Section[]
  headerTree?: HeaderTree
  top?: number
  stickyLabels?: StickyLabel[] | null
  className?: string
  calculateHeight?: (section: Section) => number
};

const defaultCalcHeight = (section: Section) => {
  const domSection = document.getElementById(`virtual_section_${section.id}`);

  if (domSection) {
    const content = Array.from(domSection.children);

    return content.reduce((height, element) => {
      height += element.clientHeight;
      return height;
    }, 0);
  } else {
    return section.height;
  }
};

export const VirtualSections = forwardRef(({
  name,
  sections,
  headerTree,
  top = 0,
  stickyLabels,
  className = '',
  calculateHeight = defaultCalcHeight
}: Props, ref) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<any>(null);
  const firstVisibileRef = useRef<Section | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const sortedSections = useMemo(() => [...sections].sort((a, b) => {
    if (a.order !== undefined && b.order !== undefined) return a.order - b.order;
    return 0;
  }), [sections]);
  
  const maxViewportHeight = getMaxViewportHeight();
  const height = maxViewportHeight - TABS_HEIGHT;

  const [selectedSection, setSelectedSection] = useState<Section | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSeeAllCollapsed, setIsSeeAllCollapsed] = useState(false);

  const scrollTo = (index: number) => {
    setSelectedSection(sortedSections[index]);
    listRef.current?.scrollToItem(index, 'start');
    headerTree && setIsSeeAllCollapsed(true);
  };

  const navigateAllScrollTo = (index: number) => {
    scrollTo(index);
    headerTree && setIsCollapsed(true);
  };

  const onItemsRendered = ({ visibleStartIndex }: onItemsRenderedArgs) => {
    const visibleSection = sortedSections[visibleStartIndex];
    
    if ((!visibleSection?.order || visibleSection?.order >= 0) && visibleSection?.label) {
      firstVisibileRef.current = visibleSection;
    }
  };

  const onScroll = ({ scrollDirection, scrollOffset, scrollUpdateWasRequested }: onScrollArgs) => {
    if (scrollUpdateWasRequested) { // workaround to onScroll being called twice (https://github.com/bvaughn/react-window/issues/228)
      containerRef.current?.scrollIntoView({block: 'start'});
      timeoutRef.current = setTimeout(() => timeoutRef.current = null, 10);
    }

    if (scrollUpdateWasRequested || timeoutRef.current) return;
    
    setSelectedSection(firstVisibileRef.current);
    headerTree && setIsCollapsed(scrollDirection === 'forward');
    headerTree && setIsSeeAllCollapsed(scrollOffset > 50);
  };

  useEffect(() => {
    if (ref) {
      (ref as React.MutableRefObject<any>).current = listRef.current;
    };

    if (sortedSections.length) {
      const selectableSections = sortedSections.filter(section => (!section.order || section?.order >= 0) && section.label);
      setSelectedSection(prev => prev || selectableSections[0]);
    }

    return () => clearTimeout(timeoutRef.current as NodeJS.Timeout);
  }, [sortedSections]);

  return (
    <OuterWrapper ref={containerRef} className={className}>
      {(!!sortedSections.length || !!stickyLabels?.length) &&
        <TabsHeader
          top={top}
          name={name}
          tree={headerTree}
          sections={sortedSections}
          selectedSection={selectedSection}
          isCollapsed={isCollapsed}
          isSeeAllCollapsed={isSeeAllCollapsed}
          stickyLabels={stickyLabels}
          scrollTo={scrollTo}
          setIsCollapsed={setIsCollapsed}
        />
      }
      <VariableSizeList
        ref={listRef}
        height={height}
        itemId={(index: number, data: Section[]) => data[index].id}
        itemCount={sortedSections.length}
        itemSize={(index: number) => calculateHeight(sortedSections[index])}
        itemData={sortedSections}
        onItemsRendered={onItemsRendered}
        onScroll={onScroll}
        className='virtual_list'
      >
        {Section}
      </VariableSizeList>
      {headerTree &&
        <NavigateAllPopup
          name={name}
          tree={headerTree}
          sections={sortedSections}
          scrollTo={navigateAllScrollTo}
        />
      }
    </OuterWrapper>
  );
});

const Section = ({ data, index, style }: any) => {
  const section = data[index];
  return (
    <section style={style} id={`virtual_section_${section.id}`}>
      {section?.element}
    </section>
  );
};

VirtualSections.displayName = 'ScrollSections';