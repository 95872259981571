import styled from "styled-components";

const CircleContainer = styled.div`
  background-color: ${({theme}) => theme.background_contrast};
  border-radius: ${({theme}) => theme.radius_round};
  height: 88px;
  width: 88px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0;
  z-index: 1;
`;

const Gap = styled.div`
  position: absolute;
  top: 0;
  left: calc(50% - 4px);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 44px solid ${({theme}) => theme.background};
  margin-bottom: 50%;
  transform-origin: 50% 100%;
  z-index: 2;
  `;

const TitleContainer = styled.div`
  background-color: ${({theme}) => theme.background};
  border-radius: ${({theme}) => theme.radius_round};
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: ${({theme}) => theme.font_sm};
  line-height: 16px;
  font-weight: 600;
  padding: 4px;
  z-index: 3;
`;

export {CircleContainer, Gap, TitleContainer};