import styled from "styled-components";

export const AllergensList = styled.ul`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-shrink: 0;
  overflow: hidden;
`;

export const BadgeContainer = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-size: ${({theme}) => theme.font_sm};
    font-weight: 500;
    line-height: 1em;
    color: ${({theme}) => theme.text};
  }
`;

export const SvgWrapper = styled.div<{size: number, color: string | undefined}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({size}) => size}px;
  width: ${({size}) => size}px;
  padding: ${({size}) => size * 0.25}px;
  color: ${({color}) => color};
  border-radius: ${({theme}) => theme.radius_round};
  box-shadow: inset 0 0 ${({size}) => size * 2}px -${({size}) => size}px currentColor;
  position: relative;
`;
