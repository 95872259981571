import { baseTheme } from "../../shared/themes";
import { Theme } from "./theme";

export type ThemeAction =
  | { type: 'EDIT_THEME', payload: Partial<Theme> }
  | { type: 'RESET_THEME' }

export const themeReducer = (
	state: Theme,
	action: ThemeAction
): Theme => {
	switch (action.type) {
		case 'EDIT_THEME':
      return {...state, ...action.payload};
    case 'RESET_THEME':
      return baseTheme as Theme;
  };
};