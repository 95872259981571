import React from "react";
import { ILayout } from "./interfaces";
import { RenderWhenContainer } from "../render-when";
import styled from 'styled-components';

export const LayoutContainer = ({ children, anotherBackground }: ILayout) => {
  return(
    <LayoutOuterContainer anotherBackground={anotherBackground}>
      <LayoutInnerContainer>
        <RenderWhenContainer>{children}</RenderWhenContainer>
      </LayoutInnerContainer>
    </LayoutOuterContainer>
  )
};

const LayoutOuterContainer = styled.div<{anotherBackground?: boolean}>`
    display: flex;
    flex-direction: column;
    height: var(--dvh); /* fallback */
    height: 100dvh;
    justify-content: space-between;
    scroll-behavior: smooth;
    background-color: ${({anotherBackground}) => anotherBackground ? 'var(--background_lighter)' : 'var(--background)'};
    color: var(--text);
`;

const LayoutInnerContainer = styled.div`
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
`;
