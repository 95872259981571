import React, { useContext, useRef, useState } from "react";
import PropTypes, {InferProps} from "prop-types";
import _ from "lodash";
import 'react-toastify/dist/ReactToastify.css';
import { BackgroundContainer, CornerLogo, Logo, LogoContainer, TableContainer } from "./MainImageContainers";
import PATFallbackBg from "../../assets/images/redesign/fallback_PAT.jpg";
import OPFallbackBg from "../../assets/images/orderAndPay/fallback_OP.jpg";
import { AppContext } from "../../context/AppContext";
import { t } from "i18next";
import { getImageUrl } from "../../shared/functions";

const DEMO_GROUP_UUID = '5c10ee0fd2a9411988ebc21bc333837f';

export function MainImageComponent({mainImage, logo, cornerLogo, isCatalog}: InferProps<typeof MainImageComponent.propTypes>) {
  const { state: { restaurantCustomization: { colors: { photoContrastColor }, logoWidth } } } = useContext(AppContext);

  const restaurantId = Number(sessionStorage.getItem('restaurantId'));
  const isDemoGroup = sessionStorage.getItem('restaurantGroupUuid') === DEMO_GROUP_UUID;
  
  const tableName = sessionStorage.getItem("tableName") || "";
  const functionality = sessionStorage.getItem('functionality') || "";
  
  const imgElement = useRef<HTMLImageElement>(null);
  const [ratio, setRatio] = useState(0.00);
  const isOrderAndPay = isCatalog as boolean;
  const validLogoWidth = logoWidth && logoWidth > 0 && logoWidth <= 100 ? logoWidth : null;

  const getAspectRatio = () => {
    const logo = imgElement.current as unknown as HTMLImageElement;
    const height = logo?.naturalHeight;
    const width = logo?.naturalWidth;
    setRatio(width/height);
  }

  const imageUrl = getImageUrl(mainImage);

  return (
    <BackgroundContainer
      image={imageUrl || (isOrderAndPay ? OPFallbackBg : PATFallbackBg)}
      restaurantId={restaurantId}
      isOrderAndPay={isOrderAndPay}
    >
      <TableContainer color={photoContrastColor}>
        {!['order_and_collect', 'delivery', 'takeaway'].includes(functionality) &&
          <span>{isDemoGroup ? `${t('table')} 10` : tableName}</span>
        }
        {cornerLogo &&
          <CornerLogo src={cornerLogo} alt=''/>
        }
      </TableContainer>
      <LogoContainer isOrderAndPay={isOrderAndPay}>
      {logo &&
        <Logo logoWidth={validLogoWidth} aspectRatio={ratio}>
          <img ref={imgElement} onLoad={getAspectRatio} onError={() => imgElement.current?.remove()} src={logo} alt='logo'/>
        </Logo>
      }
      </LogoContainer>
    </BackgroundContainer>
  );
}

MainImageComponent.propTypes = {
  mainImage: PropTypes.string,
  logo: PropTypes.string,
  cornerLogo: PropTypes.string,
  isCatalog: PropTypes.bool
};