import React from 'react';
import { Receipt } from '../../../context/app';
import { ListItem } from './ReceiptsContainers';
import { t } from 'i18next';
import moment from 'moment';
import { calculateEuros, calculateOrderTotal, calculateTotal } from '../../../pages/checkout/functions/calculatePrices';
import { Icon, IconType } from '../../icons';
import { STATUS_BG, STATUS_EMOJIS } from './constants';

type Props = {
  receipt: Receipt
  notifyUuid: string | null
  variant: 'today'|'history'
  openTicket: (receipt: Receipt) => void
};

export const ReceiptItem = ({receipt, notifyUuid, variant, openTicket}: Props) => {
  const functionality = sessionStorage.getItem('functionality') || '';
  
  const isTable = ['just_pay', 'order_at_table'].includes(functionality);
  const isPaid = 'base_amount_cents' in receipt;
  
  const date = moment(receipt.date).format('DD/MM/YYYY H:mm');

  const accountTotal = !isPaid ? calculateOrderTotal(receipt) : calculateTotal(receipt);
  const totalEuros = calculateEuros(accountTotal) + '€';

  const pickUpRange = receipt.order_pickup_time
    ? `${moment(receipt.order_pickup_time).format('H:mm')} - ${moment(receipt.order_pickup_time).add(15, 'm').format('H:mm')}`
    : null;

  const status = !isPaid ? 'order_placed' : (receipt?.order_status || receipt.status);
  const showPickUpRange = !isTable && pickUpRange;
  const transContext = isTable ? 'table' : 'collect';

  const handleClick = (event: React.MouseEvent, receipt: Receipt) => {
    event.stopPropagation();
    openTicket(receipt);
  };
  
  return (
    <ListItem notify={receipt.uuid === notifyUuid} isHistory={variant === 'history'} onClick={event => handleClick(event, receipt)}>
      <div>
        {variant === 'today' &&
          <div className='receipt_icon'>
            {STATUS_EMOJIS[status]}
            <Icon type={IconType.Check} size={8}/>
          </div>
        }
        {variant === 'history' &&
          <Icon type={IconType.Receipt}/>
        }
        <div className='order'>
          {!!receipt?.order_code &&
            <p>
              {t('order') + ': '}
              <b>{'#' + receipt?.order_code}</b>
            </p>
          }
          <p>{date}</p>
        </div>
        <b>
          {totalEuros}
        </b>
      </div>
      {(variant === 'today' && showPickUpRange && isPaid) &&
        <p>{'🕚 ' + pickUpRange}</p>
      }
      {variant === 'today' &&
        <span style={{backgroundColor: STATUS_BG[status]}}>
          {t(status, {context: transContext})}
        </span>
      }
    </ListItem>
  );
};