import React from 'react';
import { ICONS } from "./constants";
import { allergensIcons } from "./constants/allergens";
import { colorIcons } from "./constants/color";
import { linearIcons } from "./constants/linear";
import { themedIcons } from "./constants/themed";
import { IIcon } from "./interfaces";
import { tagsIcons } from './constants/tags';

export const IconComponent = ({ type, colorIcon = 'currentColor', size, fill, disabled=false }: IIcon) => {
  const AllIcons = { ...ICONS, ...linearIcons, ...colorIcons, ...themedIcons, ...allergensIcons, ...tagsIcons };
  
  !AllIcons[type] && console.error(`"${type}" is not a valid icon type`);

  return AllIcons[type]?.(size, colorIcon, fill, disabled) || <></>;
};
