import "./font.component.scss";
import React from "react";
import { IFont } from "./interfaces";
import { FontSize, FontVariant } from "./enums";
import { useTheme } from "styled-components";

export const FontComponent = ({
  text,
  color,
  size = FontSize.Normal,
  variant = FontVariant.Normal,
  underline = false,
  leading,
  style = {}
}: IFont) => {
  const theme: any = useTheme();
  let finalStyle: any = {fontSize: `${size}`, fontWeight: `${variant}` as unknown as number, color: color || theme.text, textDecoration: underline ? "underline" : "none"};
  if (leading) {
    finalStyle['lineHeight'] = `${leading}`;
  }
  finalStyle = {...finalStyle, ...style};
  if (leading) {
    return (<p style={finalStyle}>{text}</p>);
  }
  return (<span style={finalStyle}>{text}</span>);
};
